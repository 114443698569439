import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
      <Container className="page-404">
          <Row>
              <Col xs={12} sm={6}>
                  <img src={process.env.PUBLIC_URL + "/img/error404.svg"} className="error-image" alt="errorcito" />
              </Col>
              <Col xs={12} sm={6}>
                  <img src="/logo.svg" className="logo" alt="logo"/>
                  <h1>Error 404</h1>
                  <p>Ups, parece que no hemos encontrado lo que buscabas.</p>

                  <Link to="/">
                    <Button className="btn btn-primary px-4 py-2 mT-20">Back to home</Button>
                  </Link>
              </Col>
          </Row>
      </Container>
  );
};

export default NotFound;
