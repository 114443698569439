import React from "react";
import { RequestNewVersion } from "../../classes/RequestNewVersion"; 
import Cookies from "js-cookie";
import { getTimeTranscurred } from "../my_expedients/expedients_list_logic";
import {NotificationManager} from "react-notifications";
import {Col,Row,Button} from "react-bootstrap";
import { Link } from "react-router-dom";


export const columns = [
    {
        dataField: "id_formatted",
        text: "#",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { width: "6%", textAlign: "center", minWidth: "3rem" }
      },
      {
        dataField: "expedient_number_formatted",
        text: "Id del trámite",
        sort: true,
        style: { textAlign: "left" },
        headerStyle: { width: "16%", textAlign: "left", minWidth: "12rem" }
      },
      {
        dataField: "destinatary",
        text: "Destinatario",
        sort: true,
        style: { textAlign: "left" },
        headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
      },
      {
        dataField: "carge",
        text: "Puesto",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
      },
      {
        dataField: "created",
        text: "Creado",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
      },
      {
        dataField: "actions",
        text: "Acciones",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { width: "18%", textAlign: "center", minWidth: "12rem" }
      }
];

export const getPendingTransfers = async ( 
    sizePerPage, 
    page, 
    setData, 
    setTotal, 
    setTextNotData,
    setOpenModal,
    setOpenModalCancel
) => {
    const {userId_03} = Cookies.get();

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/pending_to_deliver/${userId_03}/${sizePerPage}?page=${page}`,
        "get",
        null,
        {}
      );

      const getDataPendingTransfers = await request.executeRequest();

      if ( getDataPendingTransfers.code !== 200 ) {

        NotificationManager.error("Ha ocurrido un error, inténtalo nuevamente, no se pudo completar la búsqueda");
      }
      else {
          if ( getDataPendingTransfers.response.governmentBooks && getDataPendingTransfers.response.governmentBooks.length > 0 ) {

            const formattedPendingTransfers = formattedPendingTransfersFunction(
                getDataPendingTransfers.response.governmentBooks,
                setOpenModal,
                setOpenModalCancel
            );
            setData(formattedPendingTransfers);
            setTotal(getDataPendingTransfers.response.total);
          }
          else if ( getDataPendingTransfers.response.governmentBooks && getDataPendingTransfers.response.governmentBooks.length === 0 ) {
            setData([]);
            setTotal(0);
            setTextNotData(getDataPendingTransfers.response.message );
          }
      }
};


const formattedPendingTransfersFunction =  ( pendingTransfers, setOpenModal, setOpenModalCancel ) => {
    let data = [];
    pendingTransfers.forEach( (pendingTransfer,index) => {
        const paintRed = pendingTransfer["governmentBook"]["receiver"]["id"] === pendingTransfer["governmentBook"]["sender"]["id"] &&
                         pendingTransfer["governmentBook"]['external'] === false
                         ? true : false;
        const paintGray = pendingTransfer["governmentBook"]['external'];
        const expedient_number_link = String(pendingTransfer["governmentBook"]["expedient_number"]).replace(/\//g,'-');

        pendingTransfer["governmentBook"]["id_formatted"] = (
          <span style={ paintRed ? { color:"red" } : paintGray ? { color: "#c1c1c1" } : null } >
            {pendingTransfer["governmentBook"]["id"]}
          </span>
        );

        pendingTransfer["governmentBook"]["expedient_number_formatted"] = (
          <Link 
            style={ paintRed ? { color:"red" } : paintGray ? { color: "#c1c1c1" } : null }
            to={{
              pathname: `/expedient_details/${expedient_number_link}`, 
              state: { 
                labelPath: 'Expedientes enviados',
                prevPath: window.location.pathname 
              }
            }}
          >
            {pendingTransfer["governmentBook"]["expedient_number"]}
          </Link>
        );

        pendingTransfer["governmentBook"]["destinatary"] = (
          <span style={ paintRed ? { color:"red" } : paintGray ? { color: "#c1c1c1" } : null } >
            {pendingTransfer["governmentBook"]["receiver"]["name"]}
          </span>
        );

        pendingTransfer["governmentBook"]["carge"] = (
          <span style={ paintRed ? { color:"red" } : paintGray ? { color: "#c1c1c1" } : null }>
            {(pendingTransfer["governmentBook"]["receiver"]["group"]).toUpperCase()}
          </span>
        );
        pendingTransfer["governmentBook"]["created"] = (
          <span style={ paintRed ? { color:"red" } : paintGray ? { color: "#c1c1c1" } : null }>
            {getTimeTranscurred( new Date( pendingTransfer["governmentBook"]["created_at"] ) )}
          </span>
        );

        pendingTransfer["governmentBook"]["actions"] = pendingTransfer["governmentBook"]['external'] === true ? (
            <Row center="xs" around="xs">
                <Col xs={12} className="procedures-actions">
                    <div 
                      style={{paddingTop:"5px"}} 
                      className="badge-warning" 
                      title="Este es un expediente que se ha transferido de manera externa"
                    >
                      <small style={{fontSize:'.8rem', color:'white'}}>Externo</small>
                    </div>
                </Col>
            </Row>
          ) : (
            <Row center="xs" around="xs">
                <Col xs={12} className="procedures-actions">
                    <div 
                      style={{paddingTop:"5px"}} 
                      className="edit" 
                      onClick={() => setOpenModal(pendingTransfer["governmentBook"]) }>
                        <i className="ti-pencil-alt" title="Editar turno" />
                    </div>
                    <div 
                      style={{paddingTop:"5px"}} 
                      className="trash" onClick={() => setOpenModalCancel(pendingTransfer["governmentBook"])}
                      title="Cancelar"
                    >
                      <i className="ti-trash"/>
                    </div>
                </Col>
            </Row>
        ); 
        
        data.push(pendingTransfer["governmentBook"]);
    });

    return data;
};


export const getUsersToTransfer = async (
  setOptions,
  courtId
) => {
  const { userId_03 } = Cookies.get();
  let users = [];
  const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/courts/${userId_03}/${courtId}`,
      "get",
      null,
      {}
    );
  const usersOfSameCourt = await request.executeRequest();

  if ( usersOfSameCourt.code !== 200 ) {
      NotificationManager.error("No es posible cargar la lista de usuarios para la transferencia");
  }
  else {

      if ( usersOfSameCourt.response.users && typeof usersOfSameCourt.response.users ) {

          for( const key in usersOfSameCourt.response.users ) {

              users.push( { label:usersOfSameCourt.response.users[key], value:key } );
          }

          setOptions(users);
      }
  }
};


export const updateTransfer = async (
  transfer,
  sizePerPage, 
  page, 
  setData, 
  setTotal, 
  setTextNotData,
  setOpenModal,
  setShow,
  setOpenModalCancel
) => {

  const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/update/${transfer.id}`,
      "put",
      null,
      {
        id: transfer.id,
        receiver_id: transfer.receiver_id.value,
        observation: transfer.observation
      }
    );
  const responseUpdate = await request.executeRequest();

  if ( responseUpdate.code !== 200 ) {

    NotificationManager.error( "Algo sucedió y no fue posible actualizar la transferencia" );
  }
  else {

    if ( 
      responseUpdate.response.governmentBook && 
      responseUpdate.response.message && 
      responseUpdate.response.message !== "" &&
      typeof responseUpdate.response.message === "string" 
    ) {

      await getPendingTransfers(
        sizePerPage, 
        page, 
        setData, 
        setTotal, 
        setTextNotData,
        setOpenModal,
        setOpenModalCancel
      );
      setShow(false);
      NotificationManager.success( responseUpdate.response.message );
    }
  }  
};


export const bodyModalCancel = (
  <>
    ¿Estás seguro de efecturar esta operación?
  </>
);


export const footerModalCancel = ( 
  expedient, 
  closeModal,
  sizePerPage, 
  page, 
  setData, 
  setTotal, 
  setTextNotData,
  setOpenModal,
  setOpenModalCancel,
  props
) => {

  return (
    <>
      <Button
          variant="secondary"
          onClick={ () => closeModal(false) }
      >
          Cancelar
      </Button>
      <Button 
          variant="primary"
          type="submit"
          onClick={ () => requestToCancelTransfer(
            expedient,
            sizePerPage, 
            page, 
            setData, 
            setTotal, 
            setTextNotData,
            setOpenModal,
            setOpenModalCancel,
            closeModal,
            props
          )}
      >
          Confirmar
      </Button>
    </>
  );
};



export const searchPendingExpedients = async (
  search,
  sizePerPage,
  page,
  setData,
  setTotal,
  setTextNotData,
  setOpenModal,
  setOpenModalCancel
) => {

  const { userId_03 } = Cookies.get();
  search = String(search).replace(/\//g,'-').toUpperCase(); 

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/search/pending_to_deliver/${userId_03}/${search}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseSearch = await request.executeRequest();

  if ( responseSearch.code === 200 ) {

    if ( responseSearch.response.governmentBooks && responseSearch.response.governmentBooks.length > 0 ) {

      const formattedResponse = await formattedPendingTransfersFunction(
        responseSearch.response.governmentBooks,
        setOpenModal,
        setOpenModalCancel
      );

      setData(formattedResponse);
      setTotal(responseSearch.response.total);
    }
    else {
      setTextNotData("No se han encontrado resultados");
      setData([]);
      setTotal(0);
    }
  }
  else if ( 
    responseSearch.code === 404 && 
    responseSearch.response.error && 
    responseSearch.response.message && 
    typeof responseSearch.response.message === "string" ) {

      setTextNotData(responseSearch.response.message);
      setData([]);
      setTotal(0);
  }
};



export const requestToCancelTransfer = async (
  expedient,
  sizePerPage, 
  page, 
  setData, 
  setTotal, 
  setTextNotData,
  setOpenModal,
  setOpenModalCancel,
  showModalCancel,
  props
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/cancel_release/${expedient.id}`,
    "put",
    null,
    {}
  );

  const responseCancel = await request.executeRequest();

  if ( 
    responseCancel.code === 200 && 
    responseCancel.response.expedientBook && 
    typeof responseCancel.response.expedientBook === "object" && 
    responseCancel.response.message && 
    typeof responseCancel.response.message === "string" ) {

      await getPendingTransfers(
        sizePerPage, 
        page, 
        setData, 
        setTotal, 
        setTextNotData,
        setOpenModal,
        setOpenModalCancel
      );
      showModalCancel(false);
      NotificationManager.success(responseCancel.response.message);
      setTimeout(() => {
        props.history.push('/my_expedients');
      }, 500);
  }
  else {

    NotificationManager.error("Algo ha sucedido al intentar cancelar el turnado");
  }
};