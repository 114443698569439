
export const getBlobOfFilePDF = (data, onSuccess) => {
  let xhr = new XMLHttpRequest();
  let url = `${data.url}?token=${process.env.REACT_APP_TOKEN}`;
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Authorization', data.token);
  xhr.responseType = 'arraybuffer';
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if (onSuccess) {
        onSuccess(xhr.response);
      }
    }
  };
  xhr.send();
};