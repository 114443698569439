import React, { useEffect, useState, useContext } from "react";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import { getColumnsModifyExpedients, searchExpedientsToModify, getExpedientsToModify } from "../modify_expedients/modify_expedients_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";

const ModifyExpedients = (props) => {
	const [searching, setSearching] = useState(false);
	const [cleaning, setCleaning] = useState(false);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [totalProcedures, setTotalProcedures] = useState(0);
	const [data, setData] = useState([]);
	const [textNotData, setTextNotData] = useState('');
	const columns = getColumnsModifyExpedients();
	const loader = useContext(LoaderContext);

	useEffect( () => {
		(async function() {
			loader.show(true);
			await getExpedientsToModify(
				page, 
				sizePerPage,
				setData,
				setTotalProcedures,
				props,
				setTextNotData
			);
			loader.show(false);
		}())
	}, []);


	const searchExpedients = async () => {
    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) {
			loader.show(true);
			await searchExpedientsToModify(
				1,
				sizePerPage,
				setData,
				setTotalProcedures,
				props,
				search,
				setTextNotData
			);
			loader.show(false);
		}
	};

	const cleanSearch = async () => {
		setSearch('');
		loader.show(true);
		await getExpedientsToModify(
			1, 
			sizePerPage,
			setData,
			setTotalProcedures,
			props,
			setTextNotData
		);
		loader.show(false);
	};

	const handleTableChange = async (type, { page, sizePerPage }) => {
		setPage(page);
		setSizePerPage(sizePerPage);
		if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
			loader.show(true);
			await searchExpedientsToModify(
				page,
				sizePerPage,
				setData,
				setTotalProcedures,
				props,
				search,
				setTextNotData
			);
			loader.show(false);
		}
		else {
			loader.show(true);
			await getExpedientsToModify(
				page, 
				sizePerPage,
				setData,
				setTotalProcedures,
				props,
				setTextNotData
			);
			loader.show(false);
		}
	};


  return (
		<>
			<NavBar {...props} />
			<h3 className="mT-20 w-100 text-center">Listado de expedientes</h3>
		
			<div style={{width:"85%", margin:"0px auto", display:"block", marginBottom:"65px"}}>
				<ExpedientsSearcher
					placeholderSearch="Escribe el número de Id del trámite"
					placeholderSearchButton="Buscar"
					placeholderCleanButton="Limpiar"
					helperContextual="Escribe el número de Id del trámite y haz clic en el botón de buscar."
					executeSearch={searchExpedients}
					cleanSearch={cleanSearch}
					isSearching={searching}
					isCleaning={cleaning}
					setSearch={setSearch}
					search={search}
					classNameButtonsSearch={"buttonsSearchExpedientsList"}
					hasRulesToSearch={true}
					rulesToSearch={/^[a-zA-Z0-9/]*$/}
				/>
			</div>

			<Grid fluid={true} className={ data.length > 0 ? "no-padding" : "d-n" } style={{minHeight: '76vh', width:"85%", margin:"0px auto"}}>
				<section className="procedures-table-container mT-30">
					<RemotePagination
						data={data}
						page={page}
						sizePerPage={sizePerPage}
						totalSize={totalProcedures}
						onTableChange={handleTableChange}
						columns={columns}
					/>
				</section>
			</Grid>

			<h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data mT-20" : "d-n" }>{textNotData}</h4>

			<Loader/>
		</>
	);	
};

export default ModifyExpedients;