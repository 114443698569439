
import React from 'react';
import Cookies from 'js-cookie';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import { NotificationManager } from 'react-notifications';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export const initColumnsTableReceivedHistory = [
  {
    dataField: "index",
    text: "#",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "expedient_number_link",
    text: "Id del trámite",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "sender",
    text: "Emisor",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "group",
    text: "Puesto",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "reception_date",
    text: "Fecha de recepción",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "actions",
    text: "Acciones",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  }
];

export const getReceivedHistory = async (
  setReceivedHistory,
  setTotalExpedients,
  page,
  sizePerPage,
  handleShowModal,
  setTextNotData
) => {

  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/history/user/${userId_03}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const response = await request.executeRequest();

  if (response.code !== 200) {
    NotificationManager.error("Algo sucedió y no fue posible traer los expedientes, inténtalo nuevamente");
  } else {
    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      NotificationManager.error(response.response.message);
    }
    else if ( response.response.history && response.response.history.length === 0 ) {

      setTextNotData("No existen resultados por mostrar");
      setTotalExpedients(0);
      setReceivedHistory([]);
    }
    else {
      const expedients = await formattedExpedients(
        response.response.history,
        handleShowModal
      );
      setTotalExpedients(response.response.total);
      setReceivedHistory(expedients);
    }
  }
};

const formattedExpedients = (expedients, handleShowModal) => {

  return expedients.map((expedient, index) => {
    const expedient_number_parsed = String(expedient["expedient_number"]).replace(/\//g,'-');

    expedient["actions"] = (
      <Row key={expedient['id']} center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div className="continue" onClick={(e) => {handleShowModal(expedient)}}>
            <i className="ti-eye" title="Transferir expediente" />
          </div>
        </Col>
      </Row>
    );
    expedient["expedient_number_link"] = (
      <Link 
        to={{
          pathname: `/expedient_details/${expedient_number_parsed}`, 
          state: { 
            labelPath: 'Expedientes recibidos',
            prevPath: window.location.pathname 
          }
        }}
      >
        {expedient.expedient_number}
      </Link>
    );

    expedient['index'] = expedient.id;
    expedient['group'] = (expedient.sender.group).toUpperCase();
    expedient['sender'] = expedient.sender.name;
    expedient["reception_date"] = getFormatedDate(expedient['reception_date']);

    return expedient;
  });
};

const getFormatedDate = (date) => {
  let year = date.substr(0, 4);
  let month = date.substr(5, 2);
  let day = date.substr(8, 2);

  return `${day}-${month}-${year}`;
}

export const sendSearchExpedients = async (
  search,
  setTextNotData,
  sizePerPage,
  page,
  setData,
  setTotalProcedures,
  handleShowModal
) => {

  const { userId_03 } = Cookies.get();
  search = String(search).replace(/\//g,'-');

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/history/search/user/${userId_03}/${search}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseSearch = await request.executeRequest();

  if ( responseSearch.code === 200 ) {

    if ( responseSearch.response.history && responseSearch.response.history.length > 0 ) {

      const expedients = await formattedExpedients(
        responseSearch.response.history,
        handleShowModal
      );

      setData(expedients);
      setTotalProcedures(responseSearch.response.total);
    }
    else {

      setTotalProcedures(0);
      setTextNotData("No se han encontrado resultados");
      setData([]);
    }
  }
  else if ( 
    responseSearch.response.error && 
    typeof responseSearch.response.message === "string" 
    && responseSearch.response.message !== "" 
  ) {

    setTextNotData(responseSearch.response.message);
    setData([]);
    setTotalProcedures(0);
  }
};