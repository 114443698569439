import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const actionRoutes = [
  'Mis expedientes',
  'Expedientes enviados',
  "Buscar expediente",
  "Expedientes recibidos",
  "Expedientes por recibir",
  "Detalles de expediente"
];

const Breadcrumb = props => {
  const {
    open,
    onHide,
    name,
    path
  } = props;
  const inExpedientDetails = props && props.location && props.location.state && path === '/expedient_details' ?
    true : false;
  const previousPathData = inExpedientDetails ? 
    props.location.state : { prevPath: null, labelPath: null };

  return(
    <div className="sm-10 offset-1 mT-10">
      <p>
      <Link to="/"> <i className="fas fa-home"/> Inicio</Link>
        { actionRoutes.includes(name) && 
          previousPathData.labelPath !== 'Modificar expedientes' &&
          previousPathData.labelPath !== 'Turnado externo de expedientes' ? 
          (<span>&nbsp;\&nbsp;
            <Button
              className="remove-hover-link"
              variant="link" 
              onClick={() => onHide( !open )}
            > 
              Expedientes 
            </Button></span>) :
          ''
        }
        { inExpedientDetails ? 
          (<span>&nbsp;\&nbsp;
            <Link
              to={previousPathData.prevPath}
            > 
              {previousPathData.labelPath} 
            </Link></span>) : ''
        }
        &nbsp;\&nbsp;<Button 
          className="remove-hover-link" 
          variant="link" 
        > 
          {name} 
        </Button>
        
      </p>
    </div>
  );
};
export default Breadcrumb;