import React from "react";
import Cookies from "js-cookie";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
import {animateScroll} from "react-scroll";

export const initForm = {
  curp: "",
  paternal_surname: "",
  maternal_surname: "",
  names: "",
  rfc: "",
  email: "",
  password: ""
};

/**********************************************************GET USER DATA************************************************************************/
export const getUserData = async setForm => {
  const { token_03, uid_03, client_03, userId_03 } = Cookies.get();

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/${userId_03}`,
    "get",
    null,
    {
      uid_name: uid_03,
      access_token_name: token_03,
      client_name: client_03
    }
  );
  const getUserDataRequest = await request.executeRequest();

  if ( getUserDataRequest.code === 200 ) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setForm( getUserDataRequest.response.user );
  }
};
/***********************************************************************************************************************************************/

/**********************************************************UPDATE PROFILE************************************************************************/
export const UpdateProfileRequest = async (form, props, setErrors) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/${userId_03}`,
    "patch",
    null,
    {
      names: form.names,
      paternal_surname: form.paternal_surname,
      maternal_surname: form.maternal_surname,
      password: form.password
    }
  );
  const getUserDataRequest = await request.executeRequest();

  if ( getUserDataRequest.code === 200 ) {
    if ( getUserDataRequest.response.error && typeof getUserDataRequest.response.message === "object" ) {

      setErrors( getUserDataRequest.response.message );
      animateScroll.scrollToTop();
      
      setTimeout(() => {
        NotificationManager.error("Existen errores que impiden continuar, revisa los campos marcados en rojo.");
      }, 500);
    }
    else if ( getUserDataRequest.response.error && typeof getUserDataRequest.response.message === "string" ) {
      const errorPassword = {
        password: [getUserDataRequest.response.message]
      }

      setErrors(errorPassword);
      setTimeout(() => {
        NotificationManager.error("Existen errores que impiden continuar, revisa los campos marcados en rojo.");
      }, 500);
    }
    else {
      setTimeout(() => {
        NotificationManager.success("La informacion se actualizo correctamente.");
      }, 500);
  
      Cookies.set("names_03", getUserDataRequest.response.user.names);
      Cookies.set(
        "paternal_surname_03",
        getUserDataRequest.response.user.paternal_surname
      );
  
      props.history.push("/");
    }
  }
  else if ( getUserDataRequest.code === 422 && getUserDataRequest.response.error ) {
    if ( typeof getUserDataRequest.response.message === "object" ) {

      setErrors( getUserDataRequest.response.message );
      animateScroll.scrollToTop();
    }
    else if ( typeof getUserDataRequest.response.message === "string" ) {
      const errorPassword = {
        password: [getUserDataRequest.response.message]
      }

      setErrors(errorPassword);
    }
    setTimeout(() => {
      NotificationManager.error("Existen errores que impiden continuar, revisa los campos marcados en rojo.");
    }, 500);
  }
};
/***********************************************************************************************************************************************/

export const renderErrorsList = (errors, inputName) => {
  if( errors && errors[inputName] ){
      const errorsList = errors[inputName].map((error,index)=>{
          return (
              <li key={index} style={{padding:"0px 25px 0px", color:"red", fontSize:".8rem"}}>
                  <small>
                      {error}
                  </small>
              </li>  
          );
      })
      return errorsList;
  }
};

/***********************************************************************************************************************************************/
export const renderClassesToErrors = (errors, inputName) => {
  if( errors && errors[inputName] ){
      return "text-danger is-invalid";
  }
};