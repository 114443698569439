import React from "react";
import Cookies from "js-cookie";
import {DestroyCookies} from "../../login/login_logic";
import {Badge} from "react-bootstrap";
import {NotificationManager} from "react-notifications";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

export const logOut = async props => {
    const {token_03, uid_03, client_03} = Cookies.get();

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_out`,
        "delete",
        null,
        {
            access_token_name: token_03,
            uid_name: uid_03,
            client_name: client_03
        }
    );

    const responseLogOutData = await request.executeRequest();

    if (responseLogOutData.code === 200) {
        //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE DESPUES DE DESLOGUEARSE
        if (
            typeof responseLogOutData.response.message === "string" &&
            responseLogOutData.response.message !== ""
        ) {

            setTimeout(() => {
                NotificationManager.success(responseLogOutData.response.message);
            }, 1000);

            await DestroyCookies();
        }
    }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function capital_letter(str) {
    str = str.trim();
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
}