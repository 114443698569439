import React, { useState, useEffect } from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import OneColumn from "../layout/containers/OneColumn";
import { getUserManualByGroupsId } from "./user_manual_logic";

const UserManual = props => {
    const [ firstText, setFirstText ] = useState("el manual de usuario que describe");
    const [ secondText, setSecondText ] = useState("visualizarlo haz clic en el enlace mostrado");
    const [ howManyManuals, setHowManyManuals ] = useState("Manual");
    const [ dataButtons, setDataButtons ] = useState([]);


    useEffect(() => {        
        (async function () {
            await getUserManualByGroupsId( setFirstText, setSecondText, setHowManyManuals, setDataButtons );
        })();
    }, []);

    let pageConfig = {
        active: 'user_manual',
        title: 'User manual'
    };

    return (
        <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid noTitle>
            <Container 
                fluid={true} 
                className="bg-size-cover" 
                style={{
                    backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
                    minHeight: "100vh",
                }}
            >
                <Container>
                    <Row style={{minHeight: "100vh"}}>
                        <Col
                            sm={12}
                            md={{span: 10, offset: 1}}
                            className="login-container"
                        >
                            <Card className="mY-40">
                                <Card.Body>
                                    <h4 className="text-center">{`${howManyManuals} de Usuario`}</h4>
                                    <hr/>
                                    <Row center="xs">
                                        <Col xs={12}>
                                            <div className="table-wrapper mT-10">
                                                <p>{`En la presente sección ponemos a tu disposición ${firstText} las principales actividades que puedes llevar a cabo en el sistema de acuerdo a tu rol.`}</p>
                                                <p>{`Para ${secondText} a continuación.`}</p>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            {
                                                dataButtons.length > 0 ?
                                                (
                                                    <div className="table-responsive">
                                                        <table className="table table-collapsed">
                                                            <tbody>
                                                                <tr className="table-info">
                                                                    <th className="text-center">Grupo</th>
                                                                    <th className="text-center">Enlace de descarga</th>
                                                                </tr>
                                                                {
                                                                    dataButtons.map( ( data ) => (
                                                                        <tr>
                                                                            <td style={{paddingTop:"10px",fontSize:"1.1rem"}} className="text-center">{data.name}</td>
                                                                            <td style={{paddingTop:"10px"}} className="text-center">
                                                                                <a href={ data.url + "?token=" + process.env.REACT_APP_TOKEN }>
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        style={{
                                                                                            marginLeft:"10px",
                                                                                            marginBottom:"5px"
                                                                                        }}
                                                                                    >
                                                                                        Manual de usuario <i className="ti-download" title="Descargar Manual de Usuario" />
                                                                                    </Button>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) :
                                                (
                                                    <h4>Por el momento no cuentas con ningún manual de usuario.</h4>
                                                )
                                            }
                                        </Col>
                                    </Row>


                                    <Row className="btn-group-sign-up pY-20">
                                        <Col xs={12} sm={12} className="dinamic-form-tool-bar">
                                            <Button variant="secondary" className="float-left" onClick={() => props.history.push("/my_expedients")}>Cancelar</Button>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </OneColumn>
    );
};

export default UserManual;
