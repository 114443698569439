import React, { useState } from 'react'
import { createPortal } from 'react-dom'


const IframeFunctional = ({
    children,
    title,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null);
    const mountNode = contentRef && contentRef['contentWindow'] &&
    contentRef['contentWindow']['document'] && contentRef['contentWindow']['document']['body'] ?
    contentRef['contentWindow']['document']['body'] : undefined;

    return (
      <iframe title={title} {...props} ref={setContentRef} scrolling="no" style={{width:'200px', height:'280px'}}>
        {mountNode && createPortal(children, mountNode)}
      </iframe>
    )
};

export default IframeFunctional;