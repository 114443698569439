import React, { useEffect, useState } from "react";
import IframeFunctional from "./IframeFunctional";
import { 
  getTemplatesByType, getAgreementTypes, getPromotionsWithoutAgreement,
  requestTemplatePresave 
} from "./details_expedient/expedient_details_logic";
import ModalComponent from "../helper_components/ModalComponent";
import { Button, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { selectStylesDefault, fieldsValidation } from "./my_expedients/expedients_list_logic";
import Select from "react-select";
import Cookies from "js-cookie";
import { renderErrorsList, renderClassesToErrors } from "../edit_profile/edit_profile_logic";
import { NotificationManager } from "react-notifications";

const stylesDiv = {
  paddingLeft:'10px',
  paddingRight:'15px',
  paddingTop:'15px',
  paddingBottom:'15px',
  fontSize: '5px',
  cursor:'pointer'
};

const stylesDiv2 = {
  width:'160px', 
  height:'240px', 
  fontSize:'5px', 
  backgroundColor:'white',
  pointerEvents: 'none'
};

const stylesDiv3 = {
  paddingBottom:'20px', 
  cursor:'pointer'
};

const stylesFormatted = {
  ...selectStylesDefault,
  menu: (provided, state) => ({
    ...provided,
    zIndex:100
  })
};

export const selectStylesError = {
  control: (provided, state) => {
    return {
      ...provided,
      border: '1px solid red'
    };
  }
};

const PreviewTemplates = ({ 
  type, expedientNumber, 
  setReloadDataTemplates, reloadDataTemplates 
}) => {
  const { userId_03 } = Cookies.get();
  const [templates, setTemplates] = useState([]);
  const [showModalTiny, setShowModalTiny] = useState(false);
  const [templateTiny, setTemplateTiny] = useState({});
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect( () => {
    if (type !== '') {
      (async function(){
        await getTemplatesByType(
            type,
            setTemplates,
            expedientNumber
        );
        await getAgreementTypes(
          setAgreementTypes
        );
        await getPromotionsWithoutAgreement(
          expedientNumber,
          setPromotions
        );
      })()
    }
  }, [])
    
  const openModalTinyTemplate = (template) => {
    if (template) {
      setErrors({});
      setTemplateTiny({
        content: template['content'],
        document_id: template['id'],
        name: template['name'],
        user_id: userId_03,
        expedient: expedientNumber.replace(/-/g, '/'),
        type: type,
        comments: '',
        alias: type === 'promotion' || type === 'demand' ? template['name'] : '',
        answer_promotion:false
      });
      setShowModalTiny(true);
    }
  };

  const handleEditorChange = (value) => {
    delete errors['content'];
    setTemplateTiny({
      ...templateTiny,
      content:value.target.getContent()
    });
  };

  const tinyEditor = (
    <>
      <div style={{margin:'0 auto', width:'80%'}}>
        <Form>
          <Form.Group>
            <Form.Label>Comentarios:</Form.Label>
            <Form.Control
              onChange={(e) => setTemplateTiny({...templateTiny, comments:e.target.value})}
              as="textarea"
              rows="5"
              aria-label="Comentarios"
              maxLength={255}
              className={renderClassesToErrors(errors, 'comments')}
            />
            {renderErrorsList(errors, 'comments')}
          </Form.Group>
          <hr/>

          <Form.Group>
            <Form.Label>* Documento:</Form.Label>
            {renderErrorsList(errors, 'content')}
          </Form.Group>
        </Form>
      </div>
      <div className={"container-preview-templates" + (errors['content'] ? '-error' : '')}>
        <Editor
          apiKey="bugg7r7e8dy612zqv84qvfm9qtb8lm7r1utvvtlsdvtnd9ih"
          initialValue={templateTiny['content']}
          value={templateTiny['content']}
          init={{
            plugins: "link image code",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code",
            language: "es_MX",
            language_url: "/langs/es_MX.js"
          }}
          onChange={(value) => handleEditorChange(value)}
        />
      </div>
    </>
  );

  const handleRequestSaveTemplate = async () => {
    const requiredFields = ['alias', 'content', 'user_id'];
    const responseValidation = fieldsValidation(requiredFields, templateTiny);
    if (typeof responseValidation === 'object') {
      NotificationManager.error('Los campos marcados en rojo son requeridos, revísalos');
      setErrors(responseValidation);
    } else {
      if (typeof templateTiny['alias'] === 'object')
        templateTiny['alias'] = templateTiny['alias']['value'];
      await requestTemplatePresave(
        templateTiny,
        setErrors,
        setShowModalTiny,
        setReloadDataTemplates,
        reloadDataTemplates,
        type
      );
    }
  };

  return (
    <div style={{textAlign:'center'}}>
      <div className="row">
        {
          templates.map( (template, index) => (
            <div  
              key={index} 
              className="col-sm" 
              style={stylesDiv3} 
              onClick={() => openModalTinyTemplate(template)}
            >
              <IframeFunctional title={index} >
                <div
                  style={stylesDiv}
                  onClick={() => openModalTinyTemplate(template)}
                >
                  <div
                    style={stylesDiv2}
                    dangerouslySetInnerHTML={{
                      __html:template['html']
                    }}
                  />
                </div>
              </IframeFunctional>
              <br/>
              <span>{template['name']}</span>
            </div>
          ))
        }
      </div>
      <ModalComponent
        header={`${templateTiny['name']}:`}
        body={tinyEditor}
        footer={(    
          <>
            <Button variant="secondary" onClick={() => setShowModalTiny(false) }>Cerrar</Button>
            <Button variant="success" onClick={() => handleRequestSaveTemplate(templateTiny)}>Guardar</Button>
          </>
        )}
        show={showModalTiny}
        canClose={true}
        onHide={setShowModalTiny}
        dialogClassName="modal-templates-preview"
      />
    </div>
  );
};

export default PreviewTemplates;