import React from "react";
import TinyEditor from "../tiny_libray/TinyPage";
import DynamicPDF from "../react_pdf/PDF";

const DigitalDocument = props => {
  const renderEditorOrPdf =
    props.el.extra_attributes.document_information.type === "pdf" ? (
      <DynamicPDF
        url={props.el.extra_attributes.document_information.data}
        {...props}
      />
    ) : (
      <TinyEditor {...props} />
    );

  return <>{renderEditorOrPdf}</>;
};

export default DigitalDocument;
