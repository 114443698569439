import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import Cookies from "js-cookie";
import _ from "lodash";
import { getOptionsByUrl } from "./dynamic_select_logic";
import { Col, Row } from "react-flexbox-grid";

const DynamicSelect = props => {
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState({});
  // const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    (async function() {
      let selectStateValue = props.state[props.el.name];
      let select = props.el;
      if (_.isObject(selectStateValue)) {
        setSelectValue(selectStateValue);
      } else if (selectStateValue !== "") {
        let { type, options, url } = select.extra_attributes;
        if (type === "manual") {
          const defaultValManual = searchDefaultValueOptions(options);
          setSelectValue(defaultValManual);
        } else {
          const optionsUrlCatalog = await getOptionsByUrl(url, "", "");
          const defaultValueUrlCatalog = searchDefaultValueOptions(
            optionsUrlCatalog,
          );
          setSelectValue(defaultValueUrlCatalog);
        }
      } else {
        setSelectValue(selectStateValue);
      }
    })();
  }, [props.el]);

  function searchDefaultValueOptions(options) {
    let selectStateValue = props.state[props.el.name];
    const defualtValue = options.find(el => el.value === selectStateValue);
    return defualtValue;
  }

  const handleChange = selected => {
    // VERIFICAMOS QUE SE ESTE CAMBIANDO UN CAMPO QUE NO TENGA CAMPOS DEPENDIENTES
    // Y QUE AUN NO HAYA VALOR DE ESTE EN EL STATE
    if (props.el.extra_attributes.dependent_fields && 
        props.el.extra_attributes.dependent_fields.length === 0 && 
        props.state[props.el.name] !== "" ) {
      let dataToSet = {
        ...props.state
      };
      for (let field in props.selectsWithDependentSelects) {
        if (props.selectsWithDependentSelects[field].dependentOf.includes(props.el.name) && typeof props.selectsWithDependentSelects[field].value === "object") {
          // ESTO BORRA EL VALOR DEL SELECT DEPENDIENTE
          props.selectsWithDependentSelects[field].onChange('');
          dataToSet[field] = "";
        }
      }
      dataToSet[props.el.name] = selected;
      // MANDAMOS AL SETSTATE LA DATA(dataToSet) QUE SE DEBE DE BORRAR O AGREGAR
      // SEGUN SEA EL CASO
      props.onChangeHandler(selected, props.el.name, props.el.type, null, dataToSet);
      setSelectValue(selected);
    }
    else {
      props.onChangeHandler(selected, props.el.name, props.el.type);
      setSelectValue(selected);
    }
  };

  async function onFocusSelectHandler(element) {
    let dependentValues = {};
    if (element.extra_attributes.dependent_fields) {
      if (element.extra_attributes.dependent_fields.length > 0) {
        element.extra_attributes.dependent_fields.forEach(dependentField => {
          Object.entries(props.state).forEach(([key, value]) => {
            if (key === dependentField.split(":")[0] ) {
              dependentValues = { ...dependentValues, [dependentField]: value.value };
            }
          });
        });
      }
    }
    if (element.extra_attributes.type !== "manual") {
      setOptions([]);
      // setShowSpinner(true);
      let urlValues = "";
      Object.entries(dependentValues).forEach(([key, value]) => {
        value = value === undefined ? "" : value;
        urlValues = `${urlValues}${key}=${value}&`;
      });
      if (props.el.extra_attributes.url) {
        const options = await getOptionsByUrl(
          props.el.extra_attributes.url,
          dependentValues,
          urlValues,
        );
        // setShowSpinner(false);
        setOptions(options);
      }
    } else {
      setOptions(props.el.extra_attributes.options);
    }
  }

  const customStyles = {
    control: base => ({
      ...base,
      minWidth: 300,
      border: props.el.error ? "solid 1px #d9534f" : "",
    }),
    menu: base => ({
      ...base,
      position: "absolute",
      zIndex: 2,
    }),
  };

  const customStylesDisabled = {
    control: base => ({
      ...base,
      backgroundColor: "#e9ecef",
      opacity: 1,
      border: ".06rem solid #c1c1c1 !important",
    }),
    singleValue: base => ({
      ...base,
      color: "#495057",
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#495057",
    }),
  };

  const disableFieldBasedMethod = el => {
    const { userId_03 } = Cookies.get();

    if ( el.id && el.form_id ) {
      if (!el.readonly) {
        if (props.mode === 2) {
          return true;
        }
          if (props.mode === 1) {
            return false;
          } else {
            if (parseInt(userId_03) !== parseInt(props.applicant)) {
              return true;
            } else {
              if (el.observations) {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
      if (el.readonly === 1) {
        if (props.mode === 2) {
          return true;
        }
        if (props.mode === 1) {
          return true;
        } else {
          if (parseInt(userId_03) !== parseInt(props.applicant)) {
            if (el.observations) {
              return false;
            } else {
              return true;
            } 
          }
        }    
      }
      else return false;
  };

  const validateCloudVisibility = el => {
    if (el.is_validable && props.mode === 3) {
      if (el.observations) {
        return { display: "inline-block" };
      } else {
        return { display: "none" };
      }
    } else {
      return { display: "none" };
    }
  };

  return (
    <Col
      className={props.el.visibility["status"] ? "" : " d-none"}
      xs={12}
      md={props.el.width}
    >
      <Row>
        <Col xs={12}>
          <Form.Label
            className={
              "font-weight-bold " + (props.el.error ? "text-danger" : "")
            }
          >
            <span
              style={
                props.el.requiredSignal
                  ? { display: "inline" }
                  : { display: "none" }
              }
            >
              <b>* </b>
            </span>
            {props.el.label}
            <span
              className="observation-input-icon"
              style={validateCloudVisibility(props.el)}
              onClick={() => props.setShow(props.el)}
            >
              <i
                className={
                  "far fa-comment-dots" +
                  (props.el.observations ? " text-danger" : "")
                }
              />
            </span>
          </Form.Label>
          <Select
            ref={(el) => {
              // BUSCAMOS EL SELECT QUE CONTENGA CAMPOS DEPENDIENTES Y VERIFICAMOS
              // QUE EXISTA EL ELEMENTO DENTRO DEL ref
              if (( props.el && 
                    props.el.extra_attributes && 
                    props.el.extra_attributes.dependent_fields && 
                    props.el.extra_attributes.dependent_fields.length > 0) && 
                    el && el.props
                  ) {
                // AGREGAMOS TODOS LOS CAMPOS DEPENDIENTES DEL SELECT ACTUAL
                let fields = [];
                for (let i = 0; i < props.el.extra_attributes.dependent_fields.length; i++) {
                  fields.push(props.el.extra_attributes.dependent_fields[i].split(':')[0])
                }
                const elementName = props.el['name'];
                // AGREGAMOS LA FUNCION ONCHANGE, EL VALOR Y LOS CAMPOS DEPENDIENTES
                // A  LA VARIABLE 'selectsWithDependentSelects' QUE VIENE EN PROPS
                props.selectsWithDependentSelects[elementName] = {
                  onChange: el.props.onChange,
                  value: el.props.value,
                  dependentOf: fields
                }
              }
            }}
            value={selectValue}
            onChange={selected => handleChange(selected)}
            options={options}
            styles={
              disableFieldBasedMethod(props.el)
                ? customStylesDisabled
                : customStyles
            }
            isDisabled={disableFieldBasedMethod(props.el)}
            onFocus={async e => onFocusSelectHandler(props.el)}
          />
          <small className="form-text text-muted">
            {props.el.contextual_help ? props.el.contextual_help : ""}
          </small>
        </Col>
      </Row>
      {props.errorSelect}
    </Col>
  );
};

export default DynamicSelect;
