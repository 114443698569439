/* eslint-disable no-eval */
import _ from "lodash";
import moment from "moment";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
/* eslint-disable */

//FUNCION QUE ALMACENA TODOS LOS DEFAULT VALIO QUE VENGAN EN LOS CAMPOS HIDDEN Y LOS REGRESO AL FORMULARIO
export const defaultValuesHandler = model => {
  let obj = {};
  model.forEach(el => {
    if (el.type === "hidden") {
      obj = { ...obj, [el.name]: el.default_value };
    }
  });
  return obj;
};

//ULTIMA PETICION CUANDO FINALIZA LA ETAPA
/*************************************************************************************************************************************************/

export const finishLastStep = async (
  stage_id,
  user_id,
  assigned_users,
  props
) => {
  const user = assigned_users ? [assigned_users] : null;
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/finalize_stage`,
    "post",
    null,
    {
      token: process.env.REACT_APP_TOKEN,
      stage_id,
      user_id,
      assigned_users: user
    }
  );
  const finishLastStepData = await request.executeRequest();

  if (finishLastStepData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTimeout(() => {
      NotificationManager.success(finishLastStepData.data.data.data.message);
    }, 500);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTimeout(() => {
      NotificationManager.error(
        finishLastStepData.message,
        "Error " + finishLastStepData.code + ":"
      );
    }, 500);
  }

  const { special_permissions_03 } = Cookies.get();
  if (special_permissions_03) {
    if (JSON.parse(special_permissions_03)) {
      // eslint-disable-next-line array-callback-return
      JSON.parse(special_permissions_03).find(obj => {
        if (obj.start) {
          if (obj.start === "1") {
            props.history.push("/table_process/my_procedures");
          }
        } else {
          props.history.push("/table_process/pending");
        }
      });
    }
  }
};

/**************************************************************************************************************************************************/
/******************************************************************PREVIOUS HANDLER*******************************************************************/

export const settingDefaultValues = (formsSort, setNextButtonDisable, mode) => {
  const valuesForm = formsSort.reduce((acc, element) => {
    if (_.isObject(element.value)) {
      acc[element.name] = element.value.value;
    } else if (typeof element.value == "string") {
      acc[element.name] = element.value;
    } else if (element.default_value) {
      acc[element.name] = element.default_value;
    }

    if (element.type === "digital-document") {

      if (mode === 2) {
        setNextButtonDisable(false);
      } else {
        //Valida si el documento tiene seteada la firma para habilitar o no el boton de siguiente
        if (element.extra_attributes.signature) {
          if (element.extra_attributes.current_stage_signed) {
            setNextButtonDisable(false);
          } else {
            setNextButtonDisable(true);
          }
        } else {
          setNextButtonDisable(false);
        }
        if (element.value !== "") {
          acc[element.name] = element.value;
        }
      }

    }

    if (element.type === "file" && element.value !== "") {
      acc[element.name + "_img"] = element.file_route;
    }

    //VALIDO AL MOMENTO DE CARGAR EL FORMULARIO LOS CAMPOS CHECKBOX
    if (element.type === "check-box") {
      //VALIDO SI HAY UN ATRIBUTO EN EL FORMULARIO QUE TENGA DEFAULT SELECTED
      if (_.has(element.extra_attributes, "default_selected")) {
        //SI EXISTE EL DEFAULT VALIDO SI EN VALUE NO SE LE SETEO ALGO PARA DARLE PRIORIDAD A VALUE
        if (_.isObject(element.value)) {
          //SI EXISTE EL VALOR EN VALUE SETEO LO QUE TRAE VALUE
          acc[element.name] = element.value.value;
        } else {
          //SI NO EXISTE VALUE Y FUE DEFAULT SELECTED ENTONCES LO CHECKEO
          acc[element.name] = true;
        }
      }
      //CUANDO ES UNA LISTA DE CHECKBOX
      if (_.has(element.extra_attributes, "check")) {
        if (_.isObject(element.value)) {
          //VALIDA SI YA EXISTE ALGUN VALOR PARA ESOS CHECKBOX, CUANDO CONTINUAS EL TRAMITE O REGRESAS EN EL BOTON ANTERIOR
          const checkBoxList = element.value.value.map(el => {
            return el;
          });
          //SETEA EN EL STATE LOS VALORES QUE YA VENIAN EN EL VALUE
          acc[element.name] = checkBoxList;
        } else {
          //SI NO EXISTE VALORES YA DEFINIDOS Y CARGA POR PRIMERA VEZ
          if (Array.isArray(element.extra_attributes.check)) {
            const checkBoxList = element.extra_attributes.check.map(el => {
              //RECORRES EL ARREGLO DE CHECKS PARA  VER SI EXISTEN VALORES POR DEFECTO
              if (_.has(el, "preselected")) {
                //SI SI ESTAN PRESELECCIONADOS GUARDA UN TRUE EN EL STATE
                return { [el.name]: true };
              } else {
                //SI NO ESTA PRESELECCIONADO GUARDA EL CURRENT VALUE QUE TENGA EL VALUE DEL ELEMENTO
                return { [el.name]: false };
              }
            });
            //GUARDO EL VALOR DEL NUEVO ARREGLO EN EL ARREGLO DE CHECKLIST
            acc[element.name] = checkBoxList;
          }
        }
      }
    }
    return acc;
  }, {});
  return valuesForm;
};

export const validateOnChangeValues = (
  e,
  key,
  type,
  errorDate,
  state,
  setState,
  form,
  setDataForm,
  dataToSetDependentSelects = null
) => {
  if (type === "file") {
    // uploadDocument(e.target.value);
    setState({ ...state, [key]: e.target.value });
  } else if (type === "date") {
    if (e !== null) {
      //Le doy formato a la fecha
      const dateFormatted = moment(e).format();
      setState({ ...state, [key]: dateFormatted });
      //evaluateVisibility(form, state, e.value, key, type);
    } else {
      //Si la fecha no tiene un formato valido o no existe se manda null
      alert("Error de fecha");
      setState({ ...state, [key]: null });
    }
  } else if (type === "check-box") {
    setState({ ...state, [key]: e.target.checked });
    evaluateVisibility(form, state, e.target.checked, key, type);
  } else if (type === "radio-button") {
    setState({ ...state, [key]: errorDate });
    evaluateVisibility(form, state, errorDate, key, type);
  } else if (type === "map") {
    setState({ ...state, [key]: e });
    //evaluateVisibility(form, state, e.value, key, type);
  } else if (type === "select") {
    if (dataToSetDependentSelects) {
      setState(dataToSetDependentSelects);
      evaluateVisibility(form, state, e.value, key, type);
    }
    else {
      setState({ ...state, [key]: e });
      evaluateVisibility(form, state, e.value, key, type);
    }
  } else if (type === "digital-signature") {
    setState({ ...state, [key]: e });
  } else if (type === "digital-document") {
    setState({ ...state, [key]: e });
  } else {
    setState({ ...state, [key]: e.target.value });
    evaluateVisibility(form, state, e, key, type, setDataForm);
  }
};

export const evaluateVisibility = (
  elements = [],
  values,
  event = null,
  key,
  type,
  checkboxListName = null
) => {
  try {
    // iterate in all form fields
    let flagRegex = false;
    elements.map(field => {
      // validate if exist field rules
      printValidationSignal(field);
      if (typeof field.visibility["rules"] == "object") {
        let visibility = field.visibility;

        // iterate in all rules
        field.visibility["rules"].map(rule => {
          const howManyRules = rule.sentence.split('&&') || rule.sentence.split('||');
          let final_sentence = rule.sentence;
          if (howManyRules.length > 1 && rule['fields'].includes(key)) {

            for (let position = 0; position < rule['fields'].length; position++ ) {
              if (rule['fields'][position] === key) {
                final_sentence = final_sentence.split("@@" + key).join('"' + event + '"');
              }
              else {
                let value = values[rule['fields'][position]];
                if (value) final_sentence = final_sentence.split("@@" + rule['fields'][position]).join( '"' + value.value + '"')
              }
            }
            if (rule.action === "show") {
              try {
                visibility = eval("(" + final_sentence + ")");
                changeVisibilityValue(visibility, field, elements);
              } catch (error) {}
            }
          }
          else {
          // translate rule
          rule.fields.map(field_input => {
            if (type === "checkbox-list") {
              if (field_input === checkboxListName) {
                if (rule.action === "show") {
                  while (!flagRegex) {
                    final_sentence = final_sentence.replace(/ /g, "");
                    final_sentence = final_sentence.replace(/'/g, '"');
                    if (/@@(\w+)\[\]\=\=("\w+")/.test(final_sentence)) {
                      final_sentence = replaceArrayVariables(
                        final_sentence,
                        values,
                      );
                    } else {
                      flagRegex = true;
                    }
                  }
                  try {
                    visibility = eval("(" + final_sentence + ")");
                    changeVisibilityValue(visibility, field, elements);
                  } catch (error) {}
                }
              }
            }
            if (field_input === key) {
              if (
                type === "select" ||
                type === "check-box" ||
                type === "radio-button"
              ) {
                if (rule.action === "show")
                  final_sentence = final_sentence
                    .split("@@" + field_input)
                    .join('"' + event + '"');
                try {
                  visibility = eval("(" + final_sentence + ")");
                  changeVisibilityValue(visibility, field, elements);
                } catch (error) {}
              } else {
                if (rule.action === "show")
                  final_sentence = final_sentence
                    .split("@@" + field_input)
                    .join('"' + event.target.value + '"');
                try {
                  visibility = eval("(" + final_sentence + ")");
                  changeVisibilityValue(visibility, field, elements);
                } catch (error) {}
              }
            }
          });
          }
        });
      }
    });
  } catch (error) {
    return [];
  }

  return elements;
};

function replaceArrayVariables(sentence, state) {
  let regex = /@@(\w+)\[\]\=\=("\w+")/;
  sentence = sentence.replace(/ /g, "");
  let tokenizeSentence = sentence.replace(regex, function(whole, part1, part2) {
    let finalValue = false;
    let cleanValue = part2.split('"').join("");
    Object.entries(state).map(([key, value]) => {
      if (key === part1) {
        if (typeof value === "object") {
          Object.entries(value).map(([k, val]) => {
            if (val[cleanValue]) {
              finalValue = true;
            }
          });
        }
      }
    });
    return finalValue;
  });
  return tokenizeSentence;
}

function changeVisibilityValue(visibilityValue, nameInputChange, elements) {
  elements.forEach(el => {
    if (el.name === nameInputChange.name) {
      el.visibility["status"] = visibilityValue;
    }
  });
  return elements;
}

//GUARDAR ARCHIVO EN SERVIDOR TIPO FILE
/*************************************************************************************************************************************************/

export const saveFileServer = async (file, stage_id, element) => {
  const { userId_03 } = Cookies.get();
  const base64 = await formatBase64(file);
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/upload_document`,
    "post",
    null,
    {
      token: process.env.REACT_APP_TOKEN,
      stage_id: stage_id,
      user_id: userId_03,
      field_id: element.id,
      field_name: element.name,
      file: base64
    }
  );
  const saveFileServerData = await request.executeRequest();
  if (saveFileServerData.code === 200) {
    delete element.error;

    setTimeout(() => {
      NotificationManager.success(
        "El archivo se subio correctamente al servidor"
      );
    }, 500);

    return saveFileServerData.data.data.data.field;
  }
  return null;
};
/**************************************************************************************************************************************************/
const formatBase64 = async string => {
  const formatted = _.split(string, "base64,", 2);
  return formatted[1];
};

/**************************************************************************************************************************************************/

export const isImage = extension => {
  let extensions = ["jpg", "jpeg", "png", "gif"];

  for (let i = 0; i < extensions.length; i++) {
    if (extension === extensions[i]) {
      return true;
    }
  }

  return false;
};

/**************************************************************************************************************************************************/

export const iconUrl = (state, name) => {
  if (state[name] && state[name + "_ext"] && !isImage(state, name)) {
    if (state[name + "_ext"] === "docs") {
      return "/img/docs.svg";
    } else if (state[name + "_ext"] === "pdf") {
      return "/img/pdf.svg";
    } else if (state[name + "_ext"] === "ppt") {
      return "/img/ppt.svg";
    } else if (state[name + "_ext"] === "rar") {
      return "/img/rar.svg";
    } else if (state[name + "_ext"] === "xls") {
      return "/img/xls.svg";
    } else if (state[name + "_ext"] === "zip") {
      return "/img/zip.svg";
    } else {
      return "/img/document.svg";
    }
  }

  return "";
};

/**************************************************************************************************************************************************/

export const iconUrlByExt = ext => {
  if (ext === "docs") {
    return "/img/docs.svg";
  } else if (ext === "pdf") {
    return "/img/pdf.svg";
  } else if (ext === "ppt") {
    return "/img/ppt.svg";
  } else if (ext === "rar") {
    return "/img/rar.svg";
  } else if (ext === "xls") {
    return "/img/xls.svg";
  } else if (ext === "zip") {
    return "/img/zip.svg";
  } else {
    return "/img/document.svg";
  }
};

/**************************************************************************************************************************************************/

const printValidationSignal = element => {
  const validateElement = element.rules_validation
    ? element.rules_validation.split("|").find(el => el === "required")
      ? (element.requiredSignal = true)
      : false
    : "";
};
