import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../classes/RequestNewVersion";

export const initForm = {
  email: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const forgotPassword = async (form, props) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "post",
    null,
    {
      email: form.email,
      app_id: 3
    }
  );
  const forgotPasswordData = await request.executeRequest();

  if (forgotPasswordData.code === 200 && 
      typeof forgotPasswordData.response.message == 'string' && 
      forgotPasswordData.response.message !== '' &&
      !forgotPasswordData.response.error
    ) {

    setTimeout( () => { 
      NotificationManager.success( forgotPasswordData.response.message ); 
    }, 800);
    props.history.push('/');
  }
  else {
    NotificationManager.error( forgotPasswordData.response.message );
  }
};
/***********************************************************************************************************************************************/
