import React from "react";
import { Button } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { Col } from "react-flexbox-grid";
import { getBlobOfFilePDF } from "./pdf_logic";
import Cookies from "js-cookie";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DynamicPDF = props => {
  let signatureModal;
  if (props.url) {
  } else {
    signatureModal = props.el.signature ? (
      <Col className="p-5" xs={12}>
        <DigitalSignatureModal {...props} />
      </Col>
    ) : (
      ""
    );
  }

  const downloadPDFWithToken = async () => {
    const { authentication_token_03 } = Cookies.get();
    const data = {
      url: props.url,
      token: authentication_token_03
    };
    getBlobOfFilePDF(data, function(file64) {
      let blob = new Blob([file64], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = URL.createObjectURL(blob);
      const name = props.el.name;
      a.href = url;
      a.download = name + '.pdf';
      a.click();
    });
  };

  return (
    <Col xs={12} className={props.el.visibility["status"] ? "" : " d-none"}>
      {signatureModal}

      <Button
        variant='secondary'
        onClick={() => downloadPDFWithToken()}
      >
        Descargar Archivo
      </Button>

    </Col>
  );
};

export default DynamicPDF;
