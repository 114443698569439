import React from "react";
import Cookies from "js-cookie";
import { Request } from "../../classes/Request";
import { Card } from "react-bootstrap";

export const getObservations = async (
  stage_id,
  element,
  setFormattObservations
) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/observation/get_comment/${stage_id}/${element.id}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getObservationsData = await request.executeRequest();
  if (getObservationsData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    const observations = await formattObservations(
      getObservationsData.data.data.data.observation
    );
    setFormattObservations(observations);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setFormattObservations(null);
  }
};

/**********************************************************SAVE OBSERVATION************************************************************************/
export const saveObservation = async (
  observation,
  setShow,
  setObservations,
  element,
  stage
) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/observation/create_comment`,
    "post",
    null,
    {
      stage_id: stage,
      field_id: element.id,
      user_id: userId_03,
      description: observation,
      token: process.env.REACT_APP_TOKEN
    }
  );
  const saveObservationData = await request.executeRequest();
  if (saveObservationData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setShow(false);
    setObservations("");
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    console.log(saveObservationData);
  }
};
/***********************************************************************************************************************************************/

export const formattObservations = async observations => {
  let observationFormatted = [];
  if (observations.length > 0) {
    observationFormatted = observations.map((el, index) => {
      return (
        <Card key={index}>
          <Card.Body>
            <Card.Text>{el.description}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Updated {el.updated_at}</small>
          </Card.Footer>
        </Card>
      );
    });
  } else {
    return "";
  }
  return observationFormatted;
};
/***********************************************************************************************************************************************/

/**********************************************************APROVED COMMENT************************************************************************/
export const onApprovedCommentRequest = async (setShow, element, stage) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/observation/approved_field`,
    "put",
    null,
    {
      stage_id: stage,
      field_id: element.id,
      token: process.env.REACT_APP_TOKEN
    }
  );
  const onApprovedCommentRequestData = await request.executeRequest();
  if (onApprovedCommentRequestData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setShow(false);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    console.log(onApprovedCommentRequestData);
  }
};
/***********************************************************************************************************************************************/
