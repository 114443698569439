/* eslint-disable no-const-assign */
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic"

export class RequestNewVersion {

  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
  }

  async executeRequest(show = true, token = null) {
    
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };
    let authenticationToken = Cookies.get( "authentication_token_03" ) ?
      String(Cookies.get( "authentication_token_03" )) :
      btoa( process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION );

    if (token) {
      authenticationToken = token;
    }

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });

      const { clean } = response.data.data ? response.data.data : false;
      if (clean) {
        DestroyCookies();
        window.location.href = "/";
      }

      if(response.status === 200 && response.statusText === "OK"){

        return {
          code: response.data.code,
          response: response.data.data,
          completeResponse: response
        }
      }

    }
    catch(error){

      responseError = {
        code: 500,
        message: "El servidor ha respondido con un error"
      };

      NotificationManager.error(
        responseError.message,
        "Error " + responseError.code + ":"
      );
    }

  }
}
