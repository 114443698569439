import React from "react";
import { 
  Button, Form, Carousel, Badge, FormControl,
  Dropdown, InputGroup, DropdownButton } from "react-bootstrap";
import Select from "react-select";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import RenderPdfDocument from "../../helper_components/RenderDocumentPdf";
import { selectStylesDefault, renderErrorsByInputName, selectStylesError } from "../my_expedients/expedients_list_logic";

export const columns = [
  {
    dataField: "actions",
    text: "Documentos",
    sort: true,
    style: { maxWidth:"250px", minWidth:"120px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "time_indicator",
    text: "Indicador",
    sort: true,
    style: { maxWidth:"250px", minWidth:"120px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "filename",
    text: "Nombre",
    sort: true,
    style: { maxWidth:"250px", minWidth:"100px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "document_type",
    text: "Tipo",
    sort: true,
    style: { maxWidth:"250px", minWidth:"100px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "created_date",
    text: "Creación",
    sort: true,
    style: { maxWidth:"80%", minWidth:"100px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: true,
    style: { maxWidth:"250px", minWidth:"100px", textAlign:"justify" },
    headerStyle: { textAlign:"center" }
  }
];

export const getDocumentsByExpedientNumber = async (
  expedient_number,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/documents/${expedient_number}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();
  if ( responseDocumentsExpedient.code === 200 ) {
    if ( responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient ) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day:'numeric',
          month:'long',
          year:'numeric'
        }) : null
      };

      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments( 
        responseDocumentsExpedient.response.documents.length > 0 ? 
        "" : 
        responseDocumentsExpedient.response.message 
      );
    }
  }
};

export const getSearchDocumentByExpedientNumber = async (
  expedient_number,
  searchValue,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/search/documents/${searchValue}/${expedient_number}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();
  if ( responseDocumentsExpedient.code === 200 ) {
    if ( responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient ) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day:'numeric',
          month:'long',
          year:'numeric'
        }) : null
      };

      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments( 
        responseDocumentsExpedient.response.documents.length > 0 ? 
        "" : 
        responseDocumentsExpedient.response.message 
      );
    }
  }
};

const formattedDocuments = (dataResponse, loader, setStateDocuments) => {
  let { expedient, documents } = dataResponse;
  const userMatch = String(expedient['user_id']) === Cookies.get('userId_03');
  let doc_type = 'Acuerdo';

  return documents.map( (document, index) => {
    
    const timeIndicator = checkExpiredTime(document.expired);

    document["created_date"] = new Date( document["created_at"].replace(/-/g, "/") ).toLocaleDateString('es-MX', {
                                day:'numeric',
                                month:'long',
                                year:'numeric'
                              });
    document["actions"] = (
        <div className="procedures-actions">
          <button 
            onClick={userMatch ? () => downloadFile(document["file_path"], loader) : null} 
            className={
              userMatch ? 
              "btn btn-info" : 
              "btn btn-secondary no-event-click"
            } 
          >
            <i className="ti-notepad" />
          </button>
          <button 
            //onClick={userMatch ? () => downloadFile(document["evidence_path"], loader) : null} 
            onClick={userMatch ? () => setStateDocuments(document['id']) : null} 
            className={ 
              document["evidence_path"] && userMatch ? 
              "btn button-see-evidence-documents mL-10" : 
              document["evidence_path"] && !userMatch ? 
              "btn btn-secondary mL-10 no-event-click" : 
              "d-n"
            }
            title="Documento de pruebas"
          >
            <i className="ti-files" />
          </button>
        </div>
    );
    document['time_indicator'] = (<span className={"dot-" + timeIndicator}/>);

    return document;
  });
};

const checkExpiredTime = (dataExpired) => {
  if (dataExpired.expired_at && dataExpired.warning && !dataExpired.answered_at) {
    const expiredDate = new Date(dataExpired.expired_at);
    const todayDate = new Date();
    const expired = new Date(
      expiredDate.getFullYear(),
      expiredDate.getMonth(),
      expiredDate.getDate()
    );
    const today = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate()
    );
    const timeTranscurred = (expired.getTime() - today.getTime()) / 86400000;
    if (timeTranscurred >= dataExpired.warning) return 'green';
    if (timeTranscurred < dataExpired.warning && timeTranscurred >= 0) return 'yellow';
    if (timeTranscurred < 0) return 'red';
    return 'white';
  } else return 'white';
};

export const documentTypeCatalog = [
  {
    value:"promotion",
    label:"Promoción"
  },
  {
    value:"agreement",
    label:"Acuerdo"
  },
  {
    value:"judgment",
    label:"Sentencia"
  }
];

export const anexosTypeCatalog = [
  {
    value:"original",
    label:"Original"
  },
  {
    value:"simple",
    label:"Simple"
  },
  {
    value:"certified",
    label:"Certificada"
  }
];

export const getPromotionsWithoutAgreement = async (
  expedient_number,
  setPromotions
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/promotions_by_expedient_number/${expedient_number}`,
    "get",
    null,
    {}
  );

  const responsePromotions = await request.executeRequest();
   if ( 
     responsePromotions.code === 200 && 
     responsePromotions.response.promotions &&
     responsePromotions.response.promotions.length > 0 ) {

      const promotionsFormatted = await formattedPromotions( responsePromotions.response.promotions );
      setPromotions(promotionsFormatted);
   }
   else if ( 
    responsePromotions.response.promotions &&
     responsePromotions.response.promotions.length === 0 &&
     responsePromotions.response.message &&
     responsePromotions.response.message !== "" &&
     typeof responsePromotions.response.message === "string" ) {

      setPromotions([]);
     }
     else {

      NotificationManager.error('Ocurrió un error al traer las promociones, inténtalo nuevamente');
      setPromotions([]);
     }
}; 

export const formattedPromotions = (
  promotions
) => {

  return promotions.reduce( (array,promotion) => {
    
    const promotionFormatted = {};
    promotionFormatted['label'] = promotion['alias'] ? promotion['alias'] : promotion['filename'] ;
    promotionFormatted['value'] = promotion['id'];

    array.push(promotionFormatted);
    return array;
  },[]);
};

export const downloadFile = (urlFile, loader) => {
  loader.show(true);
  let a = document.createElement('a');
  a.href = urlFile;
  a.download = true;
  a.target = "_blank"; 
  a.rel = "noopener noreferrer";

  a.click();
  loader.show(false);  
};

export const bodyModalDocuments = (documents) => {

  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
      <tbody>
        <tr className="table-documents-modal">
          <th className="text-center">Etiqueta</th>
          <th className="text-center">Tipo</th>
          <th className="text-center">Enlace de descarga</th>
        </tr>
          {
            documents.map( ( doc, index ) => (
              <tr key={index}>
                  <td style={{paddingTop:"5px", fontSize:"0.8rem", maxWidth:"140px"}} className="text-center" title={doc['alias']}>{doc['alias']}</td>
                  <td style={{paddingTop:"5px", fontSize:"0.8rem", maxWidth:"140px"}} className="text-center" title={doc['document_type']}>{doc['document_type']}</td>
                  <td style={{paddingTop:"5px"}} className="text-center">
                      <a download target="_blank" rel="noopener noreferrer" href={ doc['file_path'] + "?token=" + process.env.REACT_APP_TOKEN }>
                          <Button
                              variant="primary"
                              style={{
                                  marginLeft:"10px",
                                  fontSize:"0.85rem"
                              }}
                              size="sm"
                          >
                              Archivo digital <i className="ti-download" title={"Descargar " + doc['alias']} />
                          </Button>
                      </a>
                  </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};


export const footerModalDocuments = (setCloseModal) => {

  return (
    <>
        <Button variant="secondary" onClick={ () => setCloseModal() } >Cerrar</Button>
    </>
  );
};

export const optionsDocument = [
  {
    value: 'Copia simple',
    label: 'Copia simple'
  },
  {
    value: 'Copia certificada',
    label: 'Copia certificada'
  },
  {
    value: 'Original',
    label: 'Original'
  }
];

export const bodyModalAddEvidenceFile = (
  setFileState, fileState, getRootProps,
  errors, setErrors, getInputProps
) => {

  return (
    <div>
      <Form>

        <Form.Group>
          <Form.Label>* Tipo de documento:</Form.Label>
          
          <Select 
              options={optionsDocument}
              onChange={ async (selected) => {

                  setFileState({ ...fileState, evidence_type: selected["value"]});
                  setErrors({ ...errors, evidence_type:[] });
              }}
              placeholder="Selecciona el tipo de documento"
              styles={selectStylesDefault}
          />
          { renderErrorsByInputName(errors, "evidence_type") }
      </Form.Group>

      <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control 
              onChange={ (e) =>  {
                  setErrors({...errors, alias:[]});
                  setFileState({ ...fileState, alias:e.target.value});
              }} 
              placeholder="Agrega una etiqueta para identificar este documento"
          />
          { renderErrorsByInputName(errors, "alias") }
      </Form.Group>

      <Form.Group>
          <Form.Label>* Anexo:</Form.Label>

          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Button style={{fontWeight:"bold", fontSize:"14px"}} variant="primary" size="md" block>
                * Selecciona el anexo a subir
              </Button>
            </div>
          </section>
          { renderErrorsByInputName(errors, "evidence_file") }

          {
            fileState["evidence_file"] && fileState["evidence_file"]["preview"] ?
            <>
              <div
                  className={ fileState["evidence_file"] && fileState["evidence_file"]["preview"] ? "container-evidence-file" : "d-none" }
              >
                  <img
                      src={fileState["evidence_file"]["preview"] }
                      alt="Imagen"
                      width="50%"
                  />
              </div>
              <small style={{float:"right"}}>{ fileState["evidence_file"]["name"] }</small>
              <br/>
            </> :
            ""
          }
      </Form.Group>
        
      </Form>
    </div>
  );
};

export const getEvidencesDocument = async (
  documentId,
  setDocumentsData,
  setShowDocuments
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/retrieve/${documentId}`,
    "get",
    null,
    {}
  );

  const responseEvidences = await request.executeRequest();
  if(
    responseEvidences.code === 200 &&
    responseEvidences.response &&
    responseEvidences.response.evidences
  ) {
    const { evidences } = responseEvidences.response;
    setDocumentsData(evidences);
    setShowDocuments(true);
  }
  else {
    setDocumentsData([]);
    setShowDocuments(true);
    NotificationManager.info(
      'No se encontraron anexos en esta promoción',
      '',
      3500
    );
  }
}

export const getAgreementTypes = async (
  setAgreementTypes
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/headings`,
    "get",
    null,
    {}
  );

  const responseAgreementTypes = await request.executeRequest();
  if (
    responseAgreementTypes.code === 200 &&
    responseAgreementTypes.response &&
    responseAgreementTypes.response.headings
  ) {
    let { headings } = responseAgreementTypes.response;
    headings = formattedHeadings(headings);
    setAgreementTypes(headings);
  } else {
    NotificationManager.error(
      'No fue posible recuperar los rubros para la creación de este acuerdo',
      3500
    );
    setAgreementTypes([{value:'', label:'No existen rubros disponibles'}]);
  }
};

export const formattedHeadings = (headings) => {

  return headings.reduce( (newArr, heading) => {
    let newHeading = {};
    newHeading['label'] = heading['name'];
    newHeading['value'] = heading['name'];

    newArr.push(newHeading);

    return newArr;
  }, []);
};

export const getTemplatesByType = async (
  type,
  setTemplates,
  expedientNumber
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_template/${userId_03}/${type}/${expedientNumber}`,
    "get",
    null,
    {}
  );

  const responseTemplates = await request.executeRequest();
  type = type + 's';
  if (
    responseTemplates.code === 200 &&
    responseTemplates.response && 
    responseTemplates.response[type]
  ) {
    let templates = [];
    for (let template in responseTemplates.response[type]) {
      responseTemplates.response[type][template]['html'] = responseTemplates.response[type][template]['html'].replace(/\/?h[1-6]/ig, 'p');
      responseTemplates.response[type][template]['html'] = responseTemplates.response[type][template]['html'].replace(/font-size/g, 'color');
      templates.push(responseTemplates.response[type][template]);
    }
    setTemplates(templates);
  }
  else {
    setTemplates([]);
    NotificationManager.warning(
      `No se han encontrado plantillas de tipo ${type === 'agreements' ? 'acuerdo' : 'notificación'}`,
      '',
      5000
    );
  }
};

export const getTemplatesPresaved = async (
  expedient_number,
  setTemplatesAgreement,
  setTemplatesNotification
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_template/${userId_03}/${expedient_number}`,
    "get",
    null,
    {}
  );

  const responseTemplates = await request.executeRequest();
  if (
    responseTemplates.code === 200 &&
    responseTemplates.response &&
    responseTemplates.response.Templates &&
    (responseTemplates.response.Templates.Demands || 
    responseTemplates.response.Templates.Promotions)
  ) {
    if (responseTemplates.response.Templates.Demands) setTemplatesAgreement(
      responseTemplates.response.Templates.Demands
    ) 
    else setTemplatesAgreement([]);
    if (responseTemplates.response.Templates.Promotions) setTemplatesNotification(
      responseTemplates.response.Templates.Promotions
    ) 
    else setTemplatesNotification([])
    
  } else {
    setTemplatesNotification([]);
    setTemplatesAgreement([]);
  }
};

export const requestTemplatePresave = async (
  template,
  setErrors,
  setOpenModalCreateTemplate,
  setReloadDataTemplates,
  reloadDataTemplates,
  type
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/set_expedient_template`,
    "post",
    null,
    {...template}
  );

  const responsePresaveTemplates = await request.executeRequest();
  if (
    responsePresaveTemplates.code === 422 &&
    responsePresaveTemplates.response.error &&
    typeof responsePresaveTemplates.response.message === 'object'
  ) {
    setErrors(responsePresaveTemplates.response.message);
    NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
  } else if (
    responsePresaveTemplates.code === 200 &&
    !responsePresaveTemplates.response.error &&
    responsePresaveTemplates.response.message &&
    typeof responsePresaveTemplates.response.message === 'string' &&
    responsePresaveTemplates.response.newExpedientTemplate
  ) {
    setReloadDataTemplates(!reloadDataTemplates);
    NotificationManager.success(
      type === 'agreement' ? 
      'El proyecto de acuerdo ha sido guardado correctamente' :
      'El proyecto de sentencia ha sido guardado correctamente'
    );
    setOpenModalCreateTemplate(false);
  } else {
    NotificationManager.error('¡Ha ocurrido un error inesperado y el template no fue creado, inténtalo nuevamente!');
  }
};

export const getUserTemplateById = async (
  templateId,
  setTemplate,
  setOpenModal
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_user_templates/${userId_03}/${templateId}`,
    "get",
    null,
    {}
  );

  const responseUserTemplate = await request.executeRequest();
  if (
    responseUserTemplate.code === 200 &&
    responseUserTemplate.response &&
    responseUserTemplate.response.ExpedientTemplates &&
    responseUserTemplate.response.ExpedientTemplates[0]
  ) {
    const template = responseUserTemplate.response.ExpedientTemplates[0];
    //const promotions = template['promotions'].map((p) => ({label:p['alias'], value:p['id']}));
    setTemplate({
      id:template['id'],
      alias:template['alias'],
      expedient:template['expedient'],
      type:template['type'],
      last_comment:template['comments'],
      comments:'',
      filename:template['filename'],
      content:template['content'],
      body:template['content'],
      answer_promotion: template['answer_promotion'],
      //promotions:promotions,
      user_id: template['user_id']
    });
    setOpenModal(true);
  } else {
    setTemplate({});
    NotificationManager.error('No fue posible encontrar la plantilla corespondiente');
  }
};

export const requestSendUpdateTemplate = async (
  template, setShowModalTiny, 
  setReloadData, reloadData
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/update_content_expedient_template`,
    "put",
    null,
    {...template}
  );

  const responseUpdateTemplate = await request.executeRequest();
  if (
    responseUpdateTemplate.code === 200 &&
    responseUpdateTemplate.response &&
    !responseUpdateTemplate.response.error &&
    responseUpdateTemplate.response.expedientTemplate &&
    typeof responseUpdateTemplate.response.expedientTemplate === 'object'
  ) {
    const type = responseUpdateTemplate.response.expedientTemplate.type;
    setReloadData(!reloadData);
    NotificationManager.success(
      type === 'agreement' ?
      'El proyecto de acuerdo ha sido actualizado correctamente' :
      'El proyecto de sentencia ha sido actualizado correctamente', 
      '', 5000
    );
    setShowModalTiny(false);
  } else {
    NotificationManager.error(
      'Ha ocurrido un error desconocido al actualizar la plantilla, inténtalo nuevamente...', 
      5000
    );
  }
};

export const bodyDeleteTemplate = (
  <div>
    <b>¿Estás seguro de eliminar este elemento? Esta acción es irreversible</b>
  </div>
);

export const requestDeleteTemplate = async (
  templateId, setOpenDeleteTemplate,
  setReloadData, reloadData
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unsigned_documents/${userId_03}/${templateId}`,
    "delete",
    null,
    {}
  );

  const responseDeleteTemplate = await request.executeRequest();
  if (
    responseDeleteTemplate.code === 200 &&
    responseDeleteTemplate.response.error === false
  ) {
    setReloadData(!reloadData);
    NotificationManager.success(
      responseDeleteTemplate.response.message || 'El elemento ha sido eliminado correctamente',
      '',
      4500
    );
    setOpenDeleteTemplate(false);
  } else {
    NotificationManager.error(
      'Ha ocurrido un error al intentar eliminar el elemento, inténtalo nuevamente...',
      '',
      4500
    );
  }
};

export const getCommentsByTemplate = async (
  templateId, setComments, setOpenComments
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_template_comments/${templateId}`,
    "get",
    null,
    {}
  );
  const responseComments = await request.executeRequest();
  if (
    responseComments && responseComments.code === 200 &&
    responseComments.response && responseComments.response.comments
  ) {
    setComments(responseComments.response.comments);
    setOpenComments(true);
  } else {
    setComments([]);
    setOpenComments(false);
  }
};

export const requestCreatePDFTemplate = async (
  pdfTemplate, setShowModalTiny, 
  setReloadData, reloadData,
  setErrors, reloadParentComponent
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/template_pdf`,
    "post",
    null,
    {...pdfTemplate}
  );

  const responsePDFTemplate = await request.executeRequest();
  if (
    responsePDFTemplate &&
    responsePDFTemplate.code === 200 && 
    responsePDFTemplate.response &&
    typeof responsePDFTemplate.response === 'object'
  ) {
    setReloadData(!reloadData);
    await reloadParentComponent(
      '¡El documento ha sido creado correctamente!',
      '',
      4500
    );
    setShowModalTiny(false);
  } else {
    if (
      responsePDFTemplate &&
      responsePDFTemplate.code === 422 &&
      responsePDFTemplate.response &&
      responsePDFTemplate.response.error &&
      typeof responsePDFTemplate.response.message === 'object'
    ) {
      const keysErrors = Object.keys(responsePDFTemplate.response.message);
      setErrors(responsePDFTemplate.response.message);
      NotificationManager.error(
        responsePDFTemplate.response.message[keysErrors[0]][0],
        'Revisa los errores marcados en rojo:',
        4500
      );
    } else {
      NotificationManager.error(
        'Ha ocurrido un error desconocido, vuelve a intentarlo...',
        '',
        4500
      );
    }
  }
};

export const requestCommentAndRelease = async (
  templateId,
  data,
  props
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/return_to_correction/${templateId}`,
    "post",
    null,
    {...data}
  );
  const responseCommentAndRelease = await request.executeRequest();
  if (
    responseCommentAndRelease &&
    responseCommentAndRelease.code === 200 &&
    responseCommentAndRelease.response &&
    responseCommentAndRelease.response.governmentBook
  ) {
    NotificationManager.success(
      '',
      'El expediente ha sido devuelto para correción',
      4500
    );
    props.history.push('/pending_transfers');
  } else {
    NotificationManager.error(
      '',
      'Ha ocurrido un error desconocido al intentar devolver este expediente para corrección',
      4500
    );
  }
};

export const bodyDocumentsView = (
  currentDocument, setCurrentDocument, 
  documentsView, pause, setPause
) => {
  return (
    documentsView.length > 0 &&   
      <div
        onMouseOut={() => setPause(true)}
        onMouseOver={() => setPause(false)}
      >
        <Carousel 
          pause='hover' 
          activeIndex={currentDocument} 
          onSelect={(selected) => {
            if (!pause) setCurrentDocument(selected) 
          }}
          indicators={false}
        >
          {documentsView.map((doc, i) => (
            <Carousel.Item interval={10000} key={i}>
              <div className="document-pdf-expedients" style={{ minHeight:'70vh', height: 'auto' }}>
              {doc['extension'] === 'pdf' ?
                <RenderPdfDocument
                  doc={doc['file_path']}
                  currentDocument={documentsView[currentDocument]['file_path']}
                /> 
              : 
                <div style={{ textAlign:'center' }}>
                  <img 
                    style={{ width:'auto', maxWidth:'70vw', marginTop:'10vh', marginBottom:'10vh' }} 
                    src={doc['file_path']}
                  />
                </div>
              }
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    
  );
};

export const handleRequestPerformances = async (
  setOptions
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/performances`,
    "get",
    null,
    {}
  );
  const responsePerformances = await request.executeRequest();
  if (
    responsePerformances && responsePerformances.code === 200 &&
    responsePerformances.response && responsePerformances.response.performances &&
    responsePerformances.response.performances.length > 0 
  ) {
    setOptions(responsePerformances.response.performances.map((p) => ({
      label: p.name,
      value: p.id,
      has_expiration: p.has_expiration || false,
      relief: p.relief || false
    })));
  };
};

export const bodyTransfer = (
  courtCatalogs, expedient, dataTransfer, setDataTransfer, usersOptions,
  dependencesOptions, errors
) => {
  const usersOptionsFiltered = usersOptions.filter((u) => 
    Number(u.value) !== Number(Cookies.get('userId_03')));

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>
            <b style={errors['type'] ? { color: 'red' } : null}>
              * Elige que tipo de turnado deseas hacer
            </b>
          </Form.Label>
          <div style={{ display:'flex', justifyContent:'space-around', marginLeft: '15px' }}>
            <Form.Check 
              type='checkbox' 
              label='Turnado interno'
              checked={dataTransfer['type'] === 'internal'}
              onChange={() => {
                delete dataTransfer['external'];
                delete errors['type'];
                setDataTransfer({
                  ...dataTransfer,
                  type: 'internal',
                  receiver: null
                });
              }}
              id='internal'
            />
            <Form.Check 
              type='checkbox' 
              label='Turnado externo' 
              style={{ marginLeft:'10px' }}
              checked={dataTransfer['type'] === 'external'}
              onChange={() => {
                delete errors['type'];
                setDataTransfer({
                  ...dataTransfer,
                  type: 'external',
                  external: true,
                  receiver: null
                });
              }}
              id='external'
            />
          </div>
          { renderErrorsByInputName(errors, 'type') }
        </Form.Group>

        { 
          dataTransfer['type'] === 'internal' ?
          <Form.Group>
            <Form.Label>
              <b style={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? { color: 'red' } : null}>
                * Elige el usuario al que turnaras este expediente
              </b>
            </Form.Label>
            <Select
              options={usersOptionsFiltered}
              onChange={(selected) => {
                delete errors['receiver'];
                setDataTransfer({
                  ...dataTransfer,
                  receiver: selected
                });
              }}
              placeholder="Da clic y elige algún usuario"
              styles={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? 
                selectStylesError : selectStylesDefault}
              value={dataTransfer.receiver}
            />
            {renderErrorsByInputName(
              errors, 
              errors['receiver'] ? 'receiver' : errors['receiver_id'] ? 'receiver_id' : 'dependence_id'
            )}
          </Form.Group> : dataTransfer['type'] === 'external' ?
          <Form.Group>
            <Form.Label>
              <b style={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? { color: 'red' } : null}>
                * Elige la dependencia a la que turnaras este expediente
              </b>
            </Form.Label>
            <Select
              options={dependencesOptions}
              onChange={(selected) => {
                delete errors['receiver'];
                setDataTransfer({
                  ...dataTransfer,
                  receiver: selected
                });
              }}
              placeholder="Da clic y elige alguna dependencia"
              styles={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? 
                selectStylesError : selectStylesDefault}              
              value={dataTransfer.receiver}
            />
            {renderErrorsByInputName(
              errors, 
              errors['receiver'] ? 'receiver' : errors['receiver_id'] ? 'receiver_id' : 'dependence_id'
            )}
          </Form.Group> : '' 
        }

        {
          dataTransfer['type'] ? 
          <>
            <Form.Group className={dataTransfer['expedient_number'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Id del trámite:</b>
              </Form.Label>
              <Form.Control 
                disabled 
                value={ dataTransfer["expedient_number"] ? dataTransfer["expedient_number"] : "" } 
                aria-label="numero de expediente"
                placeholder="Número del expediente"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['expedient_number'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Número de expediente del juzgado:</b>
              </Form.Label>
              <Form.Control
                value={ dataTransfer["court_expedient"] ? dataTransfer["court_expedient"] : "" } 
                aria-label="numero de expediente"
                placeholder="Número de expediente del juzgado"
                onChange={ (e) => {
                  if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                    delete errors['receiver'];
                    setDataTransfer({ ...dataTransfer,  court_expedient:e.target.value});
                  }
                }}
              />
            </Form.Group>

            <Form.Group className={dataTransfer['expedient_number'] ? '' : 'd-n'}>
              <Form.Label>
                <b>El solicitante es ¿demandado o demandante?</b>
              </Form.Label>
              <Form.Control 
                disabled 
                value={ dataTransfer["demand"] ? dataTransfer["demand"] : "" } 
                aria-label="numero de expediente"
                placeholder="Escribe si el solicitante es demandado o demandante"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['court'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Materia:</b>
              </Form.Label>
              <Form.Control 
                disabled 
                value={ dataTransfer["court"] ? dataTransfer["court"] : "" } 
                aria-label="numero de expediente"
                placeholder="Materia"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['expedient_number'] ? '' : 'd-n'}>
              <Form.Label>Juzgado:</Form.Label>
              <Select
                value={dataTransfer['courtCatalogOption']}
                placeholder="Selecciona el juzgado"
                styles={Object.keys(errors).includes('courtCatalogOption') ? selectStylesError : selectStylesDefault}
                menuPlacement="bottom"
                maxMenuHeight={150}
                onChange={(selected) => {
                  delete errors['receiver'];
                  setDataTransfer({
                    ...dataTransfer,
                    ['courtCatalogOption']: selected
                  });
                }}
                options={courtCatalogs}
              />
              { renderErrorsByInputName(errors, "courtCatalogOption") }
            </Form.Group>

            <Form.Group className={dataTransfer['actor_name'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Nombre del actor:</b>
              </Form.Label>
              <Form.Control 
                disabled
                value={ dataTransfer["actor_name"] ? dataTransfer["actor_name"] : "" } 
                aria-label="actor_name"
                placeholder="Nombre o nombres del actor (promovente)"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['actor_firstname'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Primer apellido del actor:</b>
              </Form.Label>
              <Form.Control 
                disabled
                value={ dataTransfer["actor_firstname"] ? dataTransfer["actor_firstname"] : "" } 
                aria-label="actor_firstname"
                placeholder="Primer apellido del actor (promovente)"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['actor_secondname'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Segundo apellido del actor:</b>
              </Form.Label>
              <Form.Control 
                disabled
                value={ dataTransfer["actor_secondname"] ? dataTransfer["actor_secondname"] : "" } 
                aria-label="actor_secondname"
                placeholder="Segundo apellido del actor (promovente)"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['actor_email'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Notificaciones electrónicas del actor:</b>
              </Form.Label>
              <Form.Control 
                disabled
                value={ dataTransfer["actor_email"] ? dataTransfer["actor_email"] : "" } 
                aria-label="actor_email"
                placeholder="Correo electrónico del actor (promovente)"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['lawyer_actor_name'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Nombre(s) del abogado patrono del actor:</b>
              </Form.Label>
              <Form.Control 
                disabled
                value={ dataTransfer["lawyer_actor_name"] ? dataTransfer["lawyer_actor_name"] : "" } 
                aria-label="lawyer_actor_name"
                placeholder="Nombre(s) del abogado patrono del actor"
              />
            </Form.Group>

            <Form.Group className={dataTransfer['lawyer_actor_firstname'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Primer apellido del abogado patrono del actor:</b>
              </Form.Label>
              <Form.Control 
                value={ dataTransfer["lawyer_actor_firstname"] ? dataTransfer["lawyer_actor_firstname"] : "" } 
                aria-label="lawyer_actor_firstname"
                placeholder="Primer apellido del abogado patrono del actor"
                disabled
              />
            </Form.Group>

            <Form.Group className={dataTransfer['lawyer_actor_secondname'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Segundo apellido del abogado patrono del actor:</b>
              </Form.Label>
              <Form.Control 
                value={ dataTransfer["lawyer_actor_secondname"] ? dataTransfer["lawyer_actor_secondname"] : "" } 
                aria-label="lawyer_actor_secondname"
                placeholder="Nombre del abogado patrono del actor"
                disabled
              />
            </Form.Group>

            { dataTransfer['defendant'] ?
            <>
              <Form.Group>
                <Form.Label>
                  <h4>Este expediente cuenta con demandado:</h4>
                </Form.Label>
                <hr style={{marginTop:'-9px'}}/>
              </Form.Group>

              <Form.Group className={dataTransfer['defendant_name'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Nombre(s) del demandado:</b>
                </Form.Label>
                <Form.Control 
                  disabled
                  value={ dataTransfer["defendant_name"] ? dataTransfer["defendant_name"] : "" } 
                  aria-label="demandado"
                  placeholder="Nombre(s) del demandado"
                />
              </Form.Group>

              <Form.Group className={dataTransfer['defendant_firstname'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Primer apellido del demandado:</b>
                </Form.Label>
                <Form.Control 
                  disabled
                  value={ dataTransfer["defendant_firstname"] ? dataTransfer["defendant_firstname"] : "" } 
                  aria-label="demandado"
                  placeholder="Primer apellido del demandado"
                />
              </Form.Group>

              <Form.Group className={dataTransfer['defendant_secondname'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Segundo apellido del demandado:</b>
                </Form.Label>
                <Form.Control 
                  disabled
                  value={ dataTransfer["defendant_secondname"] ? dataTransfer["defendant_secondname"] : "" } 
                  aria-label="demandado"
                  placeholder="Segundo apellido del demandado"
                />
              </Form.Group>

              <Form.Group className={dataTransfer['defendant_email'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Notificaciones electrónicas del demandado:</b>
                </Form.Label>
                <Form.Control 
                  disabled
                  value={ dataTransfer["defendant_email"] ? dataTransfer["defendant_email"] : "" } 
                  aria-label="defendant_email"
                  placeholder="Correo electrónico del demandado"
                />
              </Form.Group>

              <Form.Group className={dataTransfer['lawyer_defendant_name'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Nombre(s) del abogado patrono del demandado:</b>
                </Form.Label>
                <Form.Control 
                  value={ dataTransfer["lawyer_defendant_name"] ? dataTransfer["lawyer_defendant_name"] : "" } 
                  aria-label="lawyer_defendant_name"
                  placeholder="Nombre(s) del abogado patrono del demandado"
                  disabled
                />
              </Form.Group>

              <Form.Group className={dataTransfer['lawyer_defendant_firstname'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Primer apellido del abogado patrono del demandado:</b>
                </Form.Label>
                <Form.Control 
                  value={ dataTransfer["lawyer_defendant_firstname"] ? dataTransfer["lawyer_defendant_firstname"] : "" } 
                  aria-label="lawyer_defendant_firstname"
                  placeholder="Primer apellido del abogado patrono del demandado"
                  disabled
                />
              </Form.Group>

              <Form.Group className={dataTransfer['lawyer_defendant_secondname'] ? '' : 'd-n'}>
                <Form.Label>
                  <b>Segundo apellido del abogado patrono del demandado:</b>
                </Form.Label>
                <Form.Control 
                  value={ dataTransfer["lawyer_defendant_secondname"] ? dataTransfer["lawyer_defendant_secondname"] : "" } 
                  aria-label="lawyer_defendant_secondname"
                  placeholder="Segundo apellido del abogado patrono del demandado"
                  disabled
                />
              </Form.Group>
            </> : '' }

            <Form.Group className={dataTransfer['kind_judgment'] ? '' : 'd-n'}>
              <Form.Label>
                <b>Tipo de Juicio:</b>
              </Form.Label>
              <Select
                isDisabled
                options={[]}
                placeholder="Tipo de Juicio"
                defaultValue={ dataTransfer["kind_judgment"] ? dataTransfer["kind_judgment"] : null }
                styles={selectStylesDefault}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <b>Observaciones:</b>
              </Form.Label>
              <Form.Control 
                as="textarea"
                rows="5"
                aria-label="Observaciones"
                placeholder="Aquí puedes agregar algunas observaciones si lo deseas"
                onChange={(e) => setDataTransfer({
                  ...dataTransfer,
                  observation: e.target.value
                })}
              />
              <Form.Text>
                En caso de tener observaciones, captúralas aquí de manera clara y breve
              </Form.Text>
            </Form.Group>
          </> : ''
        }
      </Form>
    </div>
  );
};

export const footerDocumentsView = (setCloseModal) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()}>Cerrar</Button>
    </>
  );
};

export const getAllDocumentsOfExpedient = async (
  expedientNumber, setDocumentsView, setShow
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_all_documents/${expedientNumber}`,
    "get",
    null,
    {}
  );
  const responseDocumentsExpedient = await request.executeRequest();
  if (
    responseDocumentsExpedient && responseDocumentsExpedient.code === 200 &&
    responseDocumentsExpedient.response && responseDocumentsExpedient.response.documents &&
    responseDocumentsExpedient.response.documents.length > 0
  ) {
    setDocumentsView(responseDocumentsExpedient.response.documents);
    setShow(true);
  } else if (
    responseDocumentsExpedient && responseDocumentsExpedient.code === 404
  ) {
    NotificationManager.info(responseDocumentsExpedient.response.message, 'Atención:', 5000);
  } else {
    NotificationManager.error('Ha ocurrido un error y no fue posible traer los documentos del expediente...');
  }
};

export const footerTransfer = (handleClose, handleSubmit) => {
  
  return (
    <div>
      <Button 
        variant="secondary" 
        className="mR-10" 
        onClick={() => handleClose()}
      >
        Cerrar
      </Button>
      <Button 
        variant="primary" 
        onClick={() => handleSubmit()}
      >
        Transferir
      </Button>
    </div>
  );
};

export const getDependencesToTransfer = async (setDependences) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/dependences`,
    "get",
    null,
    {}
  );
  const responseDependences = await request.executeRequest();
  if (
    responseDependences && responseDependences.code === 200 &&
    responseDependences.response && responseDependences.response.dependences &&
    responseDependences.response.dependences.length > 0
  ) setDependences(responseDependences.response.dependences.map((dep) => ({
    label: dep.name,
    value: dep.id,
  }))); 
};

export const handleTransferExpedient = async (
  data,
  props,
  setErrors
) => {
  setErrors({});
  if (data['courtCatalogOption']) {
    data['court_catalog_id'] = data['courtCatalogOption']['value'];
  }
  const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/release`,
      "post",
      null,
      {
        ...data,
        force: false
      }
    );
  const responseTransfer = await request.executeRequest();
  if (
    responseTransfer && responseTransfer.code === 200 &&
    responseTransfer.response && responseTransfer.response.governmentBook &&
    typeof responseTransfer.response.governmentBook === 'object'
  ) {
    props.history.push('/pending_transfers');
    NotificationManager.success(
      'El expediente ha sido tranferido correctamente',
      '',      
      6000
    );
  } else {
    if (
      responseTransfer && responseTransfer.code === 422 &&
      typeof responseTransfer.response.message === 'object'
    ) {
      setErrors(responseTransfer.response.message);
      NotificationManager.error(
        'Existen errores que impiden continuar, revisa los datos que has ingresado', 
        '', 
        5000
      );
    } else {
      NotificationManager.error(
        'Existen errores que impiden continuar, revisa los datos que has ingresado', 
        '', 
        5000
      );
    }
  }
};

const renderDropdownItems = (optionsStatuses, handleSelectStatus, status, subChild = false) => {
  return (
    <div>
      { optionsStatuses.map((option, i) => (

        <div key={option.id}>
          { option.children && option.children.length > 0 ? 
            <div style={subChild ? { marginLeft:'20px' } : null}>
              <Dropdown.Header>{option.name}</Dropdown.Header>
              { renderDropdownItems(option.children, handleSelectStatus, status, true) }
            </div> :
            <div key={option.id}>
              <Dropdown.Item 
                href="#"
                onClick={() => handleSelectStatus({
                  selectedName: optionsStatuses[i]['fullName'],
                  selectedId: optionsStatuses[i]['id']
                })}
                style={status.id === option.id && subChild ? 
                  { backgroundColor:'#1d78ff', color:'white', marginLeft:'20px' } : 
                  status.id !== option.id && subChild ?
                  { marginLeft:'20px' } : null
                }
              >
                {option.name}
              </Dropdown.Item>
              <Dropdown.Divider/>
            </div> }
        </div> 
      )) }
    </div>
  );
};

export const bodyChangeStatus = (
  optionsStatuses, 
  handleSelectStatus, 
  status,
  currentStatus,
  errors
) => {
  const changeable = currentStatus === '' || (currentStatus && currentStatus[currentStatus.length - 1]['changeable']) ?
    true : false;

    return (
    <Form>
      <Form.Group>
        <b>
          En está sección podrá realizar el cambio de estatus del expediente electrónico
        </b>
        <hr/>
      </Form.Group>

      <Form.Group>
        <div style={{ display:'flex', justifyContent:'space-around' }}>
          <b>
            Estatus actual:
          </b>
          <div
            style={{ 
              backgroundColor:'#17a2b8', 
              width: '300px',
              padding:'10px 0', 
              color: 'white',
              textAlign:'center',
              borderRadius:'5px'
            }}
          >
            <span>
              { status.name }
            </span>
          </div>
        </div>
        <hr/>
      </Form.Group>

      { changeable ? 
        <Form.Group>
          <b style={errors['id'] ? { color: 'red' } : null}>
            Cambiar el estatus:
          </b>
          
          <InputGroup>
            <FormControl
              placeholder="Da clic para desplegar las opciones"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onClick={() => {
                const dropdownOptions = document.getElementById('input-group-dropdown-2');
                dropdownOptions.click();
              }}
              onChange={(e) => e.preventDefault()}
              value={status.name}
              className={errors['id'] ? 'is-invalid' : ''}
            />
  
            <DropdownButton
              as={InputGroup.Append}
              variant="outline-secondary"
              title="Opciones"
              id="input-group-dropdown-2"
              className="dropdown-change-status"
            >
              { renderDropdownItems(optionsStatuses, handleSelectStatus, status) }
            </DropdownButton>
          </InputGroup>
          {renderErrorsByInputName(errors, 'id')}
        </Form.Group> :
        <Form.Group>
          <h5 className='text-center'>
            <b style={{ color: 'red' }}>¡Ya no se puede cambiar el estatus de este expediente!</b>
          </h5>
        </Form.Group> }

    </Form>
  );
};

export const footerChangeStatus = (handleClose, handleSubmit, currentStatus) => {
  const changeable = currentStatus === '' || (currentStatus && currentStatus[currentStatus.length - 1]['changeable']) ?
    true : false;

  return (
    <>
      <Button 
        variant='secondary' 
        className='mR-10' 
        onClick={() => handleClose(false)}
      >
        Cerrar
      </Button>
      { changeable ? 
        <Button 
          variant='primary' 
          onClick={() => handleSubmit()}
        >
          Cambiar
        </Button> : '' }
    </>
  );
};

const formattedStatuses = (statuses, parentName = '') => {
  statuses.forEach((status, i) => {
    if (parentName) status.fullName = parentName + ' - ' + status.name
    else status.fullName = status.name;
    if (status.children && status.children.length > 0) {
      formattedStatuses(status.children, status.fullName);
    }
  });

  return statuses;
};

export const getStatusesOptions = async (setOptionsStatuses) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/expedient_statuses/get_all`,
    "get",
    null,
    {}
  );
  const responseAllStatuses = await request.executeRequest();
  if (
    responseAllStatuses && responseAllStatuses.code === 200 &&
    responseAllStatuses.response && responseAllStatuses.response.statuses &&
    responseAllStatuses.response.statuses.length > 0
  ) {
    const statusesFormatted = formattedStatuses(responseAllStatuses.response.statuses);
    setOptionsStatuses(statusesFormatted);
  } else {
    NotificationManager.warning('No se encontraron opciones de estatus para el expediente');
    setOptionsStatuses([]);
  }
};

export const requestSaveNewStatus = async (data, handleCloseModal, reloadComponent, setErrors) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/change_status/${data.expedientNumber}/${data.id}`,
    "post",
    null,
    {}
  );
  const responseChangeStatus = await request.executeRequest();
  if (
    responseChangeStatus && responseChangeStatus.code === 200 &&
    responseChangeStatus.response && responseChangeStatus.response.message
  ) {
    handleCloseModal(false);
    await reloadComponent(responseChangeStatus.response.message, 'Excelente:', 5000);
  } else {
    if (
      responseChangeStatus && responseChangeStatus.code === 422      
    ) {
      NotificationManager.error(responseChangeStatus.response.message);
      setErrors({
        id: [responseChangeStatus.response.message]
      });
    } else {
      NotificationManager.error(
        'Ha ocurrido un error desconocido al intentar cambiar el estatus de este expediente'
      );
    }
  }
};
