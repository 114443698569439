import React, { useState, useContext } from "react";
import { Form, Button, Modal, Spinner, ButtonToolbar } from "react-bootstrap";
import { updateAlertReadStatus } from "./alert_notification_logic";
import NotificationAlertListContext from "./notification_alert_context";

const CheckboxReadAlert = props => {
  const [checked, setCheck] = useState(false);
  const [handleShow, setHandleShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertContext = useContext(NotificationAlertListContext);

  const onHandleClose = () => {
    setCheck(!checked);
    setHandleShow(false);
  };

  const submitHandler = async () => {
    setLoading(true);
    await updateAlertReadStatus(props, checked, alertContext);
    setLoading(false);
    onHandleClose();
  };

  const onChangeHandler = () => {
    setCheck(!checked);
    setHandleShow(true);
  };

  return (
    <div>
      <>
        <Modal show={handleShow}>
          <Modal.Header
            closeButton
            style={{ background: "#f0ad4e", color: "white" }}
          >
            <Modal.Title>Marcar como leido</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Estas seguro que deseas actualizar el registro ?
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <Button
                variant="primary"
                onClick={submitHandler}
                style={{ marginRight: "5px" }}
              >
                {loading ? "Cargando..." : "Aceptar"}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={
                    loading ? { display: "inline-block" } : { display: "none" }
                  }
                />
              </Button>
              <Button variant="secondary" onClick={onHandleClose}>
                Cerrar
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </>
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default CheckboxReadAlert;
