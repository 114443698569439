import Cookies from "js-cookie";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
import { getPushNotificationsByReceiver } from "../alertNotificationTable/alert_notification_logic";

export const initForm = {
  email: "",
  password: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
//###REFACTORIZADA
export const SignUp = async (form, props, notificationsContext) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_in`,
    "post",
    null,
    {
      email: form.email,
      password: form.password,
      app_id: 3
    }
  );
  const isLogged = await request.executeRequest();

  if (isLogged.code === 200) {
    if ( isLogged.response.error ) {
      NotificationManager.error( isLogged.response.message );
    }
    else {
      if (checkSpecialPermissions(isLogged.completeResponse)) {
        InitCookies(isLogged.completeResponse, props);
        const dataNotifications = await getPushNotificationsByReceiver(1);
        notificationsContext.updateNotifications('update', dataNotifications.notifications, null, null);
        notificationsContext.updateCount(!notificationsContext.requireUpdate, 'sumAlert', dataNotifications.total);
      }
    }
  }
  else {
    NotificationManager.error( isLogged.response.message );
  }
};
/***********************************************************************************************************************************************/

export function DestroyCookies() {
  Cookies.remove("token_03", { path: "/" }); // removed!
  Cookies.remove("uid_03", { path: "/" });
  Cookies.remove("client_03", { path: "/" });
  Cookies.remove("userId_03", { path: "/" }); // removed!
  Cookies.remove("names_03", { path: "/" }); // removed!
  Cookies.remove("paternal_surname_03", { path: "/" });
  Cookies.remove("regime_03", { path: "/" }); // removed!
  Cookies.remove("auth_03", { path: "/" }); // removed!
  Cookies.remove("groups_03", { path: "/" });
  Cookies.remove("special_permissions_03", { path: "/" });
  Cookies.remove("avatar_03", { path: "/" });
  Cookies.remove("authentication_token_03", { path: "/" });
  Cookies.remove("app_config_02", { path: "/" });
  localStorage.removeItem("elementsAdded");
}

export const InitCookies = (response, props) => {
  try {
    //HEADERS
    Cookies.set("token_03", response.headers["access-token"]);
    Cookies.set("uid_03", response.headers["uid"]);
    Cookies.set("client_03", response.headers["client"]);
    //USER
    Cookies.set("userId_03", response.data.data.user.id);
    Cookies.set("avatar_03", response.data.data.user.avatar);
    Cookies.set("names_03", response.data.data.user.names);
    Cookies.set(
      "paternal_surname_03",
      response.data.data.user.paternal_surname
    );
    Cookies.set("regime_03", response.data.data.user.regime);
    Cookies.set("groups_03", response.data.data.user.groups);
    Cookies.set(
      "special_permissions_03",
      response.data.data.user.special_permissions
    );
    Cookies.set("auth_03", true);
    // AUTH TOKEN
    Cookies.set( "authentication_token_03", response.data.data.jwt );
    if (response.data.data.user.position) Cookies.set(
      "user_position_03", 
      response.data.data.user.position
    );    
  } catch (error) {
    //TALIBOLLO MOSTRARIA EL ERROR
    console.log(error);
  }
};

const checkSpecialPermissions = (loginResponse) => {
  const special_permissions = loginResponse.data.data.user.special_permissions;
  if (
    special_permissions && special_permissions[0] && 
    (special_permissions[0]['procedure_section'] === '1' || 
    special_permissions[0]['expedient_section'] === '1')
  ) {

    return true;
  } else {

    NotificationManager.error(
      'No tienes acceso o permisos suficientes para ingresar a la plataforma, consulta con tu administrador',
      'Lo lamentamos:',
      8000
    );
    return false;
  }
};