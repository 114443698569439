import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { RemotePagination } from "../helper_components/remote_pagination/RemotePagination";
import { getProcessPausedData, initColumnsProcessPaused } from "./table_process_logic";
import CountProcessContext from "../layout/shared/count_process_context";

const TableProcessPaused = props => {
  const [columns] = useState(initColumnsProcessPaused);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const CountProcess = useContext(CountProcessContext);
  const [showModal, setShowModal] = useState(false);
  const [elementsModal, setElementsModal] = useState({});

  useEffect(() => {
    (async function() {
      await getProcessPausedData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        CountProcess,
        setShowModal,
        setElementsModal
      );
    })();
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await getProcessPausedData(
      props,
      setData,
      setTotalProcedures,
      sizePerPage,
      page,
      CountProcess,
      setShowModal,
      setElementsModal
    );
  };

  return (
    <>
      <Modal show={showModal} size="lg">
        {elementsModal}
      </Modal>


      <h4 className="mB-20 mt-0 w-100 text-center">Trámites Pausados</h4>

      <RemotePagination
        data={data}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalProcedures}
        onTableChange={handleTableChange}
        columns={columns}
      />
    </>
  );
};
export default TableProcessPaused;
