import React from "react";

//EN ESTE CONTEXTO DECLARO UNA FUNCION QUE HACE QUE SE HAGA UPDATE DEL COUNT DE ALERTAS Y UNA BANDERA QUE DICE SI NECESITA O NO ACTUALIZAR
const NotificationAlertListContext = React.createContext({
  count: 0,
  updateCount: () => {},
  requireUpdate: false,
  notifications: [],
  updateNotifications: () => {}
});

export default NotificationAlertListContext;
