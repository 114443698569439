import React from "react";
import { Col, Row } from "react-flexbox-grid";
import TableProcessNoAssign from "../table_process/TableProcessNoAssign";
import TableProcessAsign from "../table_process/TableProcessAsign";
import TableProcessPendingReview from "../table_process/TableProcessPendingReview";
import NotFound from "../404/404";
import TableProcessMyProcess from "./TableProcessMyProcess";
import OneColumn from "../layout/containers/OneColumn";
import TableProcessPaused from "../table_process/TableProcessPaused";

const TableProcesses = props => {
  const status = props.match.params.status;

  const setPageConfig = status => {
    if (status === "no_assign")
      return {
        active: "formalities_unassigned",
        title: "Trámites sin asignar"
      };
    else if (status === "assign")
      return { active: "formalities_attend", title: "Trámites atendidos" };
    else if (status === "pending")
      return { active: "formalities_to_attend", title: "Trámites por atender" };
    else if (status === "my_procedures")
      return { active: "my_procedures", title: "Mis trámites" };
    else if (status === "paused")
    return { active: "formalities_paused", title: "Trámites pausados" };
  };

  const filterProcessByStatus = () => {
    if (status === "no_assign") return <TableProcessNoAssign {...props} />;
    else if (status === "assign") return <TableProcessAsign {...props} />;
    else if (status === "pending")
      return <TableProcessPendingReview {...props} />;
    else if (status === "my_procedures")
      return <TableProcessMyProcess {...props} />;
    else if (status === "paused" )
    return <TableProcessPaused {...props} />;
    else return <NotFound />;
  };

  return (
    <OneColumn {...props} pageConfig={setPageConfig(status)} >
      <section className="procedures-table-container mT-30">
        <Row>
          <Col xs={12} sm={12}>
            {filterProcessByStatus()}
          </Col>
        </Row>
      </section>
    </OneColumn>
  );
};

export default TableProcesses;