import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Card, Container, Form } from "react-bootstrap";
import NavBar from "../../layout/shared/NavBar";
import Select from "react-select";
import { 
	getOptionsKindJudgment, 
	getOptionsLegalWays,
	getOptionsCourtCatalogs,
	selectStylesDefault,
	fieldsValidation,
	selectStylesError,
	renderErrorsByInputName
} from "../my_expedients/expedients_list_logic";
import { saveExpedientEdited, getDataExpedientToEdit, formatAfterExpedientEdit } from "./modify_expedients_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";

const customStyles = {
	...selectStylesDefault,
	menu: (provided, state) => ({
		...provided,
		width: '99%',
		margin: '0 0  0 2px'
	})
};

const EditExpedient = (props) => {
	const initValues = {
		expedient_number: '',
		actor_name: '',
		actor_firstname: '',
		actor_secondname: '',
		actor_email: '',
		lawyer_actor_name:'',
		lawyer_actor_firstname:'',
		lawyer_actor_secondname:'',
		defendant_name: '',
		defendant_firstname: '',
		defendant_secondname: '',
		defendant_email: '',
		lawyer_defendant_name:'',
		lawyer_defendant_firstname:'',
		lawyer_defendant_secondname:'',
		kind_judgment: null,
		via: null,
		court_catalog_id: null,
		justification: ''	
	};
	const expedientNumber = props.match.params.expedientNumber.replace(/-/g, '/');
	const [kindJudgmentOptions, setKindJudgmentOptions] = useState([]);
	const [legalWaysOptions, setLegalWaysOptions] = useState([]);
	const [courtCatalogs ,setCourtCatalogs] = useState([]);
	const [expedientToEdit, setExpedientToEdit] = useState(initValues);
	const [errors, setErrors] = useState({});
	const loader = useContext(LoaderContext);

	useEffect( () => {
		(async function() {
			const optionsKJ = await getOptionsKindJudgment();
			const optionsLW = await getOptionsLegalWays();
			setKindJudgmentOptions(optionsKJ);
			setLegalWaysOptions(optionsLW);
			const expedient = await getDataExpedientToEdit(
				expedientNumber,
				optionsLW,
				optionsKJ
			);
			const courtCatalogOptions = await getOptionsCourtCatalogs(expedient['court_id']);
			await formatAfterExpedientEdit(courtCatalogOptions, expedient);
			setCourtCatalogs(courtCatalogOptions);
			setExpedientToEdit(expedient ? expedient : initValues);
		}())
	}, []);

	const handleSubmit = async () => {
		const requiredFields = ['expedient_number', 'actor_name', 'actor_firstname', 'kindJudgmentOption', 'reason'];
		const responseValidation = fieldsValidation(requiredFields, expedientToEdit);

		if (typeof responseValidation === 'object') {
			setErrors(responseValidation);
		}
		else {
			loader.show(true);
			expedientToEdit['court_catalog_id'] = expedientToEdit['court_catalog_id'] ? expedientToEdit['courtCatalogOption']['value'] : null;
			expedientToEdit['kind_judgment'] = expedientToEdit['kind_judgment'] ? expedientToEdit['kindJudgmentOption']['value'] : null;
			
			await saveExpedientEdited(
				props,
				expedientToEdit,
				setErrors
			);
			loader.show(false);
		}
	};

	const handleChange = (name, value) => {
		setExpedientToEdit({
			...expedientToEdit,
			[name]:value
		});
		delete errors[name]
	};


	return (
		<>
			<NavBar {...props} />

			<Row className="jc-c mT-25">             
				<Card
					className="cardExpedientEdit"
				>
					<Card.Header
						className="cardHeaderExpedients"
					>
						<h4 className="text-center">{"Edición del expediente " + expedientNumber } <i className="ti-pencil-alt" style={{fontSize:'15px'}} /></h4>
					</Card.Header>
					<Card.Body>
						<Container>
							<div className="table-responsive">
								<Form>
									<Form.Group>
										<Form.Label>* Id del trámite:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el Id del trámite"
											required
											name="expedient_number"
											value={expedientToEdit['expedient_number']}
											onChange={(e) => {
												if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
													handleChange(
														'expedient_number',
														e.target.value
													)
												}
											}}
											className={Object.keys(errors).includes("expedient_number") ? "text-danger is-invalid" : ""}
											onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
										/>
										{ renderErrorsByInputName(errors, "expedient_number") }				
									</Form.Group>

									<Form.Group>

										<Form.Label>Número de expediente del juzgado:</Form.Label>
										<Form.Control 
											value={ expedientToEdit["court_expedient"] ? expedientToEdit["court_expedient"] : "" } 
											aria-label="numero de expediente"
											placeholder="Agrega el número de expediente del juzgado"
											className={Object.keys(errors).includes("court_expedient") ? "text-danger is-invalid" : ""}
											onChange={ (e) => {
											if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
												handleChange('court_expedient', e.target.value);
											}
											else setErrors({...errors, court_expedient:['Sólo son permitidos caracteres alfanuméricos y/o diagonal']});
											}}
											onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
										/>
										<Form.Text className={errors["court_expedient"] ? "d-n" : ""}>
											Ejemplo: 25/2019 (recuerda utilizar diagonal y capturar los cuatro dígitos del año)
										</Form.Text>
										{ renderErrorsByInputName(errors, "court_expedient") }
									</Form.Group>

									<Form.Group>

										<Form.Label>El solicitante es ¿demandante o demandado?:</Form.Label>
										<Form.Control 
											value={ expedientToEdit["demand"] ? expedientToEdit["demand"] : "" } 
											aria-label="numero de expediente"
											placeholder="Agrega que rol tendrá el solicitante, ¿demandado o demandante?"
											className={Object.keys(errors).includes("demand") ? "text-danger is-invalid" : ""}
											onChange={ (e) => handleChange("demand", e.target.value)}
										/>
										{ renderErrorsByInputName(errors, "demand") }
									</Form.Group>

									<Form.Group>
										<Form.Label>Materia:</Form.Label>
										<Form.Control 
											disabled={ true } 
											value={ expedientToEdit["court"] ? expedientToEdit["court"] : "" } 
											aria-label="numero de expediente"
											placeholder="Agrega la materia"
											className={Object.keys(errors).includes("court") ? "text-danger is-invalid" : ""}
											onChange={ (e) => handleChange("court", e.target.value)}
										/>
										{ renderErrorsByInputName(errors, "court") }
									</Form.Group>

									<Form.Group>
										<Form.Label>Juzgado:</Form.Label>
										<Select
											value={expedientToEdit['courtCatalogOption']}
											placeholder="Selecciona el juzgado"
											styles={Object.keys(errors).includes('courtCatalogOption') ? selectStylesError : customStyles}
											menuPlacement="bottom"
											maxMenuHeight={150}
											onChange={selected => handleChange(
												'courtCatalogOption',
												selected
											)}
											options={courtCatalogs}
										/>
										{ renderErrorsByInputName(errors, "courtCatalogOption") }
									</Form.Group>	

									<Form.Group>
										<Form.Label>* Nombre(s) del actor:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el nombre(s) del actor"
											required
											name="actor_name"
											value={expedientToEdit['actor_name']}
											onChange={(e) => handleChange(
												'actor_name',
												e.target.value
											)}
											className={Object.keys(errors).includes("actor_name") ? "text-danger is-invalid" : ""}
										/>
										{ renderErrorsByInputName(errors, "actor_name") }				
									</Form.Group>

									<Form.Group>
										<Form.Label>* Primer apellido del actor:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el primer apellido del actor"
											required
											name="actor_firstname"
											value={expedientToEdit['actor_firstname']}
											onChange={(e) => handleChange(
												'actor_firstname',
												e.target.value
											)}
											className={Object.keys(errors).includes("actor_firstname") ? "text-danger is-invalid" : ""}
										/>
										{ renderErrorsByInputName(errors, "actor_firstname") }				
									</Form.Group>

									<Form.Group>
										<Form.Label>Segundo apellido del actor:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el segundo apellido del actor"
											required
											name="actor_secondname"
											value={expedientToEdit['actor_secondname']}
											onChange={(e) => handleChange(
												'actor_secondname',
												e.target.value
											)}
											className={Object.keys(errors).includes("actor_secondname") ? "text-danger is-invalid" : ""}
										/>
										{ renderErrorsByInputName(errors, "actor_secondname") }				
									</Form.Group>			

									<Form.Group>
										<Form.Label>Nombre(s) del demandado:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el nombre(s) del demandado"
											required
											name="defendant_name"
											defaultValue={expedientToEdit['defendant_name'] || ""}
											onChange={(e) => handleChange(
												'defendant_name',
												e.target.value
											)}
										/>
										{ renderErrorsByInputName(errors, "defendant_name") }			
									</Form.Group>

									<Form.Group>
										<Form.Label>Primer apellido del demandado:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el primer apellido del demandado"
											required
											name="defendant_firstname"
											defaultValue={expedientToEdit['defendant_firstname'] || ""}
											onChange={(e) => handleChange(
												'defendant_firstname',
												e.target.value
											)}
										/>
										{ renderErrorsByInputName(errors, "defendant_firstname") }			
									</Form.Group>

									<Form.Group>
										<Form.Label>Segundo apellido del demandado:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Edita el segundo apellido del demandado"
											required
											name="defendant_secondname"
											defaultValue={expedientToEdit['defendant_secondname'] || ""}
											onChange={(e) => handleChange(
												'defendant_secondname',
												e.target.value
											)}
										/>
										{ renderErrorsByInputName(errors, "defendant_secondname") }			
									</Form.Group>

									<Form.Group>
										<Form.Label>* Tipo de Juicio:</Form.Label>
										<Select
											value={expedientToEdit['kindJudgmentOption']}
											placeholder="Selecciona el tipo de juicio"
											styles={Object.keys(errors).includes('kindJudgmentOption') ? selectStylesError : customStyles}
											menuPlacement="bottom"
											maxMenuHeight={150}
											onChange={selected => handleChange(
												'kindJudgmentOption',
												selected
											)}
											options={kindJudgmentOptions}
										/>
										{ renderErrorsByInputName(errors, "kindJudgmentOption") }				
									</Form.Group>

									<Form.Group>
										<Form.Label>* Justificación:</Form.Label>
										<Form.Control
											type="text"
											as="textarea" 
											placeholder="Agrega una justificación"
											defaultValue={expedientToEdit['reason']}
											onChange={(e) => handleChange(
												'reason',
												e.target.value
											)}
											className={Object.keys(errors).includes("reason") ? "text-danger is-invalid" : ""}
										/>
										{ renderErrorsByInputName(errors, "reason") }					
									</Form.Group>
								</Form>
							</div> 
						</Container>
					</Card.Body>
				</Card> 
			</Row>
			<Row className="jc-c mB-20">
				<div className="actions-expedient-edit">
					<div className="fl-r">
						<Button
							variant="secondary"
							className="mR-20"
							onClick={() => props.history.goBack()}
						>
								Cancelar
						</Button>
						<Button
							variant="primary"
							onClick={() => handleSubmit()}
						>
							Actualizar
						</Button>
					</div>
				</div>
			</Row>

			<Loader/>
		</>
	);
};

export default EditExpedient;