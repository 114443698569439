import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
import { Col } from "react-flexbox-grid";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";

const TinyEditor = props => {
  const [text, setText] = useState(props.state[props.el.name]);
  const isSignatureField = props.el.extra_attributes.signature;
  const signatureModal = props.el.extra_attributes.signature ? (
    <Col className="p-5" xs={12}>
      <DigitalSignatureModal {...props} htmlIntoBase64={text} />
    </Col>
  ) : (
    ""
  );

  useEffect(() => {
    (async function() {
      if (props.state[props.el.name]) {
        setText(props.state[props.el.name]);
      } else {
        setText(props.el.extra_attributes.document_information.data);
      }
    })();
  }, []);

  const handleEditorChange = e => {
    setText(e.target.getContent());
    props.onChangeHandler(e.target.getContent(), props.el.name, props.el.type);
  };

  const downloadDocument = async () => {
    const { authentication_token_03 } = Cookies.get();
    const { el, stage } = props;
    const { extra_attributes, id, name } = el;
    const url = `${process.env.REACT_APP_URL_API_LARAVEL}/documents/get_pdf_for_detail/${stage}/${extra_attributes['document_id']}`;
    let data = new FormData();
    data.append('field_id', id);
    data.append('content_file', text);

    await fetch(url, { 
      method:'POST',
      body: data,
      headers: {
        Authorization: authentication_token_03
      } 
    }).then(response => response.blob().then(blob => {
      if (blob && blob['type'] && blob['type'] === 'application/pdf') {
        let a = document.createElement('a');
        let url = URL.createObjectURL(blob);
        a.href = url;
        a.download = name + '.pdf';
        a.click(); 
        setTimeout(() => URL.revokeObjectURL(url), 500);
      } else NotificationManager.error(
        'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
        '¡Que mal!',
        5000
      );
    })
    .catch(err => NotificationManager.error(
      'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
      '¡Que mal!',
      5000
    )));
  };

  const isDisable = b => {
    if (b === 2 || b === 3){
      return true;
    }
    return false;
  }
  return (
    <Col xs={12} className="p-10" style={{ minHeight: "1250px" }}>
      {signatureModal}
      { isSignatureField ? "" :
        <Button 
          variant="success"
          onClick={() => downloadDocument()}
          className="mB-10"
        >
          Descargar documento
        </Button> }

      {text &&
        <Editor
          disabled={isDisable(props.mode)}
          apiKey="bugg7r7e8dy612zqv84qvfm9qtb8lm7r1utvvtlsdvtnd9ih"
          initialValue={text}
          value={text}
          init={{
            readonly: isDisable(props.mode) ? 1 : 0,
            plugins: "link image code",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code",
            language: "es_MX",
            language_url: "/langs/es_MX.js",
          }}
          onChange={handleEditorChange}
        />
      } 

    </Col>
  );
};

export default TinyEditor;
