import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from 'react-notifications';

export const initForm = {
  oldPassword: "",
  newPassword: "",
  passwordConfirm: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const changePassword = async (form, props) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/change/password/${userId_03}`,
    "patch",
    null,
    {
      new_password: form.newPassword,
      password_confirmation: form.passwordConfirm,
      current_password: form.oldPassword
    }
  );
  const validateChangePassword = await request.executeRequest();

  if (validateChangePassword.code === 200 && !validateChangePassword.response.error) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTimeout( () => { NotificationManager.success('La informacion se actualizó correctamente.'); }, 500);
    DestroyCookies();
    window.location.href = '/';
  }
  else if (
    validateChangePassword.response.error && 
    typeof validateChangePassword.response.message === 'string' ) {
    
    NotificationManager.error( validateChangePassword.response.message, 'Corrige los siguientes errores: ');  
  }
  else {

    NotificationManager.error('Algo sucedió y no fue posible actualizar tu contraseña');  
  }
};
/***********************************************************************************************************************************************/
