import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

export const getExpedientsToModify = async (
	page,
	sizePerPage,
	setData,
	setTotalProcedures,
	props,
	setTextNotData
) => {
    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/admin/electronic_expedients/by_court/${sizePerPage}?page=${page}`,
        "get",
        null,
        {}
      );
		const responseListExpedients = await request.executeRequest();
		if (responseListExpedients.code !== 200) {
			const messageError = responseListExpedients && responseListExpedients.response && responseListExpedients.response.message ? responseListExpedients.response.message : 'Error no definido en el mensaje de respuesta';
			NotificationManager.error(
				messageError,
				'Ha ocurrido un error al intentar traer el listado de expedientes:',
				3500
			);
		}
		else {
			if (Array.isArray(responseListExpedients.response.electronic_expedients)) {
				const expedientsFormatted = formattedData(
					props,
					responseListExpedients.response.electronic_expedients
				);
				if (expedientsFormatted.length === 0) setTextNotData('No se han encontrado expedientes');
				setData(expedientsFormatted);
				setTotalProcedures(responseListExpedients.response.total);
			}
		}
};



export const formattedData = (
	props,
	expedients
) => {
	return expedients.map( (expedient, index) => {
		expedient['actor'] = expedient['actor_name'] + (expedient['actor_firstname'] ? 
			' ' + expedient['actor_firstname'] : '') + (expedient['actor_secondname'] ? 
			' ' + expedient['actor_secondname'] : '');
		expedient['defendant'] = (expedient['defendant_name'] || 'N/A') + (expedient['defendant_firstname'] ? 
			' ' + expedient['defendant_firstname'] : '') + (expedient['defendant_secondname'] ? 
			' ' + expedient['defendant_secondname'] : '');
		expedient['expedient_number_link'] = (
			<>
				<Link 
					to={{
						pathname: `/expedient_details/${expedient['expedient_number'].replace(/\//g,'-')}`, 
						state: { 
						  labelPath: 'Modificar expedientes',
						  prevPath: window.location.pathname 
						}
					  }}
				>
						{expedient['expedient_number']}
				</Link>
			</>
		);
		expedient['actions'] = (
			<Row key={1} center="xs" around="xs">
				<Col key={1} xs={12} className="procedures-actions">
					<div 
						className="edit" 
						onClick={() => props.history.push(`/edit_expedient/${expedient['expedient_number'].replace(/\//g,'-')}`)}
					>
						<i className="ti-pencil-alt" title="Modificar expediente" />
					</div>
				</Col>
			</Row>
		);
		expedient['time_indicator'] = (<span className={"dot-" + expedient.warning_flag}/>);

		return expedient;
	});
};


export const getColumnsModifyExpedients = () => {
  
	return [
		{
			dataField: "id",
			text: "Id",
			sort: true,
			style: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		},
		{
			dataField: "expedient_number_link",
			text: "Id del trámite",
			sort: true,
			style: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		},
		{
			dataField: "actor",
			text: "Actor",
			sort: true,
			style: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		},
		{
			dataField: "time_indicator",
			text: "Indicador",
			sort: true,
			style: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		},
		{
			dataField: "defendant",
			text: "Demandado",
			sort: true,
			style: { textAlign: "center" },
			headerStyle:  { textAlign: "center" }
		},
		{
			dataField: "actions",
			text: "Acciones",
			sort: true,
			style: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		}
	];
};


export const searchExpedientsToModify = async (
	page,
	sizePerPage,
	setData,
	setTotalProcedures,
	props,
	search,
	setTextNotData
) => {
	search = String(search).replace(/\//g,'-').toUpperCase();
	const request = new RequestNewVersion(
		`${process.env.REACT_APP_URL_API_LARAVEL}/admin/electronic_expedients/by_court/search/${search}/${sizePerPage}?page=${page}`,
		"get",
		null,
		{}
	);
	const responseSearchExpedients = await request.executeRequest();

	if (responseSearchExpedients.code !== 200) {
		const messageError = responseSearchExpedients && responseSearchExpedients.response && responseSearchExpedients.response.message ? responseSearchExpedients.response.message : 'Error no definido en el mensaje de respuesta';
		NotificationManager.error(
			messageError,
			'Ha ocurrido un error al intentar traer el listado de expedientes:',
			3500
		);
	}
	else {
		if (Array.isArray(responseSearchExpedients.response.electronic_expedients)) {
			const expedientsFormatted = formattedData(
				props,
				responseSearchExpedients.response.electronic_expedients
			);
			if (expedientsFormatted.length === 0) setTextNotData('No se han encontrado expedientes');
			setData(expedientsFormatted);
			setTotalProcedures(responseSearchExpedients.response.total);
		}
	}	
};


export const saveExpedientEdited = async (
	props,
	expedientEdited,
	setErrors
) => {
	const request = new RequestNewVersion(
		`${process.env.REACT_APP_URL_API_LARAVEL}/admin/electronic_expedients/${expedientEdited['id']}`,
		"put",
		null,
		{...expedientEdited}
	);
	const responseSavedExpedient = await request.executeRequest();
	if (
		responseSavedExpedient.code === 200 &&
		responseSavedExpedient.response && 
		responseSavedExpedient.response.expedient &&
		typeof responseSavedExpedient.response.expedient === 'object'
	) {
		NotificationManager.success(
			responseSavedExpedient.response.message,
			'Actualización exitosa:',
			3000
		);
		setTimeout(() => {
			props.history.push('/modify_expedients');
		}, 400);
	}
	else {
		if (
			responseSavedExpedient &&
			responseSavedExpedient.code === 422 &&
			responseSavedExpedient.response &&
			responseSavedExpedient.response.error && 
			responseSavedExpedient.response.message &&
			typeof responseSavedExpedient.response.message === 'object'
		) {
			setErrors(responseSavedExpedient.response.message);
			NotificationManager.error(
				'Por favor revisa los campos marcados en rojo',
				'Existen errores:',
				4500
			);
		} else {
			NotificationManager.error(
				'Revisa tus datos y vuelve a intentarlo...',
				'Ha ocurrido un error al actualizar el expediente:',
				4500
			);
		}
	}
};

export const getDataExpedientToEdit = async (
	expedientNumber,
	legalWaysOptions,
	kindJudgmentTypesOptions
) => {

	const request = new RequestNewVersion(
		`${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/find/expedient_number/${expedientNumber.replace(/\//g,'-')}`,
		"get",
		null,
		{}
	);
	const getExpedientData = await request.executeRequest();
	if (
		getExpedientData.code === 200 && 
		getExpedientData.response && 
		getExpedientData.response.electronicExpedient &&
		typeof getExpedientData.response.electronicExpedient === 'object'
	) {

		return await formatExpedientEdit(
			legalWaysOptions, 
			kindJudgmentTypesOptions, 
			getExpedientData.response.electronicExpedient
		);
	}
	else {
		const errorMessage = getExpedientData && getExpedientData.response && getExpedientData.response.message || 'Mensaje de error no definido...'
		NotificationManager.error(
			errorMessage,
			'Ha ocurrido un error a la hora de intentar traer el expediente',
			3500
		);
	}
};


const formatExpedientEdit = async (viaOptions, typeOptions, expedient) => {

	viaOptions.forEach( (element, index) => {
		if (element['value'] === expedient['via']) {
			expedient['viaOption'] = element;
		}
	});

	typeOptions.forEach( (element, index) => {
		if (element['value'] === expedient['kind_judgment']) {
			expedient['kindJudgmentOption'] = element;
		}
	});

	expedient['actor_email'] = expedient['actor_email'] || '';
	expedient['defendant_email'] = expedient['defendant_email'] || '';

	return expedient;

};

export const formatAfterExpedientEdit = async (courtCatalogOptions, expedient) => {
	courtCatalogOptions.forEach( (element, index) => {
		if (element['value'] === expedient['court_catalog_id']) {
			expedient['courtCatalogOption'] = element;
		}
	});

	return expedient;

};
