/***********************************IMPORT LIBRARIES*************************************************** */
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import Cookies from "js-cookie";
import {NotificationContainer} from "react-notifications";
import Echo from "laravel-echo";
import { printSocketNotification, getPushNotificationsByReceiver } from "./components/alertNotificationTable/alert_notification_logic";

/*************************************ROUTE COMPONENTS****************************************** */
import Login from "./components/login/Login";
import NotFound from "./components/404/404";
import Home from "./components/home/Home";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import RecoveryPassword from "./components/forgot_password/RecoveryPassword";
import EditProfile from "./components/edit_profile/EditProfile";
import ChangePassword from "./components/change_password/ChangePassword";
import AlertNotificationTable from "./components/alertNotificationTable/AlertNotificationTable";
import AvatarConfig from "./components/avatar_config/AvatarConfig";
import ProcessList from "./components/processes_list/ProcessList";
import TableProcesses from "./components/table_process/TableProcesses";
import StartProcess from "./components/start_process/StartProcess";
import ErrorPage from "./components/helper_components/ErrorPage";
import StageDetail from "./components/static_form/StaticForm";
import LoaderContext from "./components/layout/shared/loader_context";
import ErrorCatcher from "./components/500/500";
import CountProcessContext from "./components/layout/shared/count_process_context";
import UserManual from "./components/user_manual/UserManual";
import NotificationAlertListContext from "./components/alertNotificationTable/notification_alert_context";
import ExpedientsList from "./components/government_book/my_expedients/ExpedientsList";
import PendingTransfers from "./components/government_book/pending_transfers/PendingTransfers";
import TurnForeignList from "./components/government_book/turn_foreign/TurnForeign";
import TableReceivedHistory from './components/government_book/received_history/TableReceivedHistory';
import ExpedientDetail from "./components/government_book/expedient_detail/ExpedientDetail";
import TablePendingExpedientsToReceive from './components/government_book/pending_expedients_to_receive/TablePendingExpedientsToReceive';
import ExpedientDetails from "./components/government_book/details_expedient/ExpedientDetails";
import ModifyExpedients from "./components/government_book/modify_expedients/ModifyExpedients";
import EditExpedient from "./components/government_book/modify_expedients/EditExpedient";

const App = props => {
  const { auth_03, authentication_token_03, userId_03, special_permissions_03 } = Cookies.get();
  const [countAlerts, setCountAlerts] = useState(0);
  const [needUpdateCount, setNeedUpdateCount] = useState(false);
  const [statusLoader, setLoaderStatus] = useState(false);
  const [countProcess, setCountProcess] = useState({});
  const [dispatch, setDispatch] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const special_permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
  const canTurnForeign = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['foreign'] === '1';
  const canModifyExpedients = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['modify_expedient'] === '1';
  const canGoToExpedients = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['expedient_section'] === '1';
  const canGoToProcedures = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['procedure_section'] === '1';
  const [newNotification, setNewNotification] = useState(null);

  const updateCountHandler = (state, sumOrSubtractAlert, newCountOfAlerts) => {
    //THIS FUNCTION ALLOWS CHANGE THE STATE WHEN THE CHECKBOX MARK AS READ AND RENDER THE COMPONENT FOR THE COUNTER ALERT UPDATE
    if(sumOrSubtractAlert === "substractAlert"){
      setCountAlerts(countAlerts - 1);
    }
    else if( sumOrSubtractAlert === "sumAlert" ){
      setCountAlerts(newCountOfAlerts);
    } 
    setNeedUpdateCount(state);
  };

  const updateNotifications = (action, listNotifications, notificationRemove, notificationAdd) => {
    if (action === 'remove') {
      const filterNotifications = notifications.filter(notif => notif.id !== notificationRemove);
      setNotifications(filterNotifications);
    }
    if (action === 'update') {
      setNotifications(listNotifications);
    }
    if (action === 'add') {
      let currentNotifications = notifications;
      currentNotifications.unshift(notificationAdd);
      setNotifications(currentNotifications);
    }
  };

  useEffect(() => {
    if (dispatch) {
      socketComunication();
      setDispatch(false);
    }
  }, []);

  useEffect(() => {
    (async function () {
      if (userId_03 && authentication_token_03) {
        const responseNotifications = await getPushNotificationsByReceiver(1);
        setNotifications(responseNotifications.notifications);
        setCountAlerts(responseNotifications.total);
      }
    })();
  }, []);

  useEffect(() => {
    if (newNotification) {
      renderAndLogicNotification(newNotification, countAlerts);
    }
  }, [newNotification]);
//'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjozLCJlbWFpbCI6Imdlcm1hbkBjamouY29tIiwidXNlcm5hbWUiOiJHZXJtYW5DamoiLCJuYW1lcyI6IkdFUk1BTiIsInBhdGVybmFsX3N1cm5hbWUiOiJDSkoiLCJtYXRlcm5hbF9zdXJuYW1lIjpudWxsLCJhY2NvdW50X2lkIjoxLCJhcHBfaWQiOjMsInJlZ2ltZSI6MSwiY3VycCI6bnVsbCwicm9sZXMiOlt7ImlkIjo0LCJuYW1lIjoib3BlcmF0b3IifV0sIm1vZHVsZXMiOltdLCJncm91cHMiOlsiRXNwZWNpYWwiXSwiYXZhdGFyIjpudWxsLCJzcGVjaWFsX3Blcm1pc3Npb25zIjpbeyJzdGFydCI6IjEiLCJyZWFsbG9jYXRlIjoiMSIsImNvcHkiOiIxIiwicGF1c2UiOiIxIiwiZm9yZWlnbiI6IjEiLCJtb2RpZnlfZXhwZWRpZW50IjoiMSIsImNyZWF0ZV9leHBlZGllbnQiOiIxIiwibm90aWZpY2F0aW9uc190ZW1wbGF0ZSI6IjEiLCJhZ3JlZW1lbnRfdGVtcGxhdGUiOiIxIiwic2lnbiI6IjEifV0sImNvdXJ0Ijp7ImlkIjoxLCJuYW1lIjoiSnV6Z2FkbyAxIiwianVkaWNpYWxfcGFydHlfaWQiOjEsImFjdGl2ZSI6dHJ1ZSwiZG9jdW1lbnRhbF9pZCI6MTc2LCJjcmVhdGVkX2F0IjoiMjAyMS0wMS0wOCAxMTowOTozMyIsInVwZGF0ZWRfYXQiOiIyMDIxLTAxLTA4IDExOjA5OjMzIiwibXVuaWNpcGFsaXR5X2lkIjo0MCwic3RyZWV0IjoiU2FuIE1lbGNob3IiLCJleHRlcm5hbF9udW1iZXIiOiIxMjc3IiwiY29sb255IjoiU2FudGEgTWFyaWEiLCJ6aXBfY29kZSI6IjQ0MzUwIiwic3RhdGUiOiJKYWxpc2NvIn19LCJ1aWQiOlsiZ2VybWFuQGNqai5jb20iXSwiYWNjZXNzLXRva2VuIjpbIndvQkk2Sk1oZXVxb0c4Z2w0V0NiR1EiXSwiY2xpZW50IjpbImJRbXhlZk5sSkprZW1Xc2NqMS1ZeGciXSwiaWF0IjoxNjEwNjU2NDExLCJleHAiOjM2MTA2NTY0MTF9.mJguoYyQfckmGf7fyPCfywZHEB37wllldFF22UnPD0A'
  const socketComunication = () => {
    if (userId_03 && authentication_token_03) {
    var echoInstance = new Echo({
      broadcaster: 'socket.io',
      host: process.env.REACT_APP_SERVER_WEB_SOCKET,
      transports: ['websocket'],
      auth: {
        headers: {
          'Authorization': authentication_token_03
        }
      }
    });

    echoInstance.private(`expedients.${userId_03}`)
      .listen('ExpedientReceived', (notification) => {
        setNewNotification(notification);
      })
      .listen('ExpedientPendingToReceive', (notification) => {
        setNewNotification(notification);
      })
      .listen('ExpedientReleaseCancelled', (notification) => {
        setNewNotification(notification);
      });
    }
  };

  const renderAndLogicNotification = (dataNotif, countAlertsHere) => {
    printSocketNotification(
      'Notificación: (' + dataNotif.created_at + ')',
      dataNotif.message,
      5000
    );
    updateNotifications('add', null, null, dataNotif);
    setCountAlerts(countAlertsHere + 1);
  };

  return (
    <ErrorCatcher>
    <BrowserRouter>
      <NotificationAlertListContext.Provider 
        value={{ 
          count: countAlerts, 
          updateCount: updateCountHandler, 
          requireUpdate: needUpdateCount,
          notifications: notifications,
          updateNotifications: updateNotifications
        }}>
        <LoaderContext.Provider value={{ status: statusLoader, show: status => { setLoaderStatus(status); }}}>
        <NotificationContainer />
          <LastLocationProvider>
          <CountProcessContext.Provider value={{ countProcess: countProcess, setCountProcess: countProcess => { setCountProcess(countProcess); } }}>
            <Switch>
            <Route 
              exact 
              path="/" 
              render={() =>
                auth_03 && canGoToProcedures ?
                (<Redirect to="/home" />) : 
                auth_03 && canGoToExpedients ? 
                (<Redirect to="/my_expedients" />) : 
                (<Login {...props} />)
              } 
            />
            <Route path="/home" render={ () => auth_03 ? (<Home {...props} />) : <Redirect to="/" />} />
            <Route path="/forgot_password" component={ForgotPassword} />
            <Route path="/recover_password:reset_password_token?" component={RecoveryPassword} />
            <Route path="/recover_password:reset_password_token?" component={RecoveryPassword} />

            <Route
              path="/edit_profile"
              render={props =>
                auth_03 ? (
                  <EditProfile {...props} name="Editar perfil" path="/edit_profile"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/process_list"
              render={props =>
                auth_03 ? (
                  <ProcessList {...props} name="Lista de procesos" path="/process_list"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/table_process/:status"
              render={props =>
                auth_03 ? (
                  <TableProcesses {...props} name="Tabla de procesos" path="/table_process" />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/alert_notifications"
              render={props =>
                auth_03 ? (
                  <AlertNotificationTable {...props} name="Buzón de notificaciones" path="/alert_notifications"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/error_page"
              render={props =>
                auth_03 ? <ErrorPage {...props} /> : <Redirect to="/" />
              }
            />
            <Route
              path="/start_process"
              render={props =>
                auth_03 ? <StartProcess {...props} /> : <Redirect to="/" />
              }
            />
            <Route
              path="/change_password"
              render={props =>
                auth_03 ? (
                  <ChangePassword {...props} name="Cambio de contraseña" path="/change_password"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/stage_detail:task?"
              render={props =>
                auth_03 ? (
                  <StageDetail {...props} name="Seguimiento de etapa" path="/stage_detail"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/avatar_config"
              render={props =>
                auth_03 ? (
                  <AvatarConfig history={props.history} name="Subir imagen de avatar" path="/avatar_config"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/user_manual"
              render={props =>
                auth_03 ? (
                  <UserManual history={props.history} name="Manual de usuario" path="/user_manual"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/my_expedients"
              render={props => {
                if (auth_03 && canGoToExpedients) {
                  return <ExpedientsList history={props.history} name="Mis expedientes" path="/my_expedients"/>;
                } else {
                  return <Redirect to="/" />;
                }
              }}
            />
            <Route
              path="/pending_transfers"
              render={props =>
                auth_03 ? (
                  <PendingTransfers {...props} name="Expedientes enviados" path="/pending_transfers"/>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            <Route
              path="/expedient_detail"
              render={props =>
                auth_03 ? (
                  <ExpedientDetail {...props} name="Buscar expediente" path="/expedient_detail"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/received_history"
              render={props =>
                auth_03 ? (
                  <TableReceivedHistory history={props.history} name="Expedientes recibidos" path="/received_history"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/pending_expedients_to_receive"
              render={props =>
                auth_03 ? (
                  <TablePendingExpedientsToReceive history={props.history} name="Expedientes por recibir" path="/pending_expedients_to_receive"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/expedient_details/:expedientNumber"
              render={props =>
                auth_03 ? (
                  <ExpedientDetails {...props} name="Detalles de expediente" path="/expedient_details"/>
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/turn_foreign"
              render={props =>
                auth_03 && canTurnForeign ? (
                  <TurnForeignList {...props} name="Turnado externo de expedientes" path="/turn_foreign"/>
                ) : (
                  <Redirect to="/my_expedients" />
                )
              }
            />
            <Route
              path="/modify_expedients"
              render={props =>
                auth_03 && canModifyExpedients ? (
                  <ModifyExpedients {...props} name="Modificar expedientes" path="/modify_expedients"/>
                ) : (
                  <Redirect to="/my_expedients" />
                )
              }
            />
            <Route
              path="/edit_expedient/:expedientNumber"
              render={props =>
                auth_03 && canModifyExpedients ? (
                  <EditExpedient {...props} name="Editar expedientes" path="/edit_expedient"/>
                ) : (
                  <Redirect to="/my_expedients" />
                )
              }
            />
            <Route component={NotFound} />
            </Switch>
          </CountProcessContext.Provider>
          </LastLocationProvider>
        </LoaderContext.Provider>
      </NotificationAlertListContext.Provider>
    </BrowserRouter>
    </ErrorCatcher>
  );
};

export default App;