import React, { useState, useEffect, useContext } from "react";
import { Badge, Form, Button, Row, Col, Modal, ListGroup } from "react-bootstrap";
import ModalComponent from "../helper_components/ModalComponent";
import { Editor } from "@tinymce/tinymce-react";
import { selectStylesDefault, fieldsValidation } from "./my_expedients/expedients_list_logic";
import Cookies from "js-cookie";
import { 
  getTemplatesPresaved, getUserTemplateById, getAgreementTypes, 
  requestDeleteTemplate, getPromotionsWithoutAgreement, 
  requestSendUpdateTemplate, bodyDeleteTemplate, getCommentsByTemplate,
  requestCommentAndRelease
} from "./details_expedient/expedient_details_logic";
import { renderErrorsList, renderClassesToErrors } from "../edit_profile/edit_profile_logic";
import LoaderContext from "../layout/shared/loader_context";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { getBlobOfFilePDF } from "../helper_components/react_pdf/pdf_logic";

const TemplatesPresaved = ({ 
  expedientNumber, reloadData, setReloadData, reloadParentComponent, props 
}) => {
  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const { userId_03 } = Cookies.get();
  const canUseDemandTemplate = specialPermissions[0]['demands_template'] === '1';
  const canUsePromotionsTemplate = specialPermissions[0]['promotions_template'] === '1';
  const [showModalTiny, setShowModalTiny] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [templateHere, setTemplateHere] = useState({});
  const [templatesAgreement, setTemplatesAgreement] = useState([]);
  const [templatesNotification, setTemplatesNotification] = useState([]);
  const [errors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const [templateIdDelete, setTemplateIdDelete] = useState('');
  const [templatesType, setTemplatesType] = useState('');
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const [comments, setComments] = useState([]);
  const [openComments, setOpenComments] = useState(false);

  useEffect(() => {
    (async function(){
      loader.show(true);
      await getTemplatesPresaved(
        expedientNumber,
        setTemplatesAgreement,
        setTemplatesNotification,
        setTemplatesType
      );
      setTemplatesType('Promotions')
      await getAgreementTypes(
        setAgreementTypes
      );
      await getPromotionsWithoutAgreement(
        expedientNumber,
        setPromotions
      );
      loader.show(false);
    })();

    const detectClickOutside = (e) => {
      if (e.target.id !== 'delete-template-button') setTemplateIdDelete('');
    };

    window.addEventListener('click', detectClickOutside);
    return () => window.removeEventListener('click', detectClickOutside);
  }, [reloadData]);

  const handleEditorChange = (value) => {
    delete errors['body'];
    setTemplateHere({
      ...templateHere,
      body:value.target.getContent()
    });
  };

  const sendCommentAndRelease = async (templateId) => {
    const data = {
      expedient_number: expedientNumber.replace(/-/g, '/'),
      comment: templateHere['comments']
    };
    const responseValidation = fieldsValidation(
      ['expedient_number', 'comment'], 
      data
    );
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
    } else {
      loader.show(true);
      await requestCommentAndRelease(
        templateId,
        data,
        props
      );
      loader.show(false);
    }
  };

  const tinyEditor = (
    <>
      <div style={{margin:'0 auto', width:'80%'}}>
        <Form>
          {templateHere['last_comment'] ? 
          <>
            <Button 
              className='fl-r btn-add-comments-template' 
              variant='link'
              onClick={() => handleOpenComments()}
            >
              Ver historial de comentarios
            </Button>
            <Form.Group>
              <Form.Label>
                Último comentario: 
              </Form.Label>
              <Form.Control
                as="textarea"
                aria-label="Comentarios"
                defaultValue={templateHere['last_comment']}
                readOnly
                disabled
              />
            </Form.Group>
            <hr/>
          </> : ''}

          <Form.Group >
            <Form.Label>Agregar comentario</Form.Label>
            <Form.Check
              type="checkbox"
              label={"Si"}
              checked={templateHere['add_comments'] ? true : false}
              id={"yes"}
              value={templateHere['add_comments'] ? 'false' : 'true'}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTemplateHere({...templateHere, add_comments: true})
                } else {
                  setTemplateHere({...templateHere, add_comments: false, comments:''})
                }
              }}
            />
          </Form.Group>

          {templateHere['add_comments'] ? 
          <>
            <hr/>
            <Form.Group>
              <Form.Label>Comentarios:</Form.Label>
              <Form.Control
                onChange={(e) => setTemplateHere({
                  ...templateHere,
                  comments:e.target.value
                })}
                as="textarea"
                rows="5"
                aria-label="Comentarios"
                maxLength={255}
                defaultValue={templateHere['comments']}
                className={renderClassesToErrors(errors, errors['comment'] ? 'comment' : 'comments')}
              />
              {renderErrorsList(errors, errors['comment'] ? 'comment' : 'comments')}
              {templateHere && String(templateHere['user_id']) === userId_03 ? '' :
              <Button 
                onClick={() => sendCommentAndRelease(templateHere['id'])} 
                variant='danger' 
                className='fl-r mT-2 mB-10'
              >
               Agregar comentario
              </Button>}
            </Form.Group>
            <br/>
          </> : ''}
          <hr/>

          <Form.Group>
            <Form.Label>* Documento</Form.Label>
            {renderErrorsList(errors, errors['html'] ? 'html' : 'body')}
          </Form.Group>
        </Form>
      </div>

      <div className={"container-preview-templates" + (errors['body'] || errors['html'] ? '-error' : '')} style={{marginTop:'-10px'}}>
        <Editor
          apiKey="bugg7r7e8dy612zqv84qvfm9qtb8lm7r1utvvtlsdvtnd9ih"
          initialValue={templateHere['body']}
          value={templateHere['body']}
          init={{
            plugins: "link image code",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code",
            language: "es_MX",
            language_url: "/langs/es_MX.js"
          }}
          onChange={(value) => {
            delete errors['body'];
            delete errors['html'];
            handleEditorChange(value);
          }}
        />
      </div>
    </>
  );

  const setDataTemplateModal = async (template) => {
    loader.show(true);
    await getUserTemplateById(
      template['id'],
      setTemplateHere,
      setShowModalTiny
    );
    loader.show(false);
  };

  const handleRequestSave = async () => {
    const requiredFields = templateHere['add_comments'] ?
      ['id', 'alias', 'filename', 'type', 'content', 'body', 'expedient', 'comments'] : 
      ['id', 'alias', 'filename', 'type', 'content', 'body', 'expedient'];
    let templateToSend = {...templateHere};
    templateToSend['content'] = templateToSend['body'];
    templateToSend['templateId'] = templateToSend['id'];
    templateToSend['userId'] = Cookies.get('userId_03');
    const responseValidation = fieldsValidation(requiredFields, templateToSend);
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
    } else {
      templateToSend = _.omit(
        templateToSend, 
        'alias', 'answer_promotion',
        'expedient', 'filename',
        'promotions', 'type',
        'id', 'body');
      loader.show(true);
      await requestSendUpdateTemplate(
        templateToSend, setShowModalTiny, 
        setReloadData, reloadData
      );
      loader.show(false);
    }
  };

  const showMenuDeleteTemplate = (e, template) => {
    e.preventDefault();
    setTemplateIdDelete(template['id']);
  };

  const handleRequestDelete = async () => {
    loader.show(true);
    await requestDeleteTemplate(
      templateIdDelete, setOpenDeleteTemplate,
      setReloadData, reloadData
    );
    loader.show(false);
  };

  const handleOpenComments = async () => {
    setShowModalTiny(false);
    await getCommentsByTemplate(
      templateHere['id'], 
      setComments,
      setOpenComments
    );
  };

  const handleCloseComments = () => {
    setOpenComments(false);
    setShowModalTiny(true);
  };

  const handleRequestPrintTemplate = async () => {
    loader.show(true);
    const { authentication_token_03 } = Cookies.get();
    let formData = new FormData();
    formData.append('user_id', userId_03);
    formData.append('template_id', templateHere['id']);
    formData.append('content', templateHere['body']);
    await fetch(`${process.env.REACT_APP_URL_API_LARAVEL}/update_download_content_expedient_template`, { 
      method:'POST',
      body: formData,
      headers: {
        Authorization: authentication_token_03
      } 
    }).then(response => response.blob().then(blob => {
      if (blob && blob['type'] && blob['type'] === 'application/pdf') {
        let url = URL.createObjectURL(blob);
        var wnd = window.open(url);
        if (wnd) wnd.print()
        else {
          NotificationManager.warning(
            'Por favor habilita el permiso de abrir ventanas emergentes en este sitio para poder imprimir el documento',
            '¡Necesitamos tu permiso!',
            8000
          );
        }
        setTimeout(() => URL.revokeObjectURL(url), 500);
      } else {
        NotificationManager.error(
          'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
          '¡Que mal!',
          5000
        );
      }
    })).catch(err => {
      NotificationManager.error(
        'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
        '¡Que mal!',
        5000
      );;
    });
    loader.show(false);
  };

  const renderTemplatesByType = () => {
    if (templatesType === 'Promotions' && templatesNotification.length > 0) return templatesNotification.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => setDataTemplateModal(tem, true)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
    else if (templatesType === 'Demands' && templatesAgreement.length > 0) return templatesAgreement.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => setDataTemplateModal(tem)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
  };

  return (
    <>
      { 
        <>
          <ListGroup horizontal>
            <ListGroup.Item 
              style={{ width:'50%', cursor: 'pointer' }}
              onClick={() => setTemplatesType('Promotions')}
              active={templatesType === 'Promotions'}
            >
              Proyectos de promoción guardados:
            </ListGroup.Item>
            <ListGroup.Item 
              style={{ width:'50%', cursor: 'pointer' }}
              onClick={() => setTemplatesType('Demands')}
              active={templatesType === 'Demands'}
            >
              Proyectos de demandas guardados:
            </ListGroup.Item>
          </ListGroup>
          <div className={templatesType !== '' ? 'render-templates-by-type' : 'd-n'}>
            {renderTemplatesByType()}
          </div>
        </>
      }

      <ModalComponent
        header={`Plantilla guardada de tipo ${templateHere['type'] === 'demand' ? 'demanda:' : 'promoción:'}`}
        body={tinyEditor}
        footer={(    
          <>
            <Button 
              variant="secondary" 
              onClick={() => setShowModalTiny(false)}
            >
              Cerrar</Button>
            <Button 
              variant="success" 
              onClick={() => handleRequestSave()}
            >
              <i className="fas fa-save" /> {'  '}
              Guardar</Button>
            <Button 
              variant="primary" 
              onClick={() => handleRequestPrintTemplate()}
            >
              <i className="fas fa-print" /> {'  '}
              Imprimir</Button>
          </>
        )}
        show={showModalTiny}
        canClose={true}
        onHide={setShowModalTiny}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header='¿Estás seguro de eliminar este elemento?'
        body={bodyDeleteTemplate}
        footer={(    
          <>
            <Button 
              variant="secondary" 
              onClick={() => setOpenDeleteTemplate(false)}
            >
              Cancelar
            </Button>
            <Button 
              variant="primary" 
              onClick={() => handleRequestDelete()}
              id='delete-template-button'
            >
              Eliminar
            </Button>
          </>
        )}
        show={openDeleteTemplate}
        canClose={true}
        onHide={setOpenDeleteTemplate}
      />

      <Modal 
        show={openComments} 
        onHide={() => handleCloseComments()} 
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Historial de comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table 
            className="table table-collapsed" 
            style={{ 
              border: '1px solid #a2a2a2',
              marginTop: '10px'
            }}>
            <tbody>
              <tr>
                <th className="text-center button-see-evidence-documents">Creado por:</th>
                <th className="text-center button-see-evidence-documents">Comentario:</th>
                <th className="text-center button-see-evidence-documents">Fecha:</th>
              </tr>
              {	
                comments.map( ( comment, index ) => (
                  <tr key={index}>
                    <td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-center">
                      <small>{comment['user']}</small>
                    </td>
                    <td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-justify">
                      {comment['comment']}
                    </td>
                    <td style={{paddingTop:"15px"}} className="text-center">
                      <small>{comment['createdAt']}</small>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseComments()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplatesPresaved;