import React from "react";

/**
 * Context loader
 * @type {React.Context<{show: LoaderContext.show, status: boolean}>}
 */
const LoaderContext = React.createContext({
    status: false,
    show: () => {}
});

export default LoaderContext;
