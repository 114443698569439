import Cookies from "js-cookie";
import { Request } from "../classes/Request";

/**********************************************************FORGOT PASSWORD************************************************************************/

export const executeStage = async (
  form,
  partial = false,
  sequence,
  props,
  setFormTitle,
  setSequence,
  setStage,
  setPreviousUrl,
  setFlagError,
  setForm,
  setLastStep,
  setMode,
  setApplicant
) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/execute_stage`,
    "post",
    null,
    {
      token: process.env.REACT_APP_TOKEN,
      stage: props.history.location.state.params.stage
        ? props.history.location.state.params.stage
        : "",
      user_id: userId_03,
      partial: partial,
      fields: form,
      sequence: sequence
    }
  );
  const executeStageData = await request.executeRequest();
  if (executeStageData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setFormTitle(executeStageData.data.data.data.form_name);
    setSequence(executeStageData.data.data.data.sequence);
    setStage(executeStageData.data.data.data.stage);
    setPreviousUrl(executeStageData.data.data.data.previous_url);
    setFlagError(false);

    const executeStageJson = {
      form_name: executeStageData.data.data.data.form_name,
      next_url: executeStageData.data.data.data.next_url,
      previous_url: executeStageData.data.data.data.previous_url,
      sequence: executeStageData.data.data.data.sequence,
      stage_id: executeStageData.data.data.data.stage,
      form: executeStageData.data.data.data.form
    };
    await processStage(
      executeStageJson,
      setLastStep,
      setFormTitle,
      setForm,
      setPreviousUrl,
      setMode,
      setApplicant
    );
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setFlagError(true);
    setForm(executeStageData.form);
  }
};

/******************************************************************PROCESS STAGE*******************************************************************/

export const processStage = async (
  form,
  setLastStep,
  setFormTitle,
  setForm,
  setPreviousUrl,
  setMode,
  setApplicant
) => {
  let processStageData = {};
  if (form.next_url) {
    const request = new Request(form.next_url, "get", null, {
      token: process.env.REACT_APP_TOKEN
    });
    processStageData = await request.executeRequest();
  } else {
    setLastStep(true);
    setFormTitle("Etapa Finalizada");
    setForm(form.form);
  }
  if (processStageData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setPreviousUrl(processStageData.data.data.data.previous_url);
    setMode(processStageData.data.data.data.meta.mode);
    setApplicant(processStageData.data.data.data.meta.applicant_id);
    setForm(processStageData.data.data.data.form);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
  }
};

/**************************************************************************************************************************************************/
/******************************************************************PREVIOUS HANDLER*******************************************************************/

export const previousHandler = async (
  url,
  setFormTitle,
  setPreviousUrl,
  setForm,
  setSequence,
  setMode
) => {
  const request = new Request(url, "get", null, {
    token: process.env.REACT_APP_TOKEN
  });
  const previousHandlerData = await request.executeRequest();

  if (previousHandlerData.code === 200) {
    setFormTitle(previousHandlerData.data.data.data.meta.form_name);
    setPreviousUrl(previousHandlerData.data.data.data.previous_url);
    setForm(previousHandlerData.data.data.data.form);
    setSequence(previousHandlerData.data.data.data.meta.current_step);
    setMode(previousHandlerData.data.data.data.meta.mode);
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    console.log(previousHandlerData);
  }
};

/**************************************************************************************************************************************************/
/***********************************************************************************************************************************************/
