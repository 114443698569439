
import React from 'react';
import Cookies from 'js-cookie';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import { NotificationManager } from 'react-notifications';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export const initColumnsTablePendingExpedientsToReceive = ( show ) => {

  return [
    {
      dataField: "index",
      text: "#",
      sort: false,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "expedient_number_link",
      text: "Id del trámite",
      sort: false,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "comments",
      text: "Comentarios",
      sort: false,
      style: show ? { textAlign: "center", maxWidth:"200px" } : { display: "none" },
      headerStyle: show ? { textAlign: "center" } : { display:"none" }
    },
    {
      dataField: "sender",
      text: "Emisor",
      sort: false,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "group",
      text: "Puesto",
      sort: false,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    }
  ];
}; 


export const getPendingExpedientsToReceive = async (
  setPendingExpedients,
  setTotalExpedients,
  page,
  sizePerPage,
  handleShowModal,
  setTextNotData
) => {

  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/pending/${userId_03}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const response = await request.executeRequest();

  if (response.code !== 200) {
    NotificationManager.error("Algo sucedió y no fue posible traer los expedientes, inténtalo nuevamente");
    setTextNotData("No hay resultados por mostrar");
  } else {
    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      setTextNotData(response.response.message);
      NotificationManager.error(response.response.message);
    }
    else if ( response.response.governmentBooks && response.response.governmentBooks.length === 0 ) {
      setTotalExpedients(0);
      setPendingExpedients([]);
      setTextNotData("No se han encontrado resultados");
    }
    else {
      const expedients = await formattedExpedients(
        response.response.governmentBooks,
        handleShowModal
      );
      setTotalExpedients(response.response.total);
      setPendingExpedients(expedients);
    }
  }
};

const formattedExpedients = (expedients, handleShowModal) => {
  let registers = [];

  expedients.forEach((governmentBook, index) => {
    let expedient = governmentBook.governmentBook;
    const { userId_03 } = Cookies.get();
    let sender_id = expedient.sender.id;
    const expedient_number_link = String(expedient.expedient_number).replace(/\//g,'-');

    expedient["actions"] = (
      <Row key={expedient['id']} center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          { expedient.sender.id !== userId_03 ? (
            <div id={expedient["id"] + "button-receive" } className="btn btn-primary" onClick={(e) => { handleShowModal(expedient) }}>
              Recibir
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
    expedient['expedient_number_link'] = (
      <Link 
        to={{
          pathname: `/expedient_details/${expedient_number_link}`, 
          state: { 
            labelPath: 'Expedientes por recibir',
            prevPath: window.location.pathname 
          }
        }}
      >
        {expedient.expedient_number}
      </Link>
    );
    expedient['comments'] = (
      <div id={expedient.id + 'div-comments-pending' } />
    );

    expedient['index'] = expedient.id;
    expedient['group'] = expedient.sender.group.toUpperCase();
    expedient['sender'] = expedient.sender.name;

    if (Number(sender_id) !== Number(userId_03)) {
      registers.push(expedient);
    }
  });
  return registers;
};

export const receiveExpedient = async (expedient,setShowComments) => {
  const expedient_number = String(expedient.expedient_number).replace(/\//g,'-');
  const pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];

  return new Promise(async (resolve, reject) => {
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/receive/${expedient_number}`,
      "post",
      null,
      {
        sign: true,
        observation: expedient.observation || null
      }
    );

    const response = await request.executeRequest();

    if (response.code !== 200) {
      NotificationManager.error("Algo sucedió y no fue posible traer los expedientes, inténtalo nuevamente");
      return reject("Algo sucedió y no fue posible traer los expedientes, inténtalo nuevamente");
    }

    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      NotificationManager.error(response.response.message);
      return reject(response.response.message);
    }

    const pendingFilter = pendingsAddedStorage.filter( element => element['expedient_number'] !== expedient['expedient_number'] );
    removeInputComments([expedient]);
    setShowComments(pendingFilter.length > 0 ? true : false);
    localStorage.setItem('pendingsAdded', JSON.stringify( pendingFilter ));

    NotificationManager.success(response.response.message);
    return resolve(response.response.message);
  });
}


export const getResponseOfSearch = async (
  search,
  sizePerPage,
  page,
  setData,
  setTotalProcedures,
  handleShowModal,
  setTextNotData
) => {

  const { userId_03 } = Cookies.get();
  search = String(search).replace(/\//g,'-');

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/pending/search/${userId_03}/${search}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseOfSearch = await request.executeRequest();

  if ( responseOfSearch.code === 200 ) {

    if ( responseOfSearch.response && responseOfSearch.response.governmentBooks && responseOfSearch.response.governmentBooks.length > 0 ) {

      const responseOfSearchFormatted = await formattedExpedients(
        responseOfSearch.response.governmentBooks,
        handleShowModal
      );
      setTotalProcedures(responseOfSearch.response.total);
      setData(responseOfSearchFormatted);
    }
    else {
      setTextNotData("No se han encontrado resultados");
      setTotalProcedures(0);
      setData([]);
    }
  }
  else if ( 
    responseOfSearch.response.error && 
    typeof responseOfSearch.response.message === "string" && 
    responseOfSearch.response.message !== "" ) {

      setTextNotData(responseOfSearch.response.message);
      setTotalProcedures(0);
      setData([]);
  }
};



export const getPendingsSelected = () => {

  let pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];
  const pendingsReduce = pendingsAddedStorage.reduce( (array,element) => {

    array.push(element['id']);

    return array;
  },[]);

  return pendingsReduce;
};


export const renderInputOfComments = ( elements, commentsOnChange ) => {

  for ( let i = 0; i < elements.length; i++ ) {

    let divContainer = document.getElementById(elements[i]['id'] + 'div-comments-pending') ? document.getElementById(elements[i]['id'] + 'div-comments-pending') : null;

    if ( divContainer && divContainer.childNodes.length < 1 ) {

      var textarea = document.createElement('textarea');
      textarea.id = elements[i]['id'] + 'input-comments-pending';
      textarea.rows = 4;
      textarea.cols = 20;
      textarea.value = elements[i]['comments']
      textarea.style.width = '100%';

      textarea.addEventListener(
        'input',
        (e) => commentsOnChange(e, elements[i]['id']),
        false
     );

      divContainer.appendChild(textarea);
    }
  }

};


export const removeInputComments = (
  elementsToRemoveComments
) => {
  
  for ( let i = 0; i < elementsToRemoveComments.length; i++ ) {

    let inputComments = document.getElementById( elementsToRemoveComments[i]['id'] + 'input-comments-pending' );
    let smallErrors = document.getElementById( elementsToRemoveComments[i]['id'] + 'errors-small' );
    if ( inputComments ) inputComments.remove();
    if ( smallErrors ) smallErrors.remove();
  };
};


export const sendMultiReceive = async (
  arrayExpedientsToReceive,
  setData,
  setTotalProcedures,
  page,
  sizePerPage,
  handleShowModal,
  setTextNotData,
  setShowComments,
  props
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/multi_receive`,
    "post",
    null,
    {
      expedients:arrayExpedientsToReceive
    }
  );

  const responseMultiReceive = await request.executeRequest();
  
  if ( 
    responseMultiReceive.code === 200 && 
    responseMultiReceive.response.expedients && 
    responseMultiReceive.response.expedients.length > 0 && 
    typeof responseMultiReceive.response.message === "string" &&
    responseMultiReceive.response.message !== "" ) {

    await getPendingExpedientsToReceive(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      handleShowModal,
      setTextNotData
    );

    localStorage.removeItem("pendingsAdded");
    setShowComments(false);
    NotificationManager.success(responseMultiReceive.response.message);
    props.history.push("/my_expedients")
  }
  else if (
    responseMultiReceive.code === 200 && 
    responseMultiReceive.response.expedients && 
    responseMultiReceive.response.expedients.length === 0 ) {

    NotificationManager.error("Algo salió mal durante el recibimiento masivo, inténtalo nuevamente");
  } 
  else {
    if ( responseMultiReceive.code === 404 ) {

      const keysResponse = Object.keys(responseMultiReceive.response).filter( key => !isNaN(key) ).map( (keyFilter) => {

        arrayExpedientsToReceive[keyFilter]['errors'] = responseMultiReceive.response[keyFilter]['message'];
        return arrayExpedientsToReceive[keyFilter];
      });

      renderErrorsByElement(keysResponse);
      NotificationManager.error("Algo salió mal durante el recibimiento masivo, revisa las instrucciones marcadas en rojo");
    } else {

      NotificationManager.error("Algo salió mal durante el recibimiento masivo, inténtalo nuevamente");
    }
  }
};


export const renderErrorsByElement = (
  elementsWithErrors
) => {

  for( let i = 0; i < elementsWithErrors.length; i++ ) {
    let divContainer = document.getElementById(elementsWithErrors[i]['id'] + 'div-comments-pending') ? document.getElementById(elementsWithErrors[i]['id'] + 'div-comments-pending') : null;

    if ( divContainer && divContainer.childNodes.length < 2 ) {

      var small = document.createElement('small');
      small.id = elementsWithErrors[i]['id'] + 'errors-small';
      small.textContent = elementsWithErrors[i]['errors']
      small.style.color = 'red';
      small.style.width = '50px';

      divContainer.appendChild(small);
    }
  }
};


export const disabledButtonsToReceiveTransfer = (
  elementsToDisabledButton,
  disabled
) => {
  if ( disabled ) {
    for ( let i = 0; i < elementsToDisabledButton.length; i++ ) {
      let buttonToTransfer = document.getElementById( elementsToDisabledButton[i]['id'] + 'button-receive' );
      if ( buttonToTransfer ) {
        buttonToTransfer.style.pointerEvents = 'none';
        buttonToTransfer.style.backgroundColor = '#ebe7e7';
      }
    }
  }
  else {
    for ( let i = 0; i < elementsToDisabledButton.length; i++ ) {
      let buttonToTransfer = document.getElementById( elementsToDisabledButton[i]['id'] + 'button-receive' );
      if ( buttonToTransfer ) {
        buttonToTransfer.style.pointerEvents = '';
        buttonToTransfer.style.backgroundColor = '#c1c1c1'
      }
    }
  }
};