import { Request } from "../classes/Request";

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getStageDetail = async (getParams, setFormData) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/stage/detail/${
      getParams.task
    }?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getStageDetailData = await request.executeRequest();
  if (getStageDetailData.code === 200) {
    setFormData(getStageDetailData.data.data.data.stage.forms);
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
  }
};
/***********************************************************************************************************************************************/
