import React from "react";
import { Button, FormControl } from "react-bootstrap";
import "./styles.css";

const Searcher = (props) => {
    const {
            placeholderSearch,
            placeholderSearchButton,
            placeholderCleanButton,
            helperContextual,
            executeSearch,
            cleanSearch,
            isSearching,
            isCleaning,
            setSearch,
            search,
            classContainer,
            classHelperContextual,
            classButtonsSearch
    } = props;

    const onEnterKey = (e) => {

            if ( e.key === "Enter" && search.length > 0 ) {
                    executeSearch();
            }
    };

    return (
        <>
            <section
                className={classContainer}
            >
                <FormControl
                    placeholder={placeholderSearch}
                    className="inputSearcher"
                    style={{fontSize:"1rem", width:"100%"}}
                    onChange={ (e) => setSearch(e.target.value) }
                    value={ search }
                    onKeyDown={(e) => onEnterKey(e)}
                />
                <div className="clearFloat"/>
                <div className={classHelperContextual} >
                    <small>{helperContextual}</small>
                </div>
                <div className="clearFloat"/>
                <div className={ classButtonsSearch ? classButtonsSearch : "buttonsSearch"} >
                    <Button
                        size="sm"
                        variant="secondary"
                        onClick={ () => cleanSearch() }
                        disabled={ isCleaning || isSearching }
                        style={ isSearching ? {display:"none"} : null}
                    >
                        { isCleaning ? "Limpiando..." : placeholderCleanButton}
                    </Button>
                    <Button
                        size="sm"
                        variant="primary"
                        onClick={ () => executeSearch() }
                        disabled={ isSearching || isCleaning }
                        style={ isCleaning ? {display:"none"} : null}
                    >
                        { isSearching ? "Buscando..." : placeholderSearchButton}
                        <i className="ti-search"></i>
                    </Button>
                </div>
            </section>
        </>
    );
};

export default Searcher;