import React, { useState, useEffect } from "react";
import { RemotePagination } from "../helper_components/remote_pagination/RemotePagination";
import { getMyProcessData, initColumnsMyProcess } from "./table_process_logic";

const TableProcessMyProcess = props => {
  const [columns] = useState(initColumnsMyProcess);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);

  useEffect(() => {
    (async function() {
      await getMyProcessData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page
      );
    })();
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await getMyProcessData(
      props,
      setData,
      setTotalProcedures,
      sizePerPage,
      page
    );
  };

  return (
    <>
      <h4 className="mB-20 mt-0 w-100 text-center">Mis trámites</h4>

      <RemotePagination
        data={data}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalProcedures}
        onTableChange={handleTableChange}
        columns={columns}
      />
    </>
  );
};
export default TableProcessMyProcess;
