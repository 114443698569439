import React, { useEffect, useContext } from "react";
import Cookies from "js-cookie";
import {Col, Row} from "react-flexbox-grid";
import {useLastLocation} from "react-router-last-location";
import notificationAlertContext from "../alertNotificationTable/notification_alert_context";
import {printFunctionaryNotification} from "../alertNotificationTable/alert_notification_logic";
import OneColumn from "../layout/containers/OneColumn";

const Home = props => {
    const lastLocation = useLastLocation();
    const auth = Cookies.get("auth_03");

    let pageConfig = {
        active: 'home',
        title: 'Home'
    };

    if (lastLocation) {
        if (lastLocation.pathname === "/") {
            window.location.reload();
        }
    }
    if (!auth) {
        props.history.push("/");
    }

    let alertContext = useContext(notificationAlertContext);
    useEffect(() => {
      if( alertContext.count > 0 ){
        printFunctionaryNotification();
      }
    }, []);

    return (
        <OneColumn {...props} pageConfig={pageConfig}>
            <section className="home">
                <Row center="xs">
                    <Col xs={12}>
                        <h1 className="mT-40">Información de interés.</h1>
                    </Col>
                </Row>
            </section>
        </OneColumn>
    );
};

export default Home;
