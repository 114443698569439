import React, { useState, useEffect, useContext } from "react";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import { Button, Form, Card, Container, Row, Dropdown } from "react-bootstrap";
import ModalComponent from "../../helper_components/ModalComponent";
import Select from "react-select";
import {
  columns, getDocumentsByExpedientNumber, documentTypeCatalog,
  getPromotionsWithoutAgreement, bodyModalDocuments, handleRequestPerformances,
  footerModalDocuments, getEvidencesDocument, getAgreementTypes,
  bodyTransfer, footerTransfer, getDependencesToTransfer, handleTransferExpedient,
  getAllDocumentsOfExpedient, bodyDocumentsView, footerDocumentsView,
  bodyChangeStatus, footerChangeStatus, getStatusesOptions, requestSaveNewStatus,
  getSearchDocumentByExpedientNumber
} from "./expedient_details_logic";
import Dropzone from "react-dropzone";
import { fieldsValidation, renderErrorsByInputName, selectStylesDefault, getOptionsCourtCatalogs } from "../my_expedients/expedients_list_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Cookies from "js-cookie";
import Footer from "../../layout/shared/Footer";
import TableUploadManyFiles from "../../helper_components/TableUploadManyFiles";
import PreviewTemplates from "../PreviewTemplates";
import TemplatesPresaved from "../TemplatesPresaved";
import { jsPDF } from "jspdf";
import { getUsersToTransfer } from "../pending_transfers/pending_transfers_logic";
import { formatAfterExpedientEdit } from "../modify_expedients/modify_expedients_logic";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment";
moment.locale("es");
registerLocale("es", es);

const ExpedientDetails = (props) => {
  const [cleaning, setCleaning] = useState(false);
  const [searching, setSearching] = useState(false);
  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const canUseDemandTemplate = specialPermissions && specialPermissions[0] && specialPermissions[0]['demands_template'] === '1';
  const canUsePromotionTemplate = specialPermissions && specialPermissions[0] && specialPermissions[0]['promotions_template'] === '1';
  const [dataExpedient, setDataExpedient] = useState({});
  const [search, setSearch] = useState("");
  const [textNotDataDocuments, setTextNotDataDocuments] = useState("");
  const [show, setShow] = useState(false);
  const [typeFile, setTypeFile] = useState({});
  const [file, setFile] = useState({});
  const [imageURL, setImageURL] = useState("");
  const expedientNumber = props.match.params.expedientNumber;
  const [errors, setErrors] = useState({});
  const [dataDocuments, setDataDocuments] = useState([]);
  const loader = useContext(LoaderContext);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [promotions, setPromotions] = useState([]);
  const { authentication_token_03 } = Cookies.get();
  const [imageURLProofDocument, setImageURLProofDocument] = useState("");
  const [typeFileProofDocument, setTypeFileProofDocument] = useState({});
  const [showDocuments, setShowDocuments] = useState(false);
  const [documentsState, setDocumentsData] = useState([]);
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [showModalTemplates, setShowModalTemplates] = useState(false);
  const [templatesType, setTemplatesType] = useState('');
  const [reloadDataTemplates, setReloadDataTemplates] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [showDocumentsView, setShowDocumentsView] = useState(false);
  const [documentsView, setDocumentsView] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(0);
  const [pauseCarousel, setPauseCarousel] = useState(false);
  const [performances, setPerformances] = useState([]);
  const [showTransfer, setShowTransfer] = useState(false);
  const [dataTransfer, setDataTransfer] = useState({});
  const [users, setUsers] = useState([]);
  const [dependences, setDependences] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [changeStatus, setChangeStatus] = useState({});
  const [optionsStatuses, setOptionsStatuses] = useState([]);
  const [courtCatalogs ,setCourtCatalogs] = useState([]);

  useEffect(() => {
    (async function () {
      loader.show(true);
      await getDocumentsByExpedientNumber(
        expedientNumber,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments
      );
      loader.show(false);
    })();
  }, []);

  const handleShowDocumentsView = async () => {
    loader.show(true);
    await getAllDocumentsOfExpedient(
      expedientNumber, setDocumentsView, setShowDocumentsView
    );
    loader.show(false);
  };

  const setCloseDocumentsView = () => {
    setCurrentDocument(0);
    setPauseCarousel(true);
    setShowDocumentsView(false);
  };

  const reloadComponent = async (message, title, time) => {
    await getDocumentsByExpedientNumber(
      expedientNumber.replace(/\//g, '-'),
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments
    );

    setCloseModal();
    NotificationManager.success(
      message ? message : "El archivo fue cargado correctamente",
      title ? title : "",
      time ? time : 3000
    );
  };

  const setStateDocuments = async (documentId) => {
    loader.show(true);
    await getEvidencesDocument(
      documentId,
      setDocumentsData,
      setShowDocuments
    );
    loader.show(false);
  };

  const bodyModal = (
    <>
      <Form>
        <Form.Group>
          <Form.Label>* Tipo de actuaciones:</Form.Label>

          <Select
            options={performances}
            onChange={(selected) => {
              setFile({ 
                ...file, 
                document_type: selected["value"],
                has_expiration: selected["has_expiration"],
                relief: selected["relief"]
              });
              setErrors({ ...errors, document_type: [] });
            }}
            placeholder="Selecciona la actuación"
            styles={selectStylesDefault}
          />
        </Form.Group>
        {renderErrorsByInputName(errors, "document_type")}

        { file["has_expiration"] ? 
          <>
            <Form.Group>
              <Form.Label>* Agrega fecha de expiración:</Form.Label>

              <DatePicker
                showPopperArrow={false}
                selected={file['expiration_date'] || new Date()}
                onChange={date => {
                  delete errors['expiration_date'];
                  setFile({ ...file, expiration_date: date });
                }}
                locale="es"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control"
                placeholderText={"Selecciona una fecha de expiración"}
                minDate={new Date()}
              />
            </Form.Group>
            {renderErrorsByInputName(errors, "expiration_date")}
          </> : '' }

        <Form.Group>
          <Form.Label>Comentarios:</Form.Label>
          <Form.Control
            onChange={(e) => setFile({ ...file, comment: e.target.value })}
            as="textarea"
            rows="5"
            aria-label="Comentarios"
            maxLength={255}
          />
          {renderErrorsByInputName(errors, "comment")}
        </Form.Group>
          
        <>
          <Form.Group controlId="formBasicFile">
            <Form.Label>{'Documento:'}</Form.Label>
            <Dropzone
              onDrop={(files, element) => onDrop(files, 1)}
              accept={[".png", ".pdf", ".jpg", ".jpeg"]}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="file-nilo-wrapper" {...getRootProps()}>
                  { '* Selecciona un documento'}
                  <input style={{ display: "inline!important" }} className="form-control" {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Form.Group>
          {renderErrorsByInputName(errors, "file")}
          <div
            className={typeFile["type"] ? "file-upload-wrapper" : "d-none"}
          >
            <img
              className={typeFile["type"] === "document" ? "d-block" : "d-none"}
              src={process.env.PUBLIC_URL + "/img/document.svg"}
              alt="Documento"
            />
            <img
              className={typeFile["type"] === "image" ? "" : "d-none"}
              src={imageURL}
              alt="Imagen"
              width="50%"
            />
          </div>
          <small style={{ float: "right" }}>{typeFile["name"]}</small>
          <br />
        </>

        <Form.Group>
          <Form.Label>* Número de personas que firman:</Form.Label>
          <Form.Control
            value={file["amount_signatures"] ? file["amount_signatures"] : ""}
            aria-label="Número de firmas"
            onChange={(e) => {
              setFile({ ...file, amount_signatures: e.target.value });
              setErrors({ ...errors, amount_signatures: [] });
            }}
            type="number"
            min={0}
            onInput={(e) => {
              if (e.target.value < 0) e.target.value = 0;
            }}
          />
          {renderErrorsByInputName(errors, "amount_signatures")}
        </Form.Group>

        {
          parseInt(file["amount_signatures"]) > 0 ?
          <Form.Group>
            <Form.Label>Hombres:</Form.Label>
            <Form.Control
              value={file["h_firma"] ? file["h_firma"] : ""}
              aria-label="Hombres"
              onChange={(e) => {
                setFile({ ...file, h_firma: e.target.value });
                setErrors({ ...errors, h_firma: [] });
              }}
              type="number"
              min={0}
              onInput={(e) => {
                if (e.target.value < 0) e.target.value = 0;
              }}
            />
            {renderErrorsByInputName(errors, "h_firma")}
          </Form.Group> : ''
        }
        
        {
          parseInt(file["h_firma"]) > 0 ? 
          <React.Fragment>
            <Form.Group>
              <Form.Label>Tercera edad:</Form.Label>
              <Form.Control
                value={file["h_edad_firma"] ? file["h_edad_firma"] : ""}
                aria-label="Hombres"
                onChange={(e) => {
                  setFile({ ...file, h_edad_firma: e.target.value });
                  setErrors({ ...errors, h_edad_firma: [] });
                }}
                type="number"
                min={0}
                onInput={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
              {renderErrorsByInputName(errors, "h_edad_firma")}
            </Form.Group>

            <Form.Group>
              <Form.Label>Discapacidad:</Form.Label>
              <Form.Control
                value={file["h_dis_firma"] ? file["h_dis_firma"] : ""}
                aria-label="Hombres"
                onChange={(e) => {
                  setFile({ ...file, h_dis_firma: e.target.value });
                  setErrors({ ...errors, h_dis_firma: [] });
                }}
                type="number"
                min={0}
                onInput={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
              {renderErrorsByInputName(errors, "h_dis_firma")}
            </Form.Group>
          </React.Fragment> : ''
        }

        {
          parseInt(file["amount_signatures"]) > 0 ?
          <Form.Group>
            <Form.Label>Mujeres:</Form.Label>
            <Form.Control
              value={file["m_firma"] ? file["m_firma"] : ""}
              aria-label="Mujeres"
              onChange={(e) => {
                setFile({ ...file, m_firma: e.target.value });
                setErrors({ ...errors, m_firma: [] });
              }}
              type="number"
              min={0}
              onInput={(e) => {
                if (e.target.value < 0) e.target.value = 0;
              }}
            />
            {renderErrorsByInputName(errors, "m_firma")}
          </Form.Group> : ''
        }

        {
          parseInt(file["m_firma"]) > 0 ?
          <React.Fragment>
            <Form.Group>
              <Form.Label>Tercera edad:</Form.Label>
              <Form.Control
                value={file["m_edad_firma"] ? file["m_edad_firma"] : ""}
                aria-label="Mujeres edad"
                onChange={(e) => {
                  setFile({ ...file, m_edad_firma: e.target.value });
                  setErrors({ ...errors, m_edad_firma: [] });
                }}
                type="number"
                min={0}
                onInput={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
              {renderErrorsByInputName(errors, "m_edad_firma")}
            </Form.Group>

            <Form.Group>
              <Form.Label>Discapacidad:</Form.Label>
              <Form.Control
                value={file["m_dis_firma"] ? file["m_dis_firma"] : ""}
                aria-label="Mujeres discapacidad"
                onChange={(e) => {
                  setFile({ ...file, m_dis_firma: e.target.value });
                  setErrors({ ...errors, m_dis_firma: [] });
                }}
                type="number"
                min={0}
                onInput={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
              {renderErrorsByInputName(errors, "m_dis_firma")}
            </Form.Group>
          </React.Fragment> : ''
        }
      </Form>
    </>
  );

  const closeModalDocuments = () => {
    setDocumentsData([]);
    setShowDocuments(false);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    await getDocumentsByExpedientNumber(
      expedientNumber,
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments
    );
  };

  const searchDocuments = async () => {
    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) { 
      setSearching(true);
      loader.show(true);
      let searchValue = search.replace(/^\s+|\s+$/gm,'').replace('/','-');
      await getSearchDocumentByExpedientNumber(
        expedientNumber,
        searchValue,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments
      );
      setSearching(false);
      loader.show(false);
    } else {
      cleanSearchDocuments();
    }
  };

  const cleanSearchDocuments = async () => {
    setSearch('');
    setCleaning(true);
    loader.show(true);
    await getDocumentsByExpedientNumber(
      expedientNumber,
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments
    );
    setCleaning(false);
    loader.show(false);
  };

  const showModalNewDocument = async () => {
    await handleRequestPerformances(setPerformances);
    setFile({
      promoter: dataExpedient['actor'] || 'N/A'
    });
    setShow(true);
  };

  const setCloseModal = () => {
    setDataUploadEvidences(null);
    setErrors({});
    setFile({});
    setTypeFile({});
    setImageURL("");
    setTypeFileProofDocument({});
    setImageURLProofDocument("");
    setShow(false);
  };

  const onDrop = (files, inputNumber) => {
    const reader = new FileReader();

    if (files.length > 0 && inputNumber === 1) {

      if (files[0]["type"].includes("image")) {
        setImageURL(URL.createObjectURL(files[0]));
        setTypeFile({ type: "image", name: files[0]["name"] });
      }
      else setTypeFile({ type: "document", name: files[0]["name"] });

      reader.onload = (event) => {

        setErrors({ ...errors, file: [] });
        setFile({ ...file, file: files[0] });
      };

      reader.readAsDataURL(files[0]);
    }
    else if (files.length > 0 && inputNumber === 2) {

      if (files[0]["type"].includes("image")) {
        setImageURLProofDocument(URL.createObjectURL(files[0]));
        setTypeFileProofDocument({ type: "image", name: files[0]["name"] });
      }
      else setTypeFileProofDocument({ type: "document", name: files[0]["name"] });

      reader.onload = (event) => {

        setErrors({ ...errors, evidence_file: [] });
        setFile({ ...file, evidence_file: files[0] });
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const sendFile = async () => {
    const requiredFields = file["has_expiration"] ?
      ["document_type", "file", "amount_signatures", "expiration_date"] : 
      ["document_type", "file", "amount_signatures"];

    const responseValidation = fieldsValidation(requiredFields, file);

    if (typeof responseValidation === "object") {
      NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
      setErrors(responseValidation);
    }
    else {
      loader.show(true);
      let formData = new FormData();
      const keysDataSend = file["has_expiration"] ?
        ["document_type", "file", "amount_signatures", 
        "expedient_number", "comment", "has_expiration", "relief", 
        "expiration_date", "h_firma", "h_edad_firma", "h_dis_firma", 
        "m_firma", "m_edad_firma", "m_dis_firma"] : 
        ["document_type", "file", "amount_signatures", "expedient_number", 
        "comment", "relief", "has_expiration", "h_firma", "h_edad_firma", "h_dis_firma",
        "m_firma", "m_edad_firma", "m_dis_firma"];

      for (let dataKey of keysDataSend) {
        if (dataKey === 'expedient_number') formData.append('expedient_number', expedientNumber.replace(/-/g, '/'))
        else if (dataKey === 'expiration_date') formData.append(dataKey, (new Date(file[dataKey])).getTime());
        else formData.append(dataKey, file[dataKey] !== null || file[dataKey] !== '' ? file[dataKey] : '');
      };

      const responseDocumentSave = await axios({
          method: "post",
          url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/upload`,
          data: formData,
          headers: {
            Authorization: String(authentication_token_03)
          }
        });
        if (
          responseDocumentSave && responseDocumentSave.data &&
          responseDocumentSave.data.code === 200 && responseDocumentSave.data.data &&
          responseDocumentSave.data.data.documentExpedient
        ) {
          await reloadComponent();
        }
        else {
          if (
            responseDocumentSave.data.data.error &&
            responseDocumentSave.data.data.message &&
            typeof responseDocumentSave.data.data.message === "object"
          ) {
            setErrors(responseDocumentSave.data.data.message);
          }

          NotificationManager.error("Ha ocurrido un error y tu archivo no fue cargado correctamente");
        }
      loader.show(false);
    }
  };

  const footerModal = (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()}>Cancelar</Button>
      <Button variant="primary" onClick={() => sendFile()} >Guardar Cambios</Button>
    </>
  );

  const showTemplatesView = (type) => {
    setTemplatesType(type);
    setShowModalTemplates(true);
  };

  const printPDFCover = () => {
    const cover = document.getElementById('coverPDF');
    const doc = new jsPDF('p', 'pt', 'letter');
    cover.style.width = "600px";

    doc.html(cover, {
      callback: function (pdf) {
        cover.style.width = "";
        pdf.autoPrint({ variant: 'non-conform' });
        pdf.output('dataurlnewwindow', { filename: 'caratula-' + expedientNumber + '.pdf' });
      }
    });
  };

  const handleOpenTransfer = async () => {
    loader.show(true);
    await getUsersToTransfer(setUsers, dataExpedient['court_id']);
    await getDependencesToTransfer(setDependences);
    const courtCatalogOptions = await getOptionsCourtCatalogs(dataExpedient['court_id']);
		await formatAfterExpedientEdit(courtCatalogOptions, dataExpedient);
		setCourtCatalogs(courtCatalogOptions);

    setDataTransfer({
      ...dataExpedient,
      via: {
        label: dataExpedient.via,
        value: dataExpedient.via_id
      },
      kind_judgment: {
        label: dataExpedient.kind_judgment,
        value: dataExpedient.kind_judgment_id
      }
    });
    setShowTransfer(true);
    loader.show(false);
  };

  const handleCloseTransfer = () => {
    setShowTransfer(false);
  };

  const handleSubmitTransfer = async () => {
    const frontValidation = fieldsValidation(['receiver', 'type'], dataTransfer);
    if ( typeof frontValidation === "object" ) {
      setErrors(frontValidation);
      NotificationManager.error(
        'Hace falta información requerida, por favor revisa los campos marcados en rojo...'
      );
    }
    else {
      loader.show(true);
      let data = {};
      if (dataTransfer.type === 'internal') {
        data = {
          ...dataTransfer,
          receiver_id: dataTransfer.receiver.value,
          kind_judgment: dataTransfer.kind_judgment.value,
          via: dataTransfer.via.value
        }
      } else if (dataTransfer.type === 'external') {
        data = {
          ...dataTransfer,
          dependence_id: dataTransfer.receiver.value,
          kind_judgment: dataTransfer.kind_judgment.value,
          via: dataTransfer.via.value
        }
      }
      await handleTransferExpedient(data, props, setErrors);
      loader.show(false);
    }
  };

  const handleOpenChangeStatus = async () => {
    await getStatusesOptions(setOptionsStatuses);
    if (dataExpedient.statuses && dataExpedient.statuses.length > 0) {
       if (dataExpedient.statuses.length === 1) {
        setChangeStatus({
          id: dataExpedient.statuses[0]['id'],
          name: dataExpedient.statuses[0]['name'],
          changeable: dataExpedient.statuses[0]['changeable'],
          expedientNumber: expedientNumber
        });
       } else if (dataExpedient.statuses.length === 2) {
        setChangeStatus({
          id: dataExpedient.statuses[1]['id'],
          name: dataExpedient.statuses[0]['name'] + ' - ' + dataExpedient.statuses[1]['name'],
          changeable: dataExpedient.statuses[1]['changeable'],
          expedientNumber: expedientNumber
        });
       } else {
        setChangeStatus({
          id: dataExpedient.statuses[2]['id'],
          name: dataExpedient.statuses[0]['name'] + ' - ' + 
            dataExpedient.statuses[1]['name'] + ' - ' + dataExpedient.statuses[2]['name'],
          changeable: dataExpedient.statuses[2]['changeable'],
          expedientNumber: expedientNumber
        });
       }
    } else {
      setChangeStatus({
        id: null,
        name: 'No definido',
        changeable: true,
        expedientNumber: expedientNumber
      });
    }
    setOpenStatus(true);
  };

  const handleSelectStatus = (selected) => {
    if (
      (dataExpedient.statuses && 
      dataExpedient.statuses.length > 0 &&
      dataExpedient.statuses[dataExpedient.statuses.length - 1]['changeable']) ||
      dataExpedient.statuses === ''
    ) {
      setErrors({});
      setChangeStatus({
        id: selected.selectedId,
        name: selected.selectedName,
        changeable: true,
        expedientNumber: expedientNumber
      });
    }
  };

  const handleSubmitChangeStatus = async () => {
    const requiredFields = ['expedientNumber', 'id'];
    const frontValidation = fieldsValidation(requiredFields, changeStatus);
    if (typeof frontValidation === 'object') {
      setErrors(frontValidation);
    } else {
      loader.show(true);
      await requestSaveNewStatus(changeStatus, setOpenStatus, reloadComponent, setErrors);
      loader.show(false);
    }
  };

  return (
    <>
      <NavBar {...props} />

      <div className="container-header-expedient-documents-details mT-10">
        <div className="text-center">
          <h3>
            {expedientNumber.replace(/-/g, '/')}
          </h3>
        </div>
        <div className="box-header-expedient-documents-details">
          <Button
            onClick={() => props.history.goBack()}
          >
            <i className="fas fa-arrow-left" /> Volver
          </Button>
        </div>
      </div>
      <br />

      <Row style={{ justifyContent: "center" }}>
        <Card
          className="cardExpedientDetails"
          id="coverPDF"
        >
          <Card.Body>
            <Container>
              <div>
                <h5
                  style={{ color: 'gray', fontWeight: 'bold', marginTop: '20px', textAlign: 'center' }}
                >
                  Datos generales del Expediente:
                </h5>
                <div
                  style={{ border: '0px solid rgba(0,0,0,.0625)', borderRadius: '4px' }}
                  className="table-responsive"
                >
                  <table className="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <th className="caratule_heading pR-15">Región:</th>
                        <td className="caratule_content pL-15">{dataExpedient["judicial_party"]}</td>
                      </tr>

                      <tr>
                        <th className="caratule_heading pR-15">Tipo de materia:</th>
                        <td className="caratule_content pL-15">{dataExpedient["court"]}</td>
                      </tr>

                      <tr>
                        <th className="caratule_heading pR-15">Tipo de juicio:</th>
                        <td className="caratule_content pL-15">{dataExpedient["kind_judgment"]}</td>
                      </tr>

                      <tr>
                        <th className="caratule_heading pR-15">Juzgado:</th>
                        <td className="caratule_content pL-15">{dataExpedient["court_catalog_name"] || 'N/A'}</td>
                      </tr>

                      <tr>
                        <th className="caratule_heading pR-15">Número de expediente:</th>
                        <td className="caratule_content pL-15">{dataExpedient["court_expedient"] || 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div>
                <h5
                  style={{ fontWeight: 'bold' }}
                >
                  {'Solicitante: ' + dataExpedient["actor"]}
                </h5>
                <div
                  style={{ border: '0px solid rgba(0,0,0,.0625)', borderRadius: '4px' }}
                  className="table-responsive text-center"
                >
                  <table className="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <th className="caratule_heading pR-15">Notificaciones electrónicas:</th>
                        <td className="caratule_content pL-15">{dataExpedient["actor_email"] || 'N/A'}</td>
                      </tr>
                      <tr>
                        <th className="caratule_heading pR-15">Tipo de solicitante:</th>
                        <td className="caratule_content pL-15">{dataExpedient["actor_type"] || 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <b>Iniciado en: {dataExpedient['date'] || ''}</b>
              </div>
            </Container>
          </Card.Body>
        </Card>
      </Row>
      <Row style={{ justifyContent: 'center', marginTop: '10px' }}>
        <div className="download-cover-expedient">
          <Button
            onClick={() => handleOpenTransfer()}
            variant="secondary"
            className="cover-expedient-transfer"        
          >
            <i className="ti-arrow-circle-right" title="Transferir expediente" />
            {'  '} Turnar expediente
          </Button>
          <Button
            onClick={() => handleOpenChangeStatus()}
            variant="success"
          >
            <i className="fas fa-tasks" title="Cambiar estatus" />
            {'  '} Estatus
          </Button>
          <Button
            onClick={() => printPDFCover()}
            className="btn btn-danger"
          >
            <i className="fas fa-print" />
            {'  '} Imprimir
          </Button>
        </div>
      </Row>

      { String(dataExpedient['user_id']) === Cookies.get('userId_03') ?
        <div className="optionsExpedientDocuments">
          <h4 className={"w-100 text-center" + (dataExpedient["external"] ? "" : " mB-30")}>Documentos del Expediente</h4>
          <div>
          {dataExpedient['external'] ? '' :
            <div id="newDocument">
              <Button
                onClick={() => handleShowDocumentsView()}
                className="mR-5"
                variant="success"
              >
                <i className="fas fa-book-open" />
                {'  '} Ver expediente completo
              </Button>
              {
                canUseDemandTemplate || canUsePromotionTemplate ?
                <Dropdown className="mR-5">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Plantillas
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    { canUseDemandTemplate ?
                      <Dropdown.Item 
                        href="#"
                        onClick={() => showTemplatesView('demand')}
                      >
                        Tipo demanda
                      </Dropdown.Item> : '' }
                    { canUsePromotionTemplate ? 
                      <Dropdown.Item 
                        href="#"
                        onClick={() => showTemplatesView('promotion')}
                      >
                        Tipo promoción
                      </Dropdown.Item> : '' }
                  </Dropdown.Menu>
                </Dropdown> : ""
              }

              <Button
                onClick={() => showModalNewDocument()}
              >
                <i className="fas fa-plus" />
                {'  '} Agregar actuación
              </Button>
            </div>
          }
          </div>

          <div className="clearFloat" />
          <div className="containerSearcherDocuments">
            <ExpedientsSearcher
              placeholderSearch="Escribe el nombre del documento"
              placeholderSearchButton="Buscar documento"
              placeholderCleanButton="Limpiar"
              helperContextual="Escribe el nombre del documento y haz clic en el botón Buscar documento."
              executeSearch={searchDocuments}
              cleanSearch={cleanSearchDocuments}
              isSearching={searching}
              isCleaning={cleaning}
              setSearch={setSearch}
              search={search}
              hasRulesToSearch={false}
            />
          </div>

          <div className="clearFloat" />
          {dataExpedient['external'] ? '' :
            <TemplatesPresaved
              expedientNumber={expedientNumber}
              reloadData={reloadDataTemplates}
              setReloadData={setReloadDataTemplates}
              reloadParentComponent={reloadComponent}
              props={props}
            />}
        </div> : <div className="mT-50" />
      }

      <Grid fluid={true} className={dataDocuments.length > 0 ? "no-padding" : "d-n"} style={{ minHeight: '76vh', width: "65%", margin: "0px auto" }}>
        <section className="procedures-table-container mB-50">
          <RemotePagination
            data={dataDocuments}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalDocuments}
            onTableChange={handleTableChange}
            columns={columns}
          />

        </section>
      </Grid>

      { dataDocuments.length === 0 && textNotDataDocuments !== "" ?
        <div className="text-center ">
          <i style={{ fontSize:'70px' }} className="fas fa-folder-open" />
        </div> : '' }
      <h4 className={dataDocuments.length === 0 && textNotDataDocuments !== "" ? "text-center clearFloat padding-footer-not-data-documents" : "d-n"}>{textNotDataDocuments}</h4>

      <Footer />

      <ModalComponent
        header="Documentos del expediente"
        body={bodyDocumentsView(
          currentDocument, setCurrentDocument, 
          documentsView, pauseCarousel, setPauseCarousel
        )}
        footer={footerDocumentsView(setCloseDocumentsView)}
        show={showDocumentsView}
        canClose={true}
        onHide={setCloseDocumentsView}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header="Expediente"
        body={bodyTransfer(
          courtCatalogs,
          dataExpedient,
          dataTransfer,
          setDataTransfer,
          users,
          dependences,
          errors
        )}
        footer={footerTransfer(handleCloseTransfer, handleSubmitTransfer)}
        show={showTransfer}
        canClose={true}
        onHide={handleCloseTransfer}
      />

      <ModalComponent
        header="Nueva actuación"
        body={bodyModal}
        footer={footerModal}
        show={show}
        canClose={true}
        onHide={setCloseModal}
      />

      <ModalComponent
        header="Documentos de pruebas"
        body={bodyModalDocuments(documentsState)}
        footer={footerModalDocuments(closeModalDocuments)}
        show={showDocuments}
        canClose={true}
        onHide={setShowDocuments}
        scroll={true}
      />

      <ModalComponent
        header={`Plantillas de tipo ${templatesType === 'promotion' ? 'promoción:' : templatesType === 'demand' ? 'demanda' : 'sentencia:'}`}
        body={<PreviewTemplates
          type={templatesType}
          expedientNumber={expedientNumber}
          setReloadDataTemplates={setReloadDataTemplates}
          reloadDataTemplates={reloadDataTemplates}
        />}
        footer={(
          <>
            <Button variant="secondary" onClick={() => setShowModalTemplates(false)}>Cerrar</Button>
          </>
        )}
        show={showModalTemplates}
        canClose={true}
        onHide={setShowModalTemplates}
        scroll={true}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header={expedientNumber.replace(/-/g, '/')}
        body={bodyChangeStatus(
          optionsStatuses, 
          handleSelectStatus, 
          changeStatus, 
          dataExpedient.statuses,
          errors
        )}
        footer={footerChangeStatus(setOpenStatus, handleSubmitChangeStatus, dataExpedient.statuses)}
        show={openStatus}
        canClose={true}
        onHide={setOpenStatus}
        scroll={true}
        dialogClassName="modal-expedients-statuses"
      />

      <Loader />
    </>
  );
};

export default ExpedientDetails;
