import Cookies from "js-cookie";
import { Request } from "../classes/Request";

////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

export const deleteProcedureByUser = async (el, history) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/delete/${userId_03}/${el.id}?token=${process.env.REACT_APP_TOKEN}`,
    "delete",
    null,
    {}
  );

  return await request.executeRequest();
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
