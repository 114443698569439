import React from "react";

/**
 * Context loader
 * @type {React.Context<{show: LoaderContext.show, status: boolean}>}
 */
const CountProcessContext = React.createContext({
    countProcess: {},
    setCountProcess: () => {}
});

export default CountProcessContext;
