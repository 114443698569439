import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {getObservations, onApprovedCommentRequest, saveObservation} from "./observation_logic";

const ObservationModal = props => {
    const [observations, setObservations] = useState("");
    const [element, setElement] = useState({});
    const [formattObservations, setFormattObservations] = useState(null);
    const [aceptObservationsButton, setAcceptObservationButton] = useState(false);

    useEffect(() => {
        (async function () {
            if (props.el.observations) {
                setAcceptObservationButton(true);
            } else {
                setAcceptObservationButton(false);
            }
            setElement(props.el);
            if (props.show) {
                //LOADER
                await getObservations(props.stage, props.el, setFormattObservations);
                //LOADER
            }
        })();
    }, [props.show]);

    const onSaveObservationHandler = async () => {
        //LOADER
        await saveObservation(observations, props.setShow, setObservations, element, props.stage);
        //LOADER
    };

    const onApprovedComment = async () => {
        //LOADER
        await onApprovedCommentRequest(props.setShow, element, props.stage);
        //LOADER
    };

    return (
        <>
            <Modal show={props.show} size="lg" onHide={() => props.setShow(false)}>
                <Modal.Header>
                    <Modal.Title>Observaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="observations">{formattObservations}</div>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Describe la observacion</Form.Label>
                        <Form.Control as="textarea" rows="3" onChange={e => setObservations(e.target.value)}/>
                    </Form.Group>

                    <div className="mT-30 mB-10">
                        <Button variant="danger" onClick={() => props.setShow(false)}>Cerrar</Button>
                        <Button variant="primary" onClick={onSaveObservationHandler} className="float-right">Continuar</Button>
                        <Button variant="success" onClick={onApprovedComment} className="float-right mr-2" style={aceptObservationsButton ? {display: "block"} : {display: "none"}}>
                            <i className="fas fa-thumbs-up mr-2" />Aprobar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ObservationModal;
