import React from "react";
import { Card, Alert, Button } from "react-bootstrap";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";

const ErrorPage = props => {
  const error = props.location.state.error ? props.location.state.error : null;
  const success = props.location.state.success
    ? props.location.state.success
    : null;
  const message = props.location.state.message;
  const redirect = props.location.state.redirect
    ? props.location.state.redirect
    : "/table_process/pending";

  const bodyErrorData = () => {
    if (!success) {
      return (
        <Card>
          <Card.Header>Error</Card.Header>
          <Card.Body>
            <Row start="xs">
              <Col xs={12}>
                <Alert variant="danger">
                  <Alert.Heading>
                    Oh lo sentimos! Algo ah ocurrido mal!
                  </Alert.Heading>
                  <p>{error}</p>
                  <hr />

                  <div className="d-flex justify-content-end">
                    <Link to={redirect}>
                      <Button variant="outline-danger">Regresar</Button>
                    </Link>
                  </div>
                </Alert>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <Card>
          <Card.Header>Estatus de la operación</Card.Header>
          <Card.Body>
            <Row start="xs">
              <Col xs={12}>
                <Alert variant="success">
                  <Alert.Heading>Operación exitosa</Alert.Heading>
                  <p>{message}</p>
                  <hr />
                </Alert>

                <div className="d-flex justify-content-end">
                  <Link to={redirect}>
                    <Button variant="outline-success">Continuar</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }
  };

  return (
    <Row className="margin-top-2em">
      <Col xs={12} md={1} />
      <Col xs={12} md={10}>
        {bodyErrorData()}
      </Col>
      <Col xs={12} md={1} />
    </Row>
  );
};

export default ErrorPage;
