import React, { useState } from "react";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";

const SideBar = (props) => {
    const { app_config_02, special_permissions_03 } = Cookies.get();
    const {
        open,
        onHide,
        history
    } = props;
    const [showItems,setShowItems] = useState(false);
    const app_config_parsed = app_config_02 ? JSON.parse(app_config_02) : null;
    const permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
    const canTurnForeign = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['foreign'] === '1';
    const canModifyExpedients = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['modify_expedient'] === '1';
    const canGoToProcedures = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['procedure_section'] === '1';
    const canGoToExpedients = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['expedient_section'] === '1';

    const hideSidebar = () => {
        onHide(false);
        setShowItems(false);
    };

    return (
        <>
        <div 
            className={ open ? "sidebarActiveContainer" : "sidebarInactiveContainer" }
            onClick= { () => hideSidebar() }
        />
        <div
            className={ open ? "sidebarActive" : "sidebarInactive"}
        >
            <div
                style={{width:"100%",marginLeft:"10%",marginTop:"18px"}}
            >
                <img
                    width={"50%"} 
                    src={ app_config_parsed && app_config_parsed['app_functionary_header_logo_url_image'] ? app_config_parsed['app_functionary_header_logo_url_image'] : `${process.env.REACT_APP_URL_BASE_LARAVEL}img/nilo_completo_01.svg`} 
                    alt="application logo"
                />
            </div>
            {/* <Link
                to={location["pathname"]}
                style={{textDecoration:"none"}}
            >
                <div
                    className="optionSidebar"
                    style={{cursor: 'no-drop'}}
                    // onClick={ () => onHide(false) }
                >
                    <i 
                        className="fas fa-home mR-10"
                    />{'  '}
                    Inicio
                </div>
            </Link>
            <Link
                style={{textDecoration:"none"}}
                to={location["pathname"]}
            >
                <div
                    className="optionSidebar"
                    style={{cursor: 'no-drop'}}
                    // onClick={ () => onHide(false) }
                >
                    <i
                        className="fas fa-folder mR-10"
                    />{'  '}
                    Promociones
                </div>
            </Link> */}
            {canGoToProcedures &&
                <Link
                    style={{
                        textDecoration:"none"
                    }}
                    to={'/home'}
                >
                    <div
                        className="optionSidebar"
                        onClick={() => onHide(false)}
                        title="Sección de trámites"
                    >
                        <i
                            className="fas fa-atlas mR-10"
                        />{'  '}
                        Trámites
                    </div>
                </Link>
            }

            {   canGoToExpedients && 
                <div
                    className="optionSidebar"
                    onClick={ 
                        () => setShowItems( !showItems )
                    }
                >
                    <i 
                        className="fas fa-book mR-10"
                    />{'  '}
                    Expedientes
                    <ul className={ showItems ? "itemsDropdownGovernmentBook" : "d-n" }>
                        <Link to="/my_expedients"><li>Mis expedientes</li></Link>
                        <Link to="/pending_transfers"><li>Expedientes enviados</li></Link>
                        <Link to="/expedient_detail"><li>Buscar expediente</li></Link>
                        <Link to="/received_history"><li>Expedientes recibidos</li></Link>
                        <Link to="/pending_expedients_to_receive"><li>Expedientes por recibir</li></Link>
                    </ul>
                </div>
            }

            {   canTurnForeign &&
                <Link
                    style={{
                        textDecoration:"none"
                    }}
                    to={'/turn_foreign'}
                >
                    <div
                        className="optionSidebar"
                        onClick={ () => onHide(false) }
                        title="Turnado externo de expedientes"
                    >
                        <i
                            className="fas fa-university mR-10"
                        />{'  '}
                        Turnado externo de expedientes
                    </div>
                </Link>
            }
            {   canModifyExpedients &&
                <Link
                    style={{
                        textDecoration:"none"
                    }}
                    to={'/modify_expedients'}
                >
                    <div
                        className="optionSidebar"
                        onClick={ () => onHide(false) }
                        title="Modificar expedientes"
                    >
                        <i
                            className="fas fa-book mR-10"
                        />{'  '}
                        Modificar expedientes
                    </div>
                </Link>
            }
        </div>

        </>

    );
};

export default SideBar;
