import React from "react";
import { Row } from "react-flexbox-grid";

const ResizingFields = props => {
  function resizingFields() {
    const data = props.dataForm.map((el, index) => {
      return (
        <React.Fragment key={index}>
          {props.createInputBaseTypeForm(el)}
        </React.Fragment>
      );
    });
    return data;
  }
  return <Row>{resizingFields()}</Row>;
};
export default ResizingFields;
