import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../classes/RequestNewVersion";


export const initForm = {
  newPassword: "",
  confirmNewPassword: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const UpdatePasswordRecovery = async (form, resetPswToken, props) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "put",
    null,
    {
      password: form.newPassword,
      password_confirmation: form.confirmNewPassword,
      reset_password_token: resetPswToken
    }
  );
  const updatePasswordRecoveryData = await request.executeRequest();

  if ( updatePasswordRecoveryData.code !== 200 ) {

    NotificationManager.error( updatePasswordRecoveryData.response.message );
  }
  else if ( updatePasswordRecoveryData.code === 200 ) {

    if ( updatePasswordRecoveryData.response.error ) {

      NotificationManager.error( updatePasswordRecoveryData.response.message );
    }
    else if ( 
      updatePasswordRecoveryData.code === 200 &&
      typeof updatePasswordRecoveryData.response.message == 'string' &&
      updatePasswordRecoveryData.response.message !== '' &&
      !updatePasswordRecoveryData.response.error
    ) {

        setTimeout(() => { 
          NotificationManager.success(updatePasswordRecoveryData.response.message); 
        }, 800);
        props.history.push('/');
    }
  }
};
/***********************************************************************************************************************************************/
