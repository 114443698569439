import React, {useEffect, useState} from "react";
import {Col, Container, Row, Card, Button, Form} from "react-bootstrap";
import {getUserData, initForm, UpdateProfileRequest, renderClassesToErrors, renderErrorsList} from "./edit_profile_logic";
import OneColumn from "../layout/containers/OneColumn";
import Cookies from "js-cookie";

const EditProfile = props => {
    const [form, setForm] = useState(initForm);
    const { groups_03 } = Cookies.get();
    const arrayOfGroups = groups_03 ? JSON.parse(groups_03) : [];

    const handleChangeForm = event => {
        Object.keys(errors).forEach((error)=>{
            if(error === event.target.name){
                delete errors[event.target.name];
            }
        });
        setForm({...form, [event.target.name]: event.target.value});
    };
    const [errors, setErrors] = useState([]);

  let pageConfig = {
    active: "edit_profile",
    title: "Profile"
  };

    useEffect(() => {
        (async function () {
            await getUserData(setForm);
        })();
    }, []);

    const onSubmitFormHandler = async e => {
        e.preventDefault();
        await UpdateProfileRequest( form, props, setErrors );
    };

    return (
        <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true}>
            <Container
                fluid={true}
                className="min-height-100vh bg-size-cover"
                style={{
                    backgroundImage:
                        "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)"
                }}
            >
                <Container>
                    <Row style={{minHeight: "100vh"}}>
                        <Col
                            sm={12}
                            md={{span: 10, offset: 1}}
                            lg={{span: 8, offset: 2}}
                            xl={{span: 6, offset: 3}}
                            className="mY-30"
                        >
                            <Card className="mY-40@sm+">
                                <Card.Header>
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 10, offset: 1}}
                                                 className="pT-20 pB-10 pT-30@sm+ pB-20@sm+">
                                                <h4 className="mb-0 subtitle text-center">Edición de perfil</h4>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 10, offset: 1}} className="pT-30">

                                                <Form onSubmit={onSubmitFormHandler} className="mB-40">
                                                    <Form.Group controlId="formBasicName">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            * Nombre(s):
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="names"
                                                            value={!form.names ? "" : form.names}
                                                            onChange={handleChangeForm}
                                                            className={renderClassesToErrors(errors,"names")}
                                                        />

                                                        { renderErrorsList(errors,"names") }
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicPaternalSurname">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            * Primer apellido:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="paternal_surname"
                                                            value={!form.paternal_surname ? "" : form.paternal_surname}
                                                            onChange={handleChangeForm}
                                                            className={renderClassesToErrors(errors,"paternal_surname")}
                                                        />

                                                        { renderErrorsList(errors,"paternal_surname") }
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicMaternalSurname">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            Segundo apellido:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="maternal_surname"
                                                            value={!form.maternal_surname ? "" : form.maternal_surname}
                                                            onChange={handleChangeForm}
                                                            className={renderClassesToErrors(errors,"maternal_surname")}
                                                        />
                                                        { renderErrorsList(errors,"maternal_surname") }
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicCurp">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            CURP:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="curp"
                                                            disabled
                                                            value={!form.curp ? "" : form.curp}
                                                            onChange={handleChangeForm}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            * Correo electrónico:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="email"
                                                            disabled
                                                            value={!form.email ? "" : form.email}
                                                            onChange={handleChangeForm}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicGroup">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            Grupo:
                                                        </Form.Label>
                                                        <Form.Control type="text" value={arrayOfGroups.join(", ")} name="group" disabled/>
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label className="text-left font-weight-bold w-100">
                                                            * Contraseña actual:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Password"
                                                            required
                                                            minLength="8"
                                                            autoComplete="off"
                                                            onChange={handleChangeForm}
                                                            name="password"
                                                            className={renderClassesToErrors(errors,"password")}
                                                        />

                                                        { renderErrorsList(errors,"password") }
                                                    </Form.Group>

                                                    <Row className="btn-group-sign-up mT-40">
                                                        <Col xs={12} sm={12} className="dinamic-form-tool-bar">
                                                            <Button
                                                                variant="secondary"
                                                                className="float-left"
                                                                onClick={() => props.history.push("/my_expedients")}
                                                            >
                                                                Cancelar
                                                            </Button>
                                                            <Button
                                                                variant="primary"
                                                                type="submit"
                                                                className="float-right"
                                                            >
                                                                Actualizar
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </OneColumn>
    );
};

export default EditProfile;
