import React from 'react';
import Cookies from 'js-cookie';
import { NotificationManager } from 'react-notifications';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getTextOfDate, getTimeTranscurred } from "../my_expedients/expedients_list_logic";

export const initColumnsTurnForeign = ( showComment ) => {
  
  return [
    {
      dataField: "expedient_number_link",
      text: "Id del trámite",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "reception_date",
      text: "Fecha de recepción",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "comments",
      text: "Comentarios",
      sort: true,
      style: showComment ? { textAlign: "justify" } : { display:"none" },
      headerStyle:  showComment ? { textAlign: "center" } : { display:"none" }
    },
    {
      dataField: "responsible",
      text: "Dependencia",
      sort: true,
      style: { textAlign: "center" },
      headerStyle:  { textAlign: "center" }
    },
    {
      dataField: "time",
      text: "Tiempo",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    }
  ];
}; 


export const getExpedientsToTurnForeign = async (
    setExpedients,
    setTotalExpedients,
    page,
    sizePerPage,
    setShowModal,
    setOptions,
    setTransfer,
    transfer,
    setTextNotData,
    setNonSelectableArray,
    openModalDespaused,
    props
) => {

    const { userId_03 } = Cookies.get();
    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find/external/${userId_03}/${sizePerPage}?page=${page}`,
        "get",
        null,
        {}
      );
    
    const responseMyExpedients = await request.executeRequest();

    if ( responseMyExpedients.code !== 200 ) {

        if (
          responseMyExpedients.code === 403 &&
          responseMyExpedients.response.error &&
          responseMyExpedients.response.redirect
        ) {
          NotificationManager.error("No estás autorizado para este recurso", responseMyExpedients.response.message + ":", 3000);

          setTimeout(() => {
            props.history.push('/my_expedients');
          }, 3001);
          return;
        }
        else NotificationManager.error("Algo sucedió y no fue posible traer los expedientes, inténtalo nuevamente");
    } else {

        if (
            responseMyExpedients.response.error && 
            responseMyExpedients.response.message && 
            typeof responseMyExpedients.response.message === "string" 
        ) {
            NotificationManager.error( responseMyExpedients.response.message );
        }
        else if ( 
            responseMyExpedients.response.electronicExpedients.length === 0 && 
            responseMyExpedients.response.total === 0
        ) {

            setTextNotData("No se han encontrado expedientes");
        }
        else {
            const expedientsReduce = responseMyExpedients.response.electronicExpedients.reduce( (array,element) => {
              if ( element['released'] === false ) {
                
                array.push(element['id']);
              }
              return array;
            },[]);
            setNonSelectableArray(expedientsReduce);
            const expedients = await formattedExpedients(
              responseMyExpedients.response.electronicExpedients,
              setShowModal,
              setOptions,
              setTransfer,
              transfer,
              openModalDespaused           
            );
            setTotalExpedients(responseMyExpedients.response.total);
            setExpedients( expedients );
        }
    }
};

export const getDependencesToTransfer = async (
    setShowModal,
    setOptions,
    expedient,
    setTransfer,
    transfer
) => {
    let dependences = [];

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/dependences`,
        "get",
        null,
        {}
      );
    const responseDependences = await request.executeRequest();
    if ( responseDependences.code !== 200 || responseDependences.response.dependences.length === 0 ) {
        NotificationManager.error("No es posible cargar la lista de dependencias para la transferencia");
    }
    else {

        if ( responseDependences.response.dependences && responseDependences.response.dependences.length > 0 ) {

            for( const key in responseDependences.response.dependences ) {

                dependences.push( { label:responseDependences.response.dependences[key]['name'], value:responseDependences.response.dependences[key]['id'] } );
            }
            if ( expedient ) {
              setTransfer({ 
                ...transfer,
                expedient_number:expedient["expedient_number"],
                actor_name:expedient["actor_name"],
                actor_firstname:expedient["actor_firstname"],
                actor_secondname:expedient["actor_secondname"],
                actor_email:expedient["actor_email"],
                defendant_name:expedient["defendant_name"],
                defendant_firstname:expedient["defendant_firstname"],
                defendant_secondname:expedient["defendant_secondname"],
                defendant_email:expedient["defendant_email"],
                via: {
                  value: null,
                  label: expedient["via"]
                },
                kind_judgment: {
                  value: null,
                  label: expedient["kind_judgment"]
                },
                update_external:expedient["update_external"],
                government_book_id:expedient["government_book_id"],
                lawyer_defendant_name:expedient["lawyer_defendant_name"],
                lawyer_defendant_firstname:expedient["lawyer_defendant_firstname"],
                lawyer_defendant_secondname:expedient["lawyer_defendant_secondname"],
                lawyer_actor_name:expedient["lawyer_actor_name"],
                lawyer_actor_firstname:expedient["lawyer_actor_firstname"],
                lawyer_actor_secondname:expedient["lawyer_actor_secondname"],
                court_expedient:expedient["court_expedient"],
                demand:expedient["demand"],
                court:expedient["court"],
                court_name:expedient["court_name"]
              });
            } 
            setOptions(dependences);
            setShowModal();
        }
    }
};

const formattedExpedients = ( 
    expedients, 
    setShowModal, 
    setOptions, 
    setTransfer,
    transfer,
    openModalDespaused
) => {

  expedients.map( ( expedient, index ) => {
        const expedient_number_parsed = String(expedient["expedient_number"]).replace(/\//g,'-');

        expedient["actions"] = expedient["external"] && !expedient["released"] ? (
            <Row key={index} center="xs" around="xs">
                <Col xs={12} className="continue-expedient">
                    <div 
                      className="continue" 
                      onClick={() => openModalDespaused(expedient)}
                    >
                      <i className="ti-control-pause" title="Despausar turno externo" />
                    </div>
                </Col>
            </Row>
        ) : expedient["released"] ? (
          <Row key={index} center="xs" around="xs">
            <Col xs={12} className="procedures-actions">
              <div 
                className="continue" 
                onClick={() => getDependencesToTransfer( 
                    setShowModal, 
                    setOptions, 
                    expedient,
                    setTransfer,
                    transfer
                )}
                style={{backgroundColor:"#c1c1c1"}}
                id={ expedient["id"] + "button-transfer" }
              >
                <i className="ti-arrow-circle-right" title="Transferir expediente" />
              </div>
            </Col>
          </Row>
        ) : "";
        expedient["expedient_number_link"] = (
          <Link 
            to={{
              pathname: `/expedient_details/${expedient_number_parsed}`, 
              state: { 
                labelPath: 'Turnado externo de expedientes',
                prevPath: window.location.pathname 
              }
            }}
          >
            {expedient["expedient_number"]}
          </Link>
        );
        expedient["reception_date"] = getTextOfDate( new Date( expedient['updated_at'].replace(/-/g, "/") ) );
        expedient["time"] = getTimeTranscurred( new Date( expedient['updated_at'].replace(/-/g, "/") ) );;
        expedient["comments"] = (<div id={expedient['id'] + 'div-comments'} />);

        return expedient;
    }); 

    return expedients;
};


export const getSearchOfExpedients = async (
    search,
    sizePerPage,
    page,
    setData,
    setTotalExpedients,
    setFunctionsToOpenModal,
    setOptions,
    setTransfer,
    transfer,
    setTextNotData,
    setNonSelectableArray,
    openModalDespaused
) => {

    const { userId_03 } = Cookies.get();
    search = String(search).replace(/\//g,'-').toUpperCase();

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/search/external/user/${userId_03}/${search}/${sizePerPage}?page=${page}`,
        "get",
        null,
        {}
      );
    
    const responseSearch = await request.executeRequest();

    if ( responseSearch.code === 200 ) {

      if ( responseSearch.response.electronicExpedients && responseSearch.response.electronicExpedients.length > 0 ) {

        const formattedExpedientsBySearch = await formattedExpedients(
          responseSearch.response.electronicExpedients, 
          setFunctionsToOpenModal, 
          setOptions, 
          setTransfer,
          transfer,
          openModalDespaused
        );
        setTotalExpedients(responseSearch.response.total);
        setData(formattedExpedientsBySearch);
      }
      else {
        setTextNotData("No se han encontrado resultados");
        setData([]);
      }
    }
    else if ( 
      responseSearch.code === 404 && 
      responseSearch.response.error && 
      typeof responseSearch.response.message === "string" && 
      responseSearch.response.message !== "" 
    ) {

      setTextNotData(responseSearch.response.message);
      setData([]);
    }
    else {
      setTextNotData("No se han encontrado resultados");
      setData([]);
    }
};

export const transferForeignExpedient = async ( 
    transfer,
    props,
    setErrors,
    toCreate
) => {
    setErrors({});
    if (transfer['courtCatalogOption']) {
        transfer['court_catalog_id'] = transfer['courtCatalogOption']['value'];
    }
    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/release`,
        "post",
        null,
        {
          ...transfer,
          force: toCreate
        }
      );
    const responseTransfer = await request.executeRequest();

    if ( responseTransfer.code !== 200 ) {

        if ( 
            responseTransfer.response.error && 
            typeof responseTransfer.response.message === "object"
        ) {
            // eslint-disable-next-line
            const errors = Object.keys( responseTransfer.response.message ).map( (key,index) => {

                return responseTransfer.response.message[key]
            });
            NotificationManager.error('Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo');
            setErrors(responseTransfer.response.message);
        }
        else if (
            responseTransfer.response.error && 
            typeof responseTransfer.response.message === "object"
        )  {
            NotificationManager.error('Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo');
            setErrors( responseTransfer.response.message );
        }
    }
    else {
        if ( responseTransfer.response.governmentBook && responseTransfer.response.message ) {

            localStorage.removeItem("elementsAddedForeign");
            window.localStorage.removeItem("user");

            NotificationManager.success(responseTransfer.response.message);
            props.history.push("/pending_transfers");
        }
    }
};


export const multipleReleaseExpedients = async (
    arrayExpedients,
    setCloseModalMultipleTransfer,
    setData,
    setTotalProcedures,
    page,
    sizePerPage,
    setFunctionsToOpenModal,
    setOptions,
    setTransfer,
    transfer,
    setTextNotData,
    setNonSelectableArray,
    openModalDespaused
  ) => {
  
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/multi_release`,
      "post",
      null,
      {
        governmentBooks: arrayExpedients
      }
    );
  
    const responseMultipleRelease = await request.executeRequest();
  
    if ( responseMultipleRelease.code === 200 ) {
  
      if ( responseMultipleRelease.response.governmentBooks &&
        responseMultipleRelease.response.governmentBooks.length > 0 && 
        responseMultipleRelease.response.governmentBooks.length === arrayExpedients.length ) {
  
        localStorage.removeItem("elementsAddedForeign");
        disabledButtonsToSingleTransfer(responseMultipleRelease.response.governmentBooks, false);
        await getExpedientsToTurnForeign(
          setData,
          setTotalProcedures,
          page,
          sizePerPage,
          setFunctionsToOpenModal,
          setOptions,
          setTransfer,
          transfer,
          setTextNotData,
          setNonSelectableArray,
          openModalDespaused
        );
        NotificationManager.success("Los expedientes seleccionados han sido turnados con éxito");
        setCloseModalMultipleTransfer();
      }
      else {
  
        NotificationManager.error("Hubo un problema durante el turnado masivo, inténtalo nuevamente");
      }
    }
    else {
  
      NotificationManager.error("Hubo un problema durante el turnado masivo, inténtalo nuevamente");
    }
  };


  export const bodyDespaused = (setBodyDespause, bodyDespause, errorsToShow) => {
    return (
      <>
        <Form>
          <Form.Label>Observaciones:</Form.Label>
          <FormControl
            as="textarea"
            rows="4"
            className="form-control"
            style={{ fontSize: "1rem", width: "100%" }}
            onChange={(e) => setBodyDespause({ ...bodyDespause, observation: e.target.value })}
          />
          <div className="mt-4 text-center">
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                checked={bodyDespause['sign']}
                value={bodyDespause['sign']}
                label="Despausar el expediente"
                onChange={(e) => setBodyDespause({ ...bodyDespause, sign:!bodyDespause['sign']})}
              />
            </Form.Group>
            { !bodyDespause['sign'] ? errorsToShow : ''}
          </div>
        </Form>
      </>
    );
  };

  export const footerDespaused = (handleClose, handleReceive) => {

    return (
      <>
        <Button variant="secondary" onClick={() => handleClose()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => handleReceive()}>
          Aceptar
        </Button>
      </>
    );
  };


  export const disabledButtonsToSingleTransfer = (
    elementsToDisabledButton,
    disabled
  ) => {
    if ( disabled ) {
      for ( let i = 0; i < elementsToDisabledButton.length; i++ ) {
        let buttonToTransfer = document.getElementById( elementsToDisabledButton[i]['electronic_expedient_id'] + 'button-transfer' );
        if ( buttonToTransfer ) {
          buttonToTransfer.style.pointerEvents = 'none';
          buttonToTransfer.style.backgroundColor = '#ebe7e7';
        }
      }
    }
    else {
      for ( let i = 0; i < elementsToDisabledButton.length; i++ ) {
        let buttonToTransfer = document.getElementById( elementsToDisabledButton[i]['electronic_expedient_id'] + 'button-transfer' );
        if ( buttonToTransfer ) {
          buttonToTransfer.style.pointerEvents = '';
          buttonToTransfer.style.backgroundColor = '#02cc98'
        }
      }
    }
  };


  export const toDespauseExpedient = async (
    expedientToDespause, 
    bodyDespause,
    setData,
    setTotalProcedures,
    page,
    sizePerPage,
    setFunctionsToOpenModal,
    setOptions,
    setTransfer,
    transfer,
    setTextNotData,
    setNonSelectableArray,
    openModalDespaused,
    setCloseModalDespaused
  ) => {
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/receive/${expedientToDespause['expedient_number'].replace(/\//g,'-')}`,
      "post",
      null,
      {   
        ...bodyDespause
      }
    );

    const responseDespause = await request.executeRequest();
    if (responseDespause.code === 200 && 
      responseDespause.response.message && 
      typeof responseDespause.response.message === "string") {

        await getExpedientsToTurnForeign(
          setData,
          setTotalProcedures,
          page,
          sizePerPage,
          setFunctionsToOpenModal,
          setOptions,
          setTransfer,
          transfer,
          setTextNotData,
          setNonSelectableArray,
          openModalDespaused
        );

        NotificationManager.success(
          responseDespause.response.message
        );
        setCloseModalDespaused();
    }
    else {
      NotificationManager.success(
        'Ha ocurrido un error al intentar ejecutar la acción deseada'
      );
    }
  };

