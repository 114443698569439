import React from "react";
import CheckboxReadAlert from "./CheckboxReadAlert";
import Cookies from "js-cookie";
import { Request } from "../classes/Request";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

export const columns = [
  {
    dataField: "id",
    text: "Id",
    sort: true
  },
  {
    dataField: "procedure_id",
    text: "Trámite",
    sort: true
  },
  {
    dataField: "operation",
    text: "Alerta",
    sort: true
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: true
  },
  {
    dataField: "read",
    text: "Marcar como leída",
    sort: true
  }
];

//FORMAT TABLE WITH THE JSX CHECKBOX AND CORRECT HTML
export const formatDataTable = async (data, updateRead, updateReadCheck) => {
  //updateRead: FUNCTION THAH UPDATE THE CHECKBOX WITHOUT RELOADING THE PAGE
  //updateReadCheck: ACTUAL VALUE OF THE FLAG, FOR CHECKING UPDATE VALUE OF CHECKBOX

  let newArrayFormated = data;
  newArrayFormated.forEach(el => {
    if (!el.read) {
      el.read = (
        <CheckboxReadAlert
          alertId={el.id}
          updateReadValue={updateRead}
          actualReadValue={updateReadCheck}
        />
      );
    }
  });
  return newArrayFormated;
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const updateAlertReadStatus = async (props, read, alertContext) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/alerts/${props.alertId}`,
    "put",
    null,
    { token: process.env.REACT_APP_TOKEN, read: read }
  );
  const updateAlertData = await request.executeRequest();
  if (updateAlertData.code === 200) {
    props.updateReadValue(!props.actualReadValue);
    //THIS LINE MAKE THE BADGE COUNT ALERT RENDER
    alertContext.updateCount(!alertContext.requireUpdate, "substractAlert");
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    if (
      typeof updateAlertData.data.data.data.message == "string" &&
      updateAlertData.data.data.data.message !== ""
    )
      NotificationManager.success(updateAlertData.data.data.data.message);
  }
};
/******************************************************************************************************************************************************/

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getAllAlertNotifications = async form => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unread_alerts/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();
  if (getNotificationData.code !== 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return [];
  }

  return getNotificationData.data.data.data.alerts;
};

export const printFunctionaryNotification = async () => {
  
  return NotificationManager.warning(
    "Tienes notificaciones sin leer",
    "Estimado usuario: ",
    1800
  );
};

export const updateAllAlertNotifications = async (alertContext, userIdLogin) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unread_alerts/${ userIdLogin ? userIdLogin : userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();

  if (getNotificationData.code === 200) {
    if(getNotificationData.data.data.data.alerts.length > 0){
      alertContext.updateCount(true, "sumAlert",getNotificationData.data.data.data.alerts.length);
      NotificationManager.warning( 
        getNotificationData.data.data.data.alerts.length === 1 ? "Cuentas con 1 notificación pendiente" : `Cuentas con ${getNotificationData.data.data.data.alerts.length} pendientes`
      );
    }
  }

};

export const printSocketNotification = async (title, message, time) => {
  
  return NotificationManager.info(title, message, time);
};

export const getPushNotificationsByReceiver = async (page) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/push_notifications/20?page=${page}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();
  if (
    getNotificationData && 
    getNotificationData.code === 200 && 
    getNotificationData.response &&
    getNotificationData.response.notifications && 
    getNotificationData.response.notifications.length > 0
  ) {
    const { total, notifications } = getNotificationData.response;
    return { total, notifications };
  } else return { total: 0, notifications: [] }
};

export const checkLikeReadedNotification = async (
  notificationId, page, alertCount
) => {
  if (alertCount.notifications.length === 1 && page > 1) page = page - 1;
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/push_notifications/${notificationId}?page=${page}`,
    "put",
    null,
    {
      read: true,
      display: false,
      paginate: 20
    }
  );
  const responseNotificationReaded = await request.executeRequest();
  if (
    responseNotificationReaded &&
    responseNotificationReaded.code === 200 &&
    responseNotificationReaded.response && 
    responseNotificationReaded.response.notifications && 
    responseNotificationReaded.response.total >= 0
  ) {
    const { total, notifications } = responseNotificationReaded.response;
    alertCount.updateNotifications('update', notifications, null, null);
    alertCount.updateCount(!alertCount.requireUpdate, 'sumAlert', total);
  } else {
    NotificationManager.error(
      '',
      'Ha ocurrido un error al intentar marcar como leída esta notificación, inténtalo nuevamente...',
      4500
    );
  }
};

export const checkLikeReadedAllNotifications = async (
  notificationsIds, page, alertCount
) => {
  if (page > 1) page = page - 1;
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/push_notifications?page=${page}`,
    "put",
    null,
    {
      notification_ids: notificationsIds,
      read: true,
      paginate: '20'
    }
  );
  const responseAllNotificationReaded = await request.executeRequest();
  if (
    responseAllNotificationReaded &&
    responseAllNotificationReaded.code === 200 &&
    responseAllNotificationReaded.response && 
    responseAllNotificationReaded.response.notifications && 
    responseAllNotificationReaded.response.total >= 0
  ) {
    const { total, notifications } = responseAllNotificationReaded.response;
    alertCount.updateNotifications('update', notifications, null, null);
    alertCount.updateCount(!alertCount.requireUpdate, 'sumAlert', total);
  } else {
    NotificationManager.error(
      '',
      'Ha ocurrido un error al intentar marcar como leída esta notificación, inténtalo nuevamente...',
      4500
    );
  }
};