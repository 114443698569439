import React, { useState, useEffect } from "react";
import { Grid, Col, Row } from "react-flexbox-grid";
import { Image, Modal } from "react-bootstrap";
import { getFooterData, initStateFooter, privacyRequest } from "./footer_logic";
import Cookies from "js-cookie";

const Footer = () => {
  const [initForm, setInitForm] = useState(initStateFooter);
  const [privacyModalElements, setPrivacyModalElements] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { app_config_02 } = Cookies.get();

  useEffect(() => {
    (async function() {
      if ( !app_config_02 ) {
        await getFooterData(setInitForm);
      }
      else {
        setInitForm( JSON.parse( app_config_02 ) );
      }    
    })();
  }, []);

  const getDataPrivacy = async () => {
    await privacyRequest( setPrivacyModalElements, setShowModal );
  };

  return (
    <footer>
      <Grid fluid={true}>
        <Grid className="pY-40">
          <Row>
            <Col xs={12}>
              <Row center="xs" middle="xs" className="pB-20">
                <Col xs={12} sm={4}>
                  <Image
                    style={ initForm.app_functionary_footer_logo_url_image ? { width: "40%" } : { display:"none" } }
                    src={initForm.app_functionary_footer_logo_url_image ? initForm.app_functionary_footer_logo_url_image : ""}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <span>
                    {initForm.app_street}
                    {initForm.app_number !== null && initForm.app_number !== ""
                      ? ` #${initForm.app_number},`
                      : initForm.app_number}
                    {initForm.app_colony !== null && initForm.app_colony !== ""
                      ? ` Col. ${initForm.app_colony},`
                      : initForm.app_colony}{" "}
                    {initForm.app_city !== null && initForm.app_city !== ""
                      ? ` ${initForm.app_city},`
                      : initForm.app_city}
                    {initForm.app_state !== null && initForm.app_state !== ""
                      ? ` ${initForm.app_state}.`
                      : initForm.app_state}
                    <br />
                    <span>
                      {initForm.app_phones !== null &&
                      initForm.app_phones !== ""
                        ? `Tel: ${initForm.app_phones}`
                        : initForm.app_phones}
                    </span>
                  </span>
                </Col>
                <Col xs={12} sm={4}>
                  <Row between="xs">
                    <Col xs={12}>
                      <a
                        href={ initForm.app_facebook_url }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ initForm.app_facebook_url ? null : { pointerEvents:"none" }}
                      >
                        <Image
                          style={{ width: "40px", marginLeft: "20px" }}
                          rounded
                          src={initForm.app_facebook_url_image}
                        />
                      </a>
                      <a
                        href={ initForm.app_twitter_url }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ initForm.app_twitter_url ? null : { pointerEvents:"none" }}
                      >
                        <Image
                          style={{ width: "40px", marginLeft: "20px" }}
                          rounded
                          src={initForm.app_twitter_url_image}
                        />
                      </a>
                      <a
                        href={ initForm.app_youtube_url }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ initForm.app_youtube_url ? null : { pointerEvents:"none" }}
                      >
                        <Image
                          style={{ width: "40px", marginLeft: "20px" }}
                          rounded
                          src={initForm.app_youtube_url_image}
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row center="xs" middle="xs">
                <Col xs={12}>
                  <span>
                    Consulta nuestro{" "}
                    <span 
                      onClick={getDataPrivacy}
                      className="cursor-pointer text-primary px-1"
                    >
                       aviso de privacidad
                    </span>{" "}
                    vigente
                  </span>
                </Col>
              </Row>

              {/*<Row center="xs" middle="xs">
                <Col xs={12}>
                  <span>
                    Copyright © 2018 Develop by{" "}
                    <a
                      href="https://agilgob.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Agilgob
                    </a>
                    . All rights reserved.
                  </span>
                </Col>
              </Row>*/}

            </Col>
          </Row>
        </Grid>
      </Grid>
      <>
        <Modal
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {privacyModalElements}
        </Modal>
      </>
    </footer>
  );
};

export default Footer;
