import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {Badge, Nav, Navbar, NavDropdown, Image, Pagination} from "react-bootstrap";
import Cookies from "js-cookie";
import {logOut} from "./navbar_logic";
import {Link} from "react-router-dom";
import LoaderContext from "./loader_context";
import NotificationAlertListContext from "../../alertNotificationTable/notification_alert_context";
import SideBar from "./SideBar";
import Breadcrumb from "./Breadcrumb";
import { 
    getPushNotificationsByReceiver, checkLikeReadedNotification,
    checkLikeReadedAllNotifications
} from "../../alertNotificationTable/alert_notification_logic";

const elementsNavBar = {
    marginLeft:"10px",
    cursor: "pointer"
}

const NavBar = props => {
    //GET COOKIES
    const {auth_03, names_03, paternal_surname_03, uid_03, app_config_02} = Cookies.get();
    const [avatar, setAvatar] = useState("");
    const alertCount = useContext(NotificationAlertListContext);
    const [isResponsive, setIsResponsive] = useState(window.innerWidth < 992);
    const [showSideBar, setShowSideBar] = useState(false);
    const app_config_parsed = app_config_02 ? JSON.parse(app_config_02) : null;
    const [page, setPage] = useState(1);

    useEffect(() => {
        (async function () {
            const {avatar} = Cookies.get();
            if (avatar && avatar !== "null") {
                setAvatar(avatar);
            } else {
                setAvatar(`${process.env.PUBLIC_URL}/img/user_avatar.png`);
            }
        })();
    }, []);

    //INITIAL STATE
    const Loader = useContext(LoaderContext);

    //LOGOUT METHOD
    const onLogOutHandler = async () => {
        Loader.show(true);
        await logOut(props);
        Loader.show(false);
    };

    useLayoutEffect(() => {
        function updateSize() {
            setIsResponsive(window.innerWidth < 992);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const profileWrapper = () => {
        if (auth_03) {
            return (
                <div className="nilo-profile-wrapper">
                    <div className="image-wrapper">
                        <Image src={avatar}/>
                    </div>
                    <div className="profile-info-wrapper">
                        <label className="name w-100">{names_03} {paternal_surname_03}</label>
                        <label className="username w-100">{uid_03}</label>
                        <Link to="/edit_profile" className="btn btn-primary">Editar Perfil</Link>
                    </div>
                </div>
            );
        }

        return '';
    };

    const renderPagination = () => {
        const pages = Math.ceil(alertCount.count / 20);

        return (
            <Pagination size="sm">
                <Pagination.First 
                    onClick={() => handleChangePage(1)} 
                />
                <Pagination.Prev 
                    onClick={() => handleChangePage(page - 1)} 
                />
                <Pagination.Item 
                    onClick={() => handleChangePage(page !== pages ? page : 1)} 
                    active={page !== pages || page === 1}
                >
                    {page !== pages ? page : 1}
                </Pagination.Item>
                {pages > 2 ? <Pagination.Ellipsis /> : ''}
                {pages > 1 ? 
                <Pagination.Item 
                    onClick={() => handleChangePage(pages)}
                    active={page === pages}
                >
                    {pages}
                </Pagination.Item> : 
                ''}
                <Pagination.Next 
                    onClick={() => handleChangePage(page + 1)} 
                />
                <Pagination.Last 
                    onClick={() => handleChangePage(pages)} 
                />
            </Pagination>
        );
    };

    const handleChangePage = async (newPage) => {
        const pages = Math.ceil(alertCount.count / 20);
        if (newPage <= pages && newPage > 0) {
            const dataNotifications = await getPushNotificationsByReceiver(newPage);
            alertCount.updateNotifications('update', dataNotifications.notifications, null, null);
            alertCount.updateCount(!alertCount.requireUpdate, 'sumAlert', dataNotifications.total);
            setPage(newPage);
        }
    };

    const handleReadedNotification = async (notificationId) => {
        if (alertCount.notifications.length === 1 && page > 1) setPage(page - 1);
        await checkLikeReadedNotification(
            notificationId, page, alertCount
        );
    };

    const handleReadAllNotifications = async () => {
        if (page > 1) setPage(page - 1);
        const notificationIds = alertCount.notifications.map((notif) => notif.id);
        await checkLikeReadedAllNotifications(
            notificationIds, page, alertCount
        );
    };

    const handleDetailsNotification = async (notification) => {
        handleReadedNotification(notification.id)
        props.history.push(`/expedient_details/${notification.meta.expedient_number.replace(/\//g,'-')}`)
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand >
                    <i 
                        onClick={ () => setShowSideBar( !showSideBar ) } 
                        style={elementsNavBar}
                        className="fas fa-align-justify"
                    />{'  '}
                    <img 
                        onClick={ 
                            props.history ? 
                            () => { /* props.history.push('/home') */ } : 
                            null 
                        }
                        src={ app_config_parsed && app_config_parsed['app_functionary_header_logo_url_image'] ? app_config_parsed['app_functionary_header_logo_url_image'] : `${process.env.REACT_APP_URL_BASE_LARAVEL}img/nilo_completo_01.svg`} 
                        alt="application logo"
                        style={elementsNavBar}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <div className={isResponsive ? 'd-block' : 'd-none'}>

                            {profileWrapper()}
                            <Nav.Link to="/alert_notifications">
                                Notificaciones
                                <Badge pill variant="danger" style={{marginLeft: ".2em"}}>
                                    {alertCount.count === 0 ? "" : alertCount.count}
                                </Badge>{" "}
                            </Nav.Link>
                            <Nav.Link to="/user_manual">
                                Manual de usuario
                            </Nav.Link>
                            <Nav.Link to="/avatar_config">
                                Configurar avatar
                            </Nav.Link>
                            <Nav.Link to="/change_password">
                                Cambiar contraseña
                            </Nav.Link>
                            <NavDropdown.Divider/>
                            <Nav.Link onClick={onLogOutHandler}>
                                Cerrar sesión
                            </Nav.Link>
                        </div>
                    </Nav>
                    <Nav>
                        {!isResponsive ?
                        <>
                            <NavDropdown
                                title={
                                    <>
                                        <Badge className="pull-right mL-3" pill variant="danger">
                                            {alertCount.count}
                                        </Badge>{" "}
                                        <i className="fas fa-bell cursor-pointer" title="notifications"/>
                                    </>
                                }
                                drop={'left'}
                            >
                                <div
                                    style={{
                                        width:'330px',
                                        minHeight:'auto',
                                        maxHeight:'500px',
                                        overflowY: 'scroll'
                                    }}
                                >
                                    <div style={{display:'flex', justifyContent:'space-around', marginBottom:'5px'}}>
                                        <div className={alertCount.notifications && alertCount.notifications.length > 0 ? "d-flex justify-content-start" : "d-n"}>
                                            {renderPagination()}
                                        </div>
                                        <div className={alertCount.notifications && alertCount.notifications.length > 0 ? "d-flex justify-content-end mT-3 mR-2" : "d-n"}>
                                        <button
                                            className="btn-notification-read"
                                            onClick={() => handleReadAllNotifications()}
                                        >
                                            Marcar todo leído
                                        </button>
                                    </div>
                                    </div>
                                    {alertCount.notifications && alertCount.notifications.length > 0 ?
                                    alertCount.notifications.map((notif, i) => (
                                        <div 
                                            key={i}
                                            className={"wrapper-notification notification-" + notif.meta.action} 
                                        >
                                            <b style={{fontSize:'12px'}}>{notif.message}</b><br/>
                                            <small>{' (' + notif.created_at + ')'}</small>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn-notification-read"
                                                    onClick={() => handleReadedNotification(notif.id)}
                                                >
                                                    Marcar como leída
                                                </button>
                                                <button
                                                    className="btn-notification-see"
                                                    onClick={() => handleDetailsNotification(notif)}
                                                >
                                                    Ver
                                                </button>
                                            </div>
                                        </div>
                                    )) :
                                    <div className="ta-c">
                                        <b>No existen notificaciones pendientes...</b>    
                                    </div>}
                                </div>
                            </NavDropdown>
                            <NavDropdown
                                title={<b>{names_03 + ' ' + paternal_surname_03}</b>}
                                id="collasible-nav-dropdown"
                                style={auth_03 ? {display: "block"} : {display: "none"}}
                                drop={'left'}
                            >
                                {profileWrapper()}
                                <NavDropdown.Item as={Link} to="/alert_notifications">
                                    Notificaciones
                                    <Badge pill variant="danger" style={{marginLeft: ".2em"}}>
                                        {alertCount.count === 0 ? "" : alertCount.count}
                                    </Badge>{" "}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/user_manual">
                                    Manual de usuario
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/avatar_config">
                                    Configurar avatar
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/change_password">
                                    Cambiar contraseña
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={onLogOutHandler}>
                                    Cerrar sesión
                                </NavDropdown.Item>
                            </NavDropdown>
                        </> : ''
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <SideBar
                open={showSideBar}
                onHide={setShowSideBar}
                history={props.history}
            />
            <Breadcrumb {...props} open={showSideBar} onHide={setShowSideBar}/>
        </>
    );
};

export default NavBar;
