import React, { useContext } from "react";
import LoaderContext from "../../layout/shared/loader_context";

const Loader = () => {
  const Loader = useContext(LoaderContext);

    return (
        <div className={'loader-container w-100 position-fixed' + (Loader.status ? ' show' : '')}>
            <div className="overlay w-100" />
            <div className="loader position-absolute w-100">
                <img className="position-absolute" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="Cargamdp" />
            </div>
        </div>
    );
};

export default Loader;
