import React, {useState, useEffect, useContext} from "react";
import NavBar from "../../layout/shared/NavBar";
import { Grid } from "react-flexbox-grid";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import {RemotePagination} from "../../helper_components/remote_pagination/RemotePagination";
import {
    columns,
    getPendingTransfers,
    getUsersToTransfer,
    updateTransfer,
    searchPendingExpedients,
    footerModalCancel,
    bodyModalCancel
} from "./pending_transfers_logic";
import ModalComponent from "../../helper_components/ModalComponent";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import Footer from "../../layout/shared/Footer";

const PendingTransfers = (props) => {
    const [searching,setSearching] = useState(false);
    const [cleaning,setCleaning] = useState(false);
    const [search,setSearch] = useState("");
    const [data,setData] = useState([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const [sizePerPage,setSizePerPage] = useState(10);
    const [textNotData,setTextNotData] = useState("");
    const [show,setShow] = useState(false);
    const [options,setOptions] = useState([]);
    const [transfer,setTransfer] = useState({});
    const loader = useContext(LoaderContext);
    const [showCancel,setShowCancel] = useState(false);
    const [expedientToCancel,setExpedientToCancel] = useState({});

    useEffect(()=> {
        ( async function() {
            await getPendingTransfers(
                sizePerPage,
                page,
                setData,
                setTotal,
                setTextNotData,
                setOpenModal,
                setOpenModalCancel
            );
        }()); 
    },[])

    const toSearch = async () => {

        if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) {

            setSearching(true);
            loader.show(true);
            await searchPendingExpedients(
                search.replace(/^\s+|\s+$/gm,''),
                sizePerPage,
                page,
                setData,
                setTotal,
                setTextNotData,
                setOpenModal,
                setOpenModalCancel
            );
            setSearching(false);
            loader.show(false);
        }
    };

    const cleanSearch = async () => {
        setSearch("");

        setCleaning(true);
        loader.show(true);
        await getPendingTransfers(
            sizePerPage,
            page,
            setData,
            setTotal,
            setTextNotData,
            setOpenModal
        );
        setCleaning(false);
        loader.show(false);
    };

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
        await getPendingTransfers(
            sizePerPage,
            page,
            setData,
            setTotal,
            setTextNotData,
            setOpenModal,
            setOpenModalCancel
        );
    };

    const setOpenModalCancel = ( expedient ) => {

        setExpedientToCancel(expedient);
        setShowCancel(true);
    };

    const setOpenModal = async ( transfer ) => {
        await getUsersToTransfer(
            setOptions,
            transfer['court_id']
        );
        const transferToShow = {
            id: transfer["id"],
            expedient_number: transfer["expedient_number"],
            receiver_id: {
                label: transfer["receiver"]["name"],
                value: transfer["receiver"]["id"]
            },
            observation: transfer["sender_observations"] ? transfer["sender_observations"] : ""
        };
        setTransfer(transferToShow);
        setShow(true);
    };

    const setCloseModal = () => {

        setShow(false);
    };

    const bodyModal = (
        <>
            <Form>
                <Form.Group>
                <Form.Label>* Destinatario:</Form.Label>
                <Select
                    value={ transfer["receiver_id"] ? transfer["receiver_id"] : {} }
                    placeholder="Selecciona un destinatario"
                    onChange={selected => setTransfer({ ...transfer, receiver_id:selected })}
                    options={options}
                />
                </Form.Group>
                <Form.Group>
                <Form.Label>Observaciones:</Form.Label>
                <Form.Control 
                    onChange={ (e) => setTransfer({ ...transfer, observation:e.target.value}) } 
                    value={ transfer["observation"] }
                    as="textarea"
                    rows="5"
                    aria-label="Observaciones" 
                />
                </Form.Group>
            </Form>
        </>
    );

    const footerModal = (
        <>
            <Button
                variant="secondary"
                onClick={ () => setCloseModal() }
            >
                Cerrar
            </Button>
            <Button 
                variant="primary"
                type="submit"
                onClick={ () => updateTransfer(
                    transfer,
                    sizePerPage,
                    page,
                    setData,
                    setTotal,
                    setTextNotData,
                    setOpenModal,
                    setShow,
                    setOpenModalCancel
                )}
            >
                Actualizar
            </Button>
        </>
    );

    return (

        <>
            <NavBar {...props}/>
            <h3 className="mT-20 w-100 text-center">Expedientes enviados</h3>
            <div style={{width:"85%",margin:"0px auto",display:"block"}}>
                <ExpedientsSearcher
                    placeholderSearch="Escribe el número de Id del trámite"
                    placeholderSearchButton="Buscar"
                    placeholderCleanButton="Limpiar"
                    helperContextual="Escribe el número de Id del trámite y haz clic en el botón de buscar."
                    executeSearch={ toSearch }
                    cleanSearch={ cleanSearch }
                    isSearching={searching}
                    isCleaning={cleaning}
                    setSearch={setSearch}
                    search={search}
                    hasRulesToSearch={true}
                    rulesToSearch={/^[a-zA-Z0-9/]*$/}
                />
            </div>
            <Grid fluid={true} className={ data.length > 0 ? "no-padding" : "d-n"} style={{minHeight: '76vh', width:"85%", margin:"0px auto"}}>
                <section className="procedures-table-container mT-30">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={total}
                        onTableChange={handleTableChange}
                        columns={columns}
                    />
                </section>
            </Grid>

            <h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n" }>{textNotData}</h4>

            <Footer/>

            <ModalComponent
                show={show}
                header={"Destinatario de turno"}
                body={bodyModal}
                footer={footerModal}
                canClose={true}
                onHide={setCloseModal}
            />

            <ModalComponent
                show={showCancel}
                header={"Confirmar acción"}
                body={bodyModalCancel}
                footer={
                    footerModalCancel( 
                        expedientToCancel, 
                        setShowCancel,
                        sizePerPage, 
                        page, 
                        setData, 
                        setTotal, 
                        setTextNotData,
                        setOpenModal,
                        setOpenModalCancel,
                        props
                    )
                }
                canClose={true}
                onHide={setShowCancel}
            />

            <Loader/>
        </>
    );
};

export default PendingTransfers;